import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account.component';
import { FinalizeComponent } from '../finalize/finalize.component';
import { AppsResolver } from '../apps/apps.resolver';
import { UserResolver } from '../core/user/user.resolver';
import { SubscriptionResolver } from '../core/subscription/subscription.resolver';

const routes: Routes = [
  {
    path: '', component: AccountComponent,
    resolve: {
      activeBusiness: UserResolver,
      subscription: SubscriptionResolver,
    },
  },
  {
    path: ':id', component: AccountComponent,
    // resolve: {
    //   activeBusiness: UserResolver,
    //   subscription: SubscriptionResolver
    // },
  },
  {
    path: 'finalize/:id', component: FinalizeComponent, resolve: { availableApps: AppsResolver }, data: { navigateToUrl: 'settings' },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {
}
