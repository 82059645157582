import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { User, UserService } from '../../core/user/user.service';
import { PageService } from '../../core/page.service';
import { SubscriptionService } from '../../core/subscription/subscription.service';
@Component({
  selector: 'rosie-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  errorMessage = '';
  billingPortalUrl: SafeResourceUrl;

  constructor(private user: UserService, private page: PageService, public subscription: SubscriptionService) {
    this.setBrowserTabTitle();

  }

  get userData(): User {
    return this.user.userData;
  }

  get hasCreditCardOnFile(): boolean {
    return this.user.hasCreditCardOnFile;
  }

  get isPaymentMethodInvalid() {
    return this.subscription.isPaymentMethodInvalid;
  }

  get planName() {
    return this.subscription.isTrialing ? 'Trial' : this.subscription.subscriptionData?.planName;
  }

  ngOnInit(): void {
  }

  redirectToBillingPortal(): void {
    window.open(this.userData.subscription?.billingUrl, '_blank');
  }

  subscribe() {
    this.subscription.open.next(true);
  }

  getSelectedPlan() {
    return this.subscription.plans?.find((plan) => this.subscription.subscriptionData?.planName === plan.name);
  }

  private setBrowserTabTitle(): void {
    this.page.initializePage(`Rosie - Settings`);
  }
}