<div class="modal-title text-left">{{ 'APP.EDIT_SETTINGS' | translate }}</div>
<div class="modal-subtitle with-border"></div>
<div class="modal-content">
  <div class="app-section-heading-wrapper mb-4">
    <div
      [ngClass]="{ 'app-section-description': app && !app.hasPostInstallFields, 'app-section-heading': app?.hasPostInstallFields }">
      {{
      getTranslationWithAppName('SETTINGS_HEADING', true)
      }}
    </div>
  </div>
  <ng-content></ng-content>
  <ng-container
    [ngTemplateOutletContext]="{ form: app?.setupData?.form, formFields: app?.setupData?.formFields, formOptions: formOptions }"
    [ngTemplateOutlet]="form">
  </ng-container>
  <rosie-alert *ngIf="app?.hasError" [message]="getTranslationWithAppName('UPDATE_FAILED')" [type]="'error'"
    class="mt-5" [customClass]="'mb-0'">
  </rosie-alert>
</div>
<div class="modal-action-line with-border">
  <rosie-button (click)="close()" [customClass]="'secondary narrow'" [translationKey]="'COMMON.CANCEL'">
  </rosie-button>
  <rosie-button (click)="updateModalActionConfig.actionFunc()" [inProgress]="app?.inProgress"
    [customClass]="'narrow slim'" [translationKey]="updateModalActionConfig.translationKey">
  </rosie-button>
</div>