import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[dataHook]'
})
export class DataHookDirective {

  @Input('dataHook') hookName: string;

  constructor(private elementRef: ElementRef) {
  }

  @HostBinding('attr.data-rosie-hook') get dataHook(): string {
    const tagName = this.elementRef.nativeElement.tagName + '-';
    let hookName = this.hookName ? this.hookName : '';
    let dataHook = '';

    if (hookName === '') {
      const parentHookName: string = ((this.elementRef.nativeElement as HTMLElement).parentElement.closest('[data-rosie-hook]') as HTMLElement)?.dataset.rosieHook;
      hookName = parentHookName ? parentHookName.split('-').slice(1).join('-') : '';
    }
    if (!!hookName) {
      dataHook = `${tagName}${hookName}`.toLowerCase().split(/\.+|\s+|\_+/g).join('-');
    }
    return dataHook;
  }

}
