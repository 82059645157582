import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {getApiUrl, getHeaders} from '../../core/helpers/rest-utils';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FileUploadData} from './file-upload.component';
import {convertCamelCaseToSnakeCase} from 'src/app/core/helpers/utils';
import {CaseType} from '../../core/helpers/utils';

@Injectable({
  providedIn: 'root',
})
export class FileService {

  constructor(private http: HttpClient) {
  }

  upload(file: File, additionalData: FileUploadData): Observable<string> {
    const formData: FormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    formData.append('image', file);

    for (const [key, value] of Object.entries(additionalData.data)) {
      formData.append(convertCamelCaseToSnakeCase(key, CaseType.LowerCase), value as string);
    }

    return this.http.post(`${getApiUrl()}${additionalData.origin}`, formData, {headers})
      .pipe(map((response: any) => {
        return response;
      })) as Observable<string>;
  }

  delete(url: string): Observable<any> {
    return this.http.delete(`${getApiUrl()}media?url=${url}`, {headers: getHeaders()}).pipe(map((response: any) => response));
  }

}
