import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'rosie-validation-error',
  template: `
    <ng-container *ngIf="show && translationKey">
      <span class="rosie-error-message" [ngClass]="customClass">{{ translationKey | translate:translationAttributes }}</span>
    </ng-container>
  `,
  styles: []
})
export class ValidationErrorComponent implements OnInit {
  @Input() show = false;
  @Input() translationKey = '';
  @Input() translationAttributes: any;
  @Input() customClass = '';


  constructor() {
  }

  ngOnInit(): void {
  }

}
