import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App, AppKey, AppStatus } from '../apps/app/app';
import { AppsService } from '../apps/apps.service';
import { DataStorage } from '../core/data/data-storage';
import { removeFalsyValuesFromObject, convertSnakeCaseToCamelCase } from '../core/helpers/utils';
import { take } from 'rxjs/operators';

@Component({
  selector: 'rosie-finalize',
  template: `
    <rosie-integration-animation [integrationTitle]="app.name" [integrationType]="'extension'"
                                 *ngIf="app?.isIntegrating" [fullScreen]="false">
    </rosie-integration-animation>
  `,
  styles: []
})
export class FinalizeComponent implements OnInit {
  app: App;
  navigateToUrl = '/integrations';

  constructor(private router: Router, private route: ActivatedRoute, private appsService: AppsService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (data?.navigateToUrl) {
        this.navigateToUrl = data.navigateToUrl;
      }
    });
    this.route.paramMap
      .subscribe((paramMap) => {
        const finalizingAppKey = convertSnakeCaseToCamelCase(paramMap.get('id')) as AppKey;
        this.app = this.appsService.allApps.find((app) => { return app.key === finalizingAppKey });
        if (this.app && !this.app.isIntegrating) {
          this.app.isIntegrating = this.router.url.includes('finalize');
          if (this.app.isIntegrating) {
            this.finalizeIntegration();
          }
        }
      });
  }

  navigateToApp(appKey: AppKey) {
    this.router.navigate([this.navigateToUrl, { id: appKey }]);
  }

  private finalizeIntegration() {
    this.route.queryParamMap
      .pipe(take(1))
      .subscribe((map: any) => {
        const params = removeFalsyValuesFromObject(map?.params);
        let requiredParams = {};
        this.app.requiredParams?.forEach((param) => requiredParams[param] = (params[param]));

        if (this.app.hasExternalFlowWithFormFields) {
          Object.assign(requiredParams, this.getAdditionalParams());
        }

        if (this.app.requiredParams.filter(param => requiredParams[param] === undefined)?.length > 0) {
          this.handleErrorOnFinalize();
          return;
        }

        this.appsService.finalizeIntegration(requiredParams, this.app.id)
          .subscribe(
            (data) => {
              this.app.isIntegrating = false;
              this.app.status = AppStatus.Installed;
              this.navigateToApp(this.app.key);
            },
            () => this.handleErrorOnFinalize());
      });
  }

  private handleErrorOnFinalize() {
    this.app.isIntegrating = false;
    this.app.status = null;
    this.navigateToApp(this.app.key);
  }

  private getAdditionalParams() {
    const params = {};
    this.app.setupData.formFields?.forEach((field) => {
      const fieldValue = DataStorage.persistent().get(`field-${this.app.id}-${field.name}`)
        || this.app.setupData.formFields?.find(formField => formField.name === field.name)?.value;
      if (fieldValue) {
        params[`${field.name}`] = fieldValue;
        DataStorage.persistent().remove(`field-${this.app.id}-${field.name}`);
      }
    });
    return params;
  }
}
