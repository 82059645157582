import {Component, Input, ViewEncapsulation} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {runOnNextTick} from '@rallycommerce/common/utils';
import {generateUniqueAdminId} from '../../core/helpers/utils';

@Component({
  selector: 'rosie-select-input',
  template: `
    <ng-select [formControl]="control" labelForId="{{id}}" [clearable]="false" [searchable]="false"
               placeholder="{{placeholderTranslationKey | translate}}" (open)="handleOpen()"
               class="rosie-select {{ hasValue ? 'has-value' : '' }}" [ngClass]="customClass ? customClass : ''" [selectOnTab]="true"
               [dataHook]="dataHook | translate" [appendTo]="appendTo">
      <ng-option *ngFor="let option of options let first = first; let last = last" [ngClass]="{ 'first' : first, 'last' : last }"
                 [value]="getOptionValue(option)" [class]="'rosie-option'" [disabled]="isOptionDisabled(option)"
                 [dataHook]="getOptionValue(option)">
        <img *ngIf="getImageUrl(option)" [src]="getImageUrl(option)" class="rosie-option-icon">
        <span *ngIf="uppercaseOption"
              [dataHook]="'select-option-' + getOptionLabel(option)">{{ optionPrefix + getOptionLabel(option) | uppercase | translate }}</span>
        <span *ngIf="!uppercaseOption"
              [dataHook]="'select-option-' + getOptionLabel(option)">{{ optionPrefix + getOptionLabel(option) | translate }}</span>
        <span *ngIf="option?.description" class="rosie-option-description"
              [dataHook]="'select-option-' + getOptionLabel(option)">{{ option.description | translate }}</span>
      </ng-option>
      <label *ngIf="labelTranslationKey">{{ labelTranslationKey | translate }}</label>
    </ng-select>
  `,
  styleUrls: ['./select-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectInputComponent {
  @Input() id = generateUniqueAdminId();
  @Input() disabled = false;
  @Input() uppercaseOption = false;
  @Input() placeholderTranslationKey = '';
  @Input() customClass: string;
  @Input() options: SelectOption[] | [] | any = [];
  // TODO start to use as of checkout links - copy over moving behviour from number input
  @Input() labelTranslationKey = '';
  @Input() control: UntypedFormControl | AbstractControl;
  @Input() optionPrefix = '';
  @Input() appendTo: string = null;
  @Input() dataHook = 'select-input';


  constructor() {
  }

  get hasValue(): boolean {
    return this.control?.value !== null && this.control?.value !== undefined && this.control?.value !== '';
  }

  handleOpen() {
    // workaround for ng-select issue where using appendTo with a dynamic class for the ng-select element
    // doesn't propagate the ng-select element class to the ng-dropdown-panel element
    if (this.appendTo && this.customClass) {
      runOnNextTick(() => {
        document.querySelector('body > .ng-dropdown-panel.rosie-select')?.classList.add(...this.customClass.split(' '));
      });
    }
  }

  isOptionDisabled(selectedOption: string) {
    const option = this.options.find((option) => option === selectedOption || option?.value === selectedOption);
    return typeof option === 'string' ? false : option?.isDisabled;
  }

  getImageUrl(option: string | SelectOption): string {
    return this.options.find((opt) => typeof option === 'string' ? opt === option : opt.label === (option as SelectOption).label)?.imageUrl;
  }

  getOptionValue(option: string | SelectOption): string {
    return typeof option === 'string' ? option : option.value || option.label;
  }

  getOptionLabel(option: string | SelectOption): string {
    return typeof option === 'string' ? option : option.label || option.value;
  }
}

export interface SelectOption {
  isDisabled?: boolean;
  value?: string;
  label: string;
  imageUrl?: string;
  description?: string;
}
