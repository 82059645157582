import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, switchMap } from 'rxjs';
import { AgentService } from './agent.service';

@Injectable({
  providedIn: 'root'
})
export class AgentResolver {
  constructor(private agent: AgentService) {
  }

  resolve(): Observable<any> {
    return this.agent.getAgent().pipe(
      switchMap(agent => {
        return forkJoin([
          of(agent),
          this.agent.getFiles(),
          this.agent.getFaqs(),
          this.agent.getCustomQuestions()
        ]);
      })
    );
  }
}
