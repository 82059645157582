<div class="page-sub-content-wrapper">
  <div class="page-sidebar" [ngClass]="{'w-100' : isMobileVersion}"
    *ngIf="!isMobileVersion || isMobileVersion && !activeSection">
    <div (click)="handleSettingsSectionChange(section)" *ngFor="let section of sections; let last = last;"
      [dataHook]="'settings-section-' + section" [ngClass]="{'active': section === activeSection, 'last': last}"
      class="section item">
      <ng-icon [name]="getIcon(section)" [color]="section === activeSection ? '#822AC6' : '#7f728a'" [size]="'24px'"
        class="mr-md"></ng-icon>
      <span class="text">{{ getTranslation(section, 'SIDEBAR') }}</span>
    </div>
  </div>
  <div class="page-content-wrapper setting-wrapper" *ngIf="!isMobileVersion || isMobileVersion && activeSection">
    <div class="page-content-wrapper-header">
      <div>
        <div class="heading">{{ getTranslation(activeSection, 'HEADING') }}</div>
        <div class="subheading">{{ getTranslation(activeSection, 'SUBHEADING') }}</div>
      </div>
    </div>
    <ng-container [ngSwitch]="activeSection">
      <ng-container *ngSwitchCase="AccountSection.Profile">
        <rosie-profile></rosie-profile>
      </ng-container>
      <ng-container *ngSwitchCase="AccountSection.Notifications">
        <rosie-notifications></rosie-notifications>
      </ng-container>
      <ng-container *ngSwitchCase="AccountSection.Billing">
        <rosie-billing></rosie-billing>
      </ng-container>
    </ng-container>
  </div>
  <div class="action-icon back-icon" *ngIf="isMobileVersion && activeSection" (click)="back()">
    <ng-icon [name]="'heroArrowUturnLeft'" [color]="'white'"></ng-icon>
  </div>
</div>