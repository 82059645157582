import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;
    let rawNumber = value;
    let formattedNumber = value;
    if (value?.startsWith('+1') && value?.length === 12) {
      rawNumber = value.slice(2);
      formattedNumber = `(${rawNumber.slice(0, 3)}) ${rawNumber.slice(3, 6)}-${rawNumber.slice(6, 10)}`
    } else if (!value?.startsWith('+') && value?.length === 10) {
      formattedNumber = `(${rawNumber.slice(0, 3)}) ${rawNumber.slice(3, 6)}-${rawNumber.slice(6, 10)}`
    }
    return formattedNumber;
  }
}
