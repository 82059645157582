import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AppsService } from '../../../apps.service';
import { convertSnakeCaseToCamelCase } from 'src/app/core/helpers/utils';
import { App, AppField, AppFieldStage, AppKey, AppModal } from '../../app';
import { Subject } from 'rxjs';

@Component({
  selector: 'rosie-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppSettingsComponent implements OnInit {
  showSuccessMessage = false;

  @Input() app: App;
  @Input() modalClosed: Subject<void>;

  @Output() update = new EventEmitter<void>();
  @Output() install = new EventEmitter<void>();
  @Output() verify = new EventEmitter<void>();

  constructor(private apps: AppsService, private modal: NgxSmartModalService) {
  }

  get settingsModalActionConfig(): ActionConfig {
    if (this.app?.hasAnyEditableFieldsOnceInstalled) {
      return {
        translationKey: 'APP.EDIT_SETTINGS',
        iconClass: 'edit-icon mr-1',
        actionFunc: () => this.update.emit()
      };
    }
    return null;
  }

  get readOnlyFields(): AppField[] {
    const fields = this.app?.details?.profile?.fields || this.app.setupData?.formFields;
    return fields?.filter(field => !field.editability || field.editability === AppFieldStage.Never) || [];
  }

  get editableFields(): AppField[] {
    const fields = this.app?.details?.profile?.fields || this.app.setupData?.formFields;
    return fields?.filter(field => !!field.editability && field.editability !== AppFieldStage.Never) || [];
  }

  get hasHiddenEdit(): boolean {
    return this.app.key === AppKey.AppointmentConfirmation;
  }

  ngOnInit(): void {
    this.handleSuccessMessageVisibility();
  }

  getFormattedCreatedAt(): string {
    const dateString = this.app.lastUpdated;
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');
  }

  getAppStatusTranslation(translationKey: string, showPrefix = false): string {
    return this.apps.buildTranslation(translationKey, { app: this.app, useStatus: true, showPrefix });
  }

  getAppSpecificTranslation(translationKey: string, showPrefix = false): string {
    return this.apps.buildTranslation(translationKey, { app: this.app, useAppKey: true, useStatus: false, showPrefix });
  }

  getHelpTextPerField(fieldName: string): string {
    return this.apps.getHelpTextPerField(fieldName);
  }

  close(): void {
    this.modal.getModal(AppModal.Settings).close();
  }

  openUninstallModal(): void {
    this.modal.getModal(AppModal.Uninstall).open();
  }

  getFieldIconValue(fieldTranslationKey: string): string {
    const fieldName = fieldTranslationKey.split('.').pop();
    return this.app?.isFieldFileType(convertSnakeCaseToCamelCase(fieldName)) ? 'checkbox-checked-circle.svg' : '';
  }

  private handleSuccessMessageVisibility(): void {
    this.modalClosed?.subscribe(() => {
      this.showSuccessMessage = false;
    });
  }
}

interface ActionConfig {
  translationKey: string;
  iconClass: string;
  actionFunc: any;
}
