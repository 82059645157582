import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { AccountAction } from '../account.service';
import { FormControl } from '@angular/forms';
import { User, UserService } from '../../core/user/user.service';
import { AgentService } from '../../core/agent/agent.service';
import { PageService } from '../../core/page.service';
import { CallsService } from '../../core/calls/calls.service';

@Component({
  selector: 'rosie-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  inProgress: AccountAction = AccountAction.None;
  errorMessage = '';
  smsEnabledForm: FormControl = new FormControl();
  smsList = [];
  @ViewChild('clearRecordingModal') clearRecordingsModal: NgxSmartModalComponent;
  @ViewChild('smsModal') smsModal: NgxSmartModalComponent;

  constructor(private user: UserService, private translate: TranslateService, private agent: AgentService, private page: PageService, private calls: CallsService) {
    this.setBrowserTabTitle();
  }

  get userData(): User {
    return this.user.userData;
  }

  get isClearRecordingsInProgress(): boolean {
    return this.inProgress === AccountAction.ClearRecordings;
  }

  ngOnInit(): void {
    this.createSmsForm();
  }

  logout() {
    this.user.logout();
  }

  getTranslation(translationKey: string): string {
    return this.translate.instant(`ACCOUNT_SECTION.TABS.${translationKey?.toUpperCase()}`);
  }

  clearRecordingData() {
    this.inProgress = AccountAction.ClearRecordings;
    this.calls.clearRecordings()
      .subscribe(() => {
        this.inProgress = AccountAction.None; this.clearRecordingsModal.close()
      }, () => this.inProgress = AccountAction.None);
  }

  fetchSmsList() {
    this.agent.getSmsList().subscribe((res: any) => {
      this.smsList = res?.map((sms) => sms.body) || [];
    });
  }

  private setBrowserTabTitle(): void {
    this.page.initializePage(`Rosie - Profile`);
  }

  private createSmsForm() {
    this.smsEnabledForm.patchValue(this.agent.primary.sms);
    this.smsEnabledForm.valueChanges.subscribe((value) => {
      this.agent.enableSms(value).subscribe();
    });
  }
}

