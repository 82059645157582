export interface Business {
  title?: string;
  url?: string;
  id?: string;
  meta?: any;
  timezone?: string;
  information?: BusinessInformation;
  services?: Service[];
  phoneNumbers?: BusinessPhoneNumber[];
}

export interface BusinessInformation {
  description: string;
  urls: string[];
  googleMapsUrl: string;
  phone: string;
  additionalProperties?: AdditionalProperties;
  meta: any;
  id: string;
}

export interface BusinessData {
  title: string;
  websiteUrls: string[];
  industry: string;
}

export interface Service {
  id?: string;
  name?: string;
  isActive?: boolean;
}

export interface Area {
  isActive: boolean;
  name: string;
}
export interface Urls {
  homePage: string;
  googleMaps: string;
}

export interface AdditionalProperties {
  name: string;
  email: string;
  addresses: Address[];
  summary: string;
  industry: string;
  socialMedia: string[];
  phoneNumbers: string[];
  contactPhoneNumber: string[];
  contactEmail: string[];
  serviceAreas: Area[];
  businessHours: BusinessHours[];
  timezone: string;
  placeId: string;
  url: string;
}

export interface Address {
  city: string;
  state: string;
  street: string;
  zipCode: string;
  id?: string;
}

export interface BusinessHours {
  day: string;
  open: string;
  close: string;
}

export interface BusinessPhoneNumber {
  id?: string;
  businessId?: string;
  number: string;
  isPrimary?: boolean;
  transferDepartmentName: string | null;
  transferAgentInstructions?: string | null;
  isActive: boolean;
}

export enum BusinessPhoneNumberType {
  Main = 'main',
  CustomerSupport = 'customer_support',
  Emergency = 'emergency'
}
