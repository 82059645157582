import { Injectable } from '@angular/core';
import { AgentService } from '../core/agent/agent.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timeZones } from '../configuration/configuration';
import { ConfigurationHelperService } from '../configuration/configuration-helper.service';
import { UserService } from '../core/user/user.service';
import { CallFinishedNotificationChannel, Configuration, CustomQuestionType } from '../core/agent/agent';
import { concat, debounceTime, distinctUntilChanged, filter, forkJoin, interval, Observable, of, ReplaySubject, Subject, switchMap, takeWhile, tap } from 'rxjs';
import { Business, BusinessPhoneNumberType, Service } from '../core/business/business';
import { QueryParamsHandling, Router } from '@angular/router';
import { ProgressStatus } from '../shared/agent-setup-process/agent-setup-process.component';
import { BusinessService } from '../core/business/business.service';
import { areEqual } from '@rallycommerce/common/utils';
import { AnalyticsEvent, InternalAnalyticsService } from '../core/internal-analytics.service';
import { removeCountryCode } from '../core/helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class GuidedSetupService {
  private isTrainingInProgressInitialized = false;
  initialSetupFormValue: any;
  redirectInProgress: boolean = false;
  progressSubject = new Subject<ProgressStatus>();
  sourceEditModeEnabled: boolean = false;
  activeStep: GuidedSetupStepType = GuidedSetupStepType.Setup;
  setupForm: FormGroup;
  services: string[] = [];
  constructor(private agent: AgentService, private config: ConfigurationHelperService, private fb: FormBuilder, private user: UserService, private router: Router, private businessService: BusinessService, private analytics: InternalAnalyticsService) {
    this.initializeSetupForm();
  }

  get hasGoogleBusinessProfile(): boolean {
    return !!this.agent.businessInformation?.additionalProperties?.placeId;
  }

  get hasBusinessUrl(): boolean {
    return !!this.agent.businessInformation?.additionalProperties?.url;
  }

  get userEmail(): string {
    return this.user.email;
  }

  get business(): Business {
    return this.agent.business;
  }

  get isTrainingInProgress(): boolean {
    return this.agent.isTrainingInProgress;
  }

  get isSourceEditModeEnabled() {
    return this.sourceEditModeEnabled;
  }

  get isInitialSetupCompleted(): boolean {
    return this.agent.isInitialSetupCompleted;
  }

  get isTalkToRosieCompleted(): boolean {
    return this.agent.isTalkToRosieCompleted;
  }

  get isNumberForwardingEnabled(): boolean {
    return this.agent.primary.configuration.numberForwardingEnabled;
  }

  get agentPhoneNumber(): string {
    return this.agent.phoneNumber;
  }

  get isRedirectInProgress(): boolean {
    return this.redirectInProgress;
  }

  addCustomQuestion() {
    const formArray = this.setupForm.get('questions') as FormArray;
    formArray.push(this.config.createCustomQuestion({ question: '' }, false));
  }

  removeCustomQuestion(index: number) {
    const formArray = this.setupForm.get('questions') as FormArray;
    formArray.removeAt(index);
  }

  retrainRosie(): Observable<any> {
    const setupData = this.setupForm.value;
    const placeId = setupData?.placeId;
    this.agent.primary.isTrainingInProgress = true;
    return this.agent.retrainAgent(setupData?.url, placeId);
  }

  goToStep(step: GuidedSetupStepType, queryParams?: any, queryParamsHandling: QueryParamsHandling = ''): void {
    this.redirectInProgress = true;
    this.router.navigate(['/setup', { step }], { queryParams, queryParamsHandling: queryParamsHandling })
      .then(() => {
        if (!this.isInitialSetupCompleted) {
          this.updateAgentConfig({ initialSetupCompleted: true, talkToRosieCompleted: false });
        } else if (step === GuidedSetupStepType.Launch && !this.isTalkToRosieCompleted) {
          this.updateAgentConfig({ talkToRosieCompleted: true });
        }
      }).finally(() => {
        this.redirectInProgress = false;
      });
  }

  updateAgentConfig(configuration: Configuration, omitAgentStateRefresh: boolean = false) {
    this.agent.updateAgentConfiguration({ configuration }, omitAgentStateRefresh)
      .subscribe(() => {
        if (!omitAgentStateRefresh) {
          this.agent.getAgent().subscribe();
        }
      });
  }

  handleTrainingInProgress() {
    if (!this.isTrainingInProgressInitialized) {
      this.isTrainingInProgressInitialized = true;
      this.progressSubject.pipe(filter((status) => status === ProgressStatus.Start))
        .subscribe(() => {
          const refreshAgent = concat(
            this.agent.getAgent().pipe(
              tap((agent) => {
                if (!this.agent.isTrainingInProgress) {
                  this.finalizeAgentRefresh();
                }
              }),
              takeWhile(agent => agent.isTrainingInProgress)
            ),
            interval(2000).pipe(
              switchMap(() => this.agent.getAgent().pipe(
                tap(agent => {
                  if (!this.agent.isTrainingInProgress) {
                    this.finalizeAgentRefresh();
                  }
                })
              )),
              takeWhile(agent => agent.isTrainingInProgress)
            )
          );
          refreshAgent.subscribe();
        })

      if (this.isTrainingInProgress) {
        this.progressSubject.next(ProgressStatus.Start);
      }
    }
  }

  handlePhoneNumberCreation() {
    if (!this.agentPhoneNumber) {
      const refreshAgent = concat(
        this.agent.getAgent().pipe(takeWhile(agent => agent.phoneNumbers?.length === 0)),
        interval(2000).pipe(
          switchMap(() => this.agent.getAgent()),
          takeWhile(agent => agent.phoneNumbers?.length === 0)
        )
      );
      refreshAgent.subscribe();
    }
  }

  restoreSourceInformation() {
    const business = this.agent.business;
    const businessInformation = this.agent.businessInformation?.additionalProperties;
    const value = {
      name: businessInformation?.name || business?.title,
      url: businessInformation.url,
      placeId: businessInformation.placeId,
    };
    this.setupForm.patchValue(value);
  }

  private finalizeAgentRefresh() {
    this.patchSetupForm();
    this.sourceEditModeEnabled = false;
    this.progressSubject.next(ProgressStatus.Finalize);
  }

  private initializeSetupForm() {
    const business = this.agent.business;
    const businessInformation = this.agent.businessInformation?.additionalProperties;
    const primaryAddress = businessInformation?.addresses?.[0];
    const timezone = timeZones.find(tz => tz.value === businessInformation?.timezone)?.label || businessInformation?.timezone;
    const primaryPhoneNumber = business?.phoneNumbers?.find(p => p.transferDepartmentName === BusinessPhoneNumberType.Main);

    this.services = this.business.services?.map((service) => service.name) || [];
    this.sourceEditModeEnabled = !this.hasGoogleBusinessProfile && !this.hasBusinessUrl && !this.isTrainingInProgress;
    this.setupForm = this.fb.group({
      title: [business?.title, Validators.required],
      name: businessInformation?.name || business?.title,
      summary: [businessInformation?.summary, [Validators.required, Validators.maxLength(300)]],
      hours: this.config.createBusinessHours(businessInformation?.businessHours || []),
      timezone: timezone,
      primaryAddress: this.config.createAddress(primaryAddress),
      primaryBusinessPhoneNumber: this.fb.group({ isActive: !!primaryPhoneNumber, number: removeCountryCode(primaryPhoneNumber?.number), transferDepartmentName: BusinessPhoneNumberType.Main, id: primaryPhoneNumber?.id, isPrimary: true }),
      emailNotificationEnabled: this.agent.primary.configuration?.hasOwnProperty('callFinishedNotificationChannel') ? this.agent.primary.configuration.callFinishedNotificationChannel?.includes(CallFinishedNotificationChannel.Email) : true,
      smsNotificationEnabled: this.agent.primary.configuration?.hasOwnProperty('callFinishedNotificationChannel') ? this.agent.primary.configuration.callFinishedNotificationChannel?.includes(CallFinishedNotificationChannel.SMS) : true,
      url: [businessInformation.url, Validators.pattern(/^(https?:\/\/)?(www\.)?([\da-z.-]+\.[a-z]{2,6})(.*)$/i)],
      placeId: businessInformation.placeId,
      questions: this.config.createCustomQuestions(this.agent.customQuestions || [], false),
      contactPhoneNumber: businessInformation?.contactPhoneNumber?.[0] || ''
    });

    this.initialSetupFormValue = this.setupForm.value;
    this.updateNotifications(this.initialSetupFormValue);

    this.setupForm.valueChanges.pipe(
      debounceTime(2500),
      distinctUntilChanged((prev, curr) => areEqual(prev, curr))
    ).subscribe((value) => {
      this.handleUpdates(value);
    });

  }

  private patchSetupForm() {
    const business = this.agent.business;
    const businessInformation = this.agent.businessInformation?.additionalProperties;

    const timezone = timeZones.find(tz => tz.value === businessInformation?.timezone)?.label || businessInformation?.timezone;
    this.services = this.business.services?.map((service) => service.name) || [];
    const value = {
      title: business?.title,
      name: businessInformation?.name || business?.title,
      summary: businessInformation?.summary,
      hours: businessInformation?.businessHours || [],
      timezone: timezone,
      primaryAddress: businessInformation?.addresses?.[0],
      url: businessInformation.url,
      placeId: businessInformation.placeId,
    };
    this.setupForm.patchValue(value, { emitEvent: false });
    this.initialSetupFormValue = this.setupForm.value;
  }

  updateBusinessServices(): Observable<any> {
    const hasChanges = !areEqual(this.services, this.business.services?.map((service) => service.name));

    if (!hasChanges) {
      return of(null);
    }

    const services = this.services.map(service => ({
      name: service,
      isActive: true,
      id: this.getBusinessService(service)?.id || null
    })) || [];;

    return this.businessService.updateServices(services);
  }

  private getBusinessService(serviceName: string) {
    return this.business.services?.find((service) => service.name === serviceName);
  }

  updateBusinessInformation(includeHoursUpdate = false): Observable<any> {
    const isPhoneNumberValid = this.setupForm.get('contactPhoneNumber').valid;
    const contactPhoneNumber = this.agent.businessInformation?.additionalProperties?.contactPhoneNumber || [];
    const businessInfo = this.setupForm.value;
    const timezone = timeZones.find(tz => tz.label === businessInfo.timezone)?.value || businessInfo.timezone;
    const businessInformationData: any = {
      additionalProperties: {
        addresses: businessInfo.primaryAddress ? [businessInfo.primaryAddress] : [],
        timezone: timezone,
        summary: businessInfo.summary,
        contactPhoneNumber
      }
    };

    if (isPhoneNumberValid && !businessInformationData?.additonalProperties?.contactPhoneNumber.includes(businessInfo.contactPhoneNumber)) {
      businessInformationData.additionalProperties.contactPhoneNumber.push(businessInfo?.contactPhoneNumber);
    }

    if (includeHoursUpdate) {
      businessInformationData.additionalProperties.businessHours = businessInfo.hours.map(day => ({
        day: day.day,
        open: this.config.convert12To24HourFormat(day.open),
        close: this.config.convert12To24HourFormat(day.close)
      }))
    }
    return this.businessService.updateInformation(businessInformationData);
  }

  updateBusiness(): Observable<any> {
    const businessForm = this.setupForm.value;
    const title = businessForm.title;
    return this.businessService.update({ title });
  }

  updateCustomQuestions(): void {
    const customQuestions = this.setupForm.get('questions').value?.filter((question) => !!question.question);
    const questions = customQuestions.map((question, index) => ({ question: question.question, priority: index, type: CustomQuestionType.Note }));
    this.agent.updateCustomQuestions(questions).subscribe();
  }

  private handleUpdates(value) {
    if (this.initialSetupFormValue?.emailNotificationEnabled !== value?.emailNotificationEnabled || this.initialSetupFormValue?.smsNotificationEnabled !== value?.smsNotificationEnabled) {
      if (this.initialSetupFormValue?.emailNotificationEnabled !== value?.emailNotificationEnabled) {
        this.analytics.trackEvent(AnalyticsEvent.OnboardingEmailNotificationChanged, { emailNotificationEnabled: value?.emailNotificationEnabled });
      }
      if (this.initialSetupFormValue?.smsNotificationEnabled !== value?.smsNotificationEnabled) {
        this.analytics.trackEvent(AnalyticsEvent.OnboardingTextNotificationChanged, { smsNotificationEnabled: value?.smsNotificationEnabled });
      }
      this.initialSetupFormValue.emailNotificationEnabled = value?.emailNotificationEnabled;
      this.initialSetupFormValue.smsNotificationEnabled = value?.smsNotificationEnabled;
      this.updateNotifications(value);
    }
    if (!areEqual(this.initialSetupFormValue?.questions, value?.questions) && this.setupForm.get('questions').valid) {
      this.initialSetupFormValue.questions = value?.questions;
      this.updateCustomQuestions();
      this.updateCustomQuestionsFlag(value?.questions)
    }

    console.log(this.initialSetupFormValue?.primaryBusinessPhoneNumber);
    console.log(value?.primaryBusinessPhoneNumber)
    if (!areEqual(this.initialSetupFormValue?.primaryBusinessPhoneNumber, value?.primaryBusinessPhoneNumber)) {
      const phoneNumbers = [value?.primaryBusinessPhoneNumber];
      this.analytics.trackEvent(AnalyticsEvent.OnboardingBusinessPhoneNumberChanged);
      this.businessService.updatePhoneNumbers(phoneNumbers).subscribe();
    }
    if (this.initialSetupFormValue?.title !== value?.title) {
      this.initialSetupFormValue.title = value?.title;
      this.analytics.trackEvent(AnalyticsEvent.OnboardingBusinessNameEdited);
      this.updateBusiness().subscribe();
    }

    const hasAddressChanged = !areEqual(this.initialSetupFormValue?.primaryAddress, value?.primaryAddress);
    const hasOverviewChanged = !areEqual(this.initialSetupFormValue?.summary, value?.summary);
    const isPhoneNumberValid = this.setupForm.get('contactPhoneNumber').valid;
    const hasContactPhoneNumberChanged = !areEqual(this.initialSetupFormValue?.contactPhoneNumber, value?.contactPhoneNumber) && isPhoneNumberValid;

    const hasBusinessInfoChanges = hasAddressChanged || hasOverviewChanged || hasContactPhoneNumberChanged;
    if (hasBusinessInfoChanges) {
      if (hasAddressChanged) {
        this.analytics.trackEvent(AnalyticsEvent.OnboardingBusinessAddressEdited);
      }

      if (hasOverviewChanged) {
        this.analytics.trackEvent(AnalyticsEvent.OnboardingBusinessOverviewEdited);
      }

      if (hasContactPhoneNumberChanged) {
        this.analytics.trackEvent(AnalyticsEvent.OnboardingTextNotificationNumberChanged);
      }

      this.initialSetupFormValue.primaryAddress = value?.primaryAddress;
      this.initialSetupFormValue.summary = value?.summary;
      this.initialSetupFormValue.contactPhoneNumber = value?.contactPhoneNumber;

      this.updateBusinessInformation().subscribe();
    }

    if (this.initialSetupFormValue?.url !== value?.url) {
      this.analytics.trackEvent(AnalyticsEvent.SourcesWebsiteUrlEdited);
    }

    if (this.initialSetupFormValue?.placeId !== value?.placeId) {
      this.analytics.trackEvent(AnalyticsEvent.SourcesGoogleProfileEdited);
    }
  }

  private updateNotifications(setupFormValue) {
    const emailNotificationEnabled = setupFormValue?.emailNotificationEnabled;
    const smsNotificationEnabled = setupFormValue?.smsNotificationEnabled;
    const callFinishedNotificationChannel = [];
    if (emailNotificationEnabled && !callFinishedNotificationChannel?.includes(CallFinishedNotificationChannel.Email)) {
      callFinishedNotificationChannel.push(CallFinishedNotificationChannel.Email);
    }
    if (smsNotificationEnabled && !callFinishedNotificationChannel?.includes(CallFinishedNotificationChannel.SMS)) {
      callFinishedNotificationChannel.push(CallFinishedNotificationChannel.SMS);
    }
    this.agent.updateAgentConfiguration({ configuration: { callFinishedNotificationChannel } }).subscribe();
  }

  private updateCustomQuestionsFlag(questions: any[]) {
    this.agent.updateAgentConfiguration({ configuration: { hasCustomQuestionsForNotesEnabled: questions?.length > 0 } }).subscribe();
  }
}


export enum GuidedSetupStepType {
  Setup = 'setup',
  Try = 'try',
  Launch = 'launch',
}

export const guidedSetupSteps = [
  { number: 1, label: 'Quick Set-up', key: GuidedSetupStepType.Setup },
  { number: 2, label: 'Talk to Rosie', key: GuidedSetupStepType.Try },
  { number: 3, label: 'Launch', key: GuidedSetupStepType.Launch }
];

export interface GuidedSetupStep {
  number: number;
  label: string;
  key: GuidedSetupStepType;
}