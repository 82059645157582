import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../auth/authentication-service';
import { UserService } from '../user/user.service';
import { DataStorage } from '../data/data-storage';
import { OnboardingService } from '../../onboarding/onboarding.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {
  persistentStorage: DataStorage = DataStorage.persistent();
  constructor(private auth: AuthenticationService, private router: Router, private user: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.isAuthenticated()) {
      if (!this.user.userData?.id) {
        this.router.navigateByUrl('/register;step=user');
        return false;
      } else {
        return true;
      }
    } else {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    }
  }
}
