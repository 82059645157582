import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {AppsService} from './apps.service';
import {App} from './app/app';
import {DataStorage} from '../core/data/data-storage';

@Injectable({
  providedIn: 'root'
})
export class AppsResolver {
  persistentStorage: DataStorage = DataStorage.persistent();

  constructor(private appsService: AppsService) {
  }

  resolve(): Observable<App[]> {
    const isRefetchNeeded = this.appsService.apps.length === 0;
    return isRefetchNeeded ? this.appsService.getAvailableApps() : of(this.appsService.apps);
  }
}
