import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'rosie-phone',
  styleUrls: ['./phone-input.component.scss'],
  template: `
    <ng-container *ngIf="control">
      <div class="rosie-phone-input-group">
        <input [type]="'tel'" [formControl]="control" #phoneInput
             [placeholder]="placeholderTranslationKey | translate"
             [class]="'rosie-form-control form-control rosie-phone-form-control'" [maxlength]="15"
            [attr.inputmode]="'tel'" [dataHook]="field" scrollIntoView phoneMask 
             [mask]="mask" [phoneControl]="control">
            <rosie-validation-error [show]="control?.invalid && control?.touched && control?.value"
                      [translationKey]="'COMMON.VALIDATION.INVALID_PHONE'"></rosie-validation-error>
             <label *ngIf="labelTranslationKey"  [class]="'rosie-phone-form-label rosie-form-control-label'">
                 {{labelTranslationKey | translate}}
             </label>
      </div>
    </ng-container>
  `,
})
export class PhoneInputComponent {
  errorMessages: string[] = [];
  isPhoneInputFocused = false;

  @Input() control: AbstractControl;
  @Input() labelTranslationKey: AbstractControl;
  @Input() placeholderTranslationKey: AbstractControl;
  @ViewChild('phoneInput') phoneInput: ElementRef;

  constructor() { }


  get mask(): string {
    return '(000) 000-0000';
  }
}
