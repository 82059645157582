import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, map, Observable, tap } from 'rxjs';
import { User, UserService } from '../core/user/user.service';
import { AuthenticationService, RegisterData } from '../core/auth/authentication-service';
import { DataStorage } from '../core/data/data-storage';
import { Business } from '../core/business/business';
import { getApiUrl } from '../core/helpers/rest-utils';
import { QueryParamsHandling, Router } from '@angular/router';
import { CustomerLocation } from './google-lookup/google-lookup.component';
import { InternalAnalyticsService } from '../core/internal-analytics.service';
declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  autocompleteService: any;
  placesService: any;
  userLocation: any = null;
  userLocationLatLon: { lat: number | string, lon: number | string } = { lat: 45.5152, lon: -122.6784 };

  storedGooglePlace: any;
  selectedPlaceId: string;
  activeOnboardingStep: OnboardingStep = OnboardingStep.GoogleLookup;
  storedLead: OnboardingLead;
  persistentStorage = DataStorage.persistent();

  prefilledData: OnboardingPrefilledData = {} as OnboardingPrefilledData;

  constructor(private http: HttpClient, private user: UserService, private auth: AuthenticationService, private router: Router, private analytics: InternalAnalyticsService) {
    this.fetchLocation();
  }

  get userData(): User {
    return this.user.userData;
  }

  get activeStep(): OnboardingStep {
    return this.activeOnboardingStep;
  }

  get activeBusiness(): Business {
    return this.user.activeBusiness;
  }

  get selectedPlace() {
    return this.storedGooglePlace || this.persistentStorage.get('googlePlace');
  }

  get completedSteps(): OnboardingStep[] {
    return this.persistentStorage.get('completedSteps') || [];
  }

  get lead(): OnboardingLead {
    return this.storedLead || this.persistentStorage.get('lead') || '';
  }

  get chosenPath(): OnboardingStep {
    return this.persistentStorage.get('chosenPath') || '';
  }

  setLead(lead: OnboardingLead): void {
    if (lead?.id) {
      this.storedLead = lead;
      this.persistentStorage.set('lead', lead)
      this.analytics.setExternalUserUuid(this.lead?.userUuid)
    }
  }

  clearSelectedPlace(): void {
    this.clearSelectedPlaceDetails();
    this.clearSelectedPlaceId();
  }

  clearSelectedPlaceDetails(): void {
    this.storedGooglePlace = null;
    this.persistentStorage.remove('googlePlace');
  }

  clearSelectedPlaceId(): void {
    this.selectedPlaceId = null;
  }

  setChosenPath(step: OnboardingStep): void {
    this.persistentStorage.set('chosenPath', step);
  }

  clearChosenPath(): void {
    this.persistentStorage.remove('chosenPath');
  }

  register(data: RegisterData): Observable<any> {
    data['leadId'] = this.lead.id;
    return this.auth.register(data).pipe(tap(() => this.persistentStorage.remove('lead')));
  }

  createLead(data: any): Observable<Business> {
    return this.http.post(`${getApiUrl()}leads`, data).pipe(map((lead: any) => lead.data), tap((lead: OnboardingLead) => this.setLead(lead)));
  }

  updateLead(data: any) {
    return this.http.patch(`${getApiUrl()}leads/${this.lead.id}`, data).pipe(map((lead: any) => lead.data), tap((lead: OnboardingLead) => this.setLead(lead)));
  }

  getLead(id?: string): Observable<OnboardingLead> {
    id = id;
    return this.http.get<OnboardingLead>(`${getApiUrl()}leads/${id}`).pipe(map((lead: any) => lead.data));
  }

  goToStep(step: OnboardingStep, queryParams?: any, queryParamsHandling: QueryParamsHandling = ''): void {
    this.router.navigate(['/register', { step }], { queryParams, queryParamsHandling: queryParamsHandling }).then(() => {
      document.getElementById('rosieLogo')?.scrollIntoView();
    });
  }

  setSelectedPlace(place: any): void {
    this.storedGooglePlace = place;
    this.persistentStorage.set('googlePlace', place)
  }

  prepareUrl(url: string = ''): string {
    if (url.includes('@')) {
      url = url.split('@').pop() || '';
    }
    return url.includes('https://') || url.includes('http://') ? url : `https://${url}`;
  }

  private fetchLocation(): void {
    this.http.get<CustomerLocation>('https://extreme-ip-lookup.com/json/?key=8xTSqsy0DdzfxyHnCQdS')
      .subscribe((location: CustomerLocation) => this.setUserLocationLatLon(location));
  }

  private setUserLocationLatLon(location?: CustomerLocation): void {
    const lat = location?.lat || 45.5152;
    const lon = location?.lon || -122.6784;
    this.userLocationLatLon = { lat, lon };
  }
}

export enum OnboardingStep {
  GoogleLookup = 'google_lookup',
  GoogleLookupVerification = 'google_lookup_verification',
  WebsiteUrl = 'website_url',
  BusinessInfo = 'business_info',
  Agent = 'agent',
  Preview = 'preview',
  User = 'user'
}
export interface OnboardingPrefilledData {
  websiteUrl: string,
}

export interface Clip {
  id: string;
  script: string;
  title: string;
  description: string;
}

export interface OnboardingLead {
  id: string;
  businessName: string;
  scrapedAt: Date | null;
  clipsGeneratedAt: Date | null;
  expiresAt: Date | null;
  url: string;
  placeId?: string;
  businessId: string | null;
  isLeadGenerated: boolean;
  clipScripts: Clip[];
  userUuid: string;
}
