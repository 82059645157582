import { Directive, Input, HostListener, ElementRef, HostBinding } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { runOnNextTick } from '@rallycommerce/common/utils';

@Directive({
    selector: '[phoneMask]'
})
export class PhoneMaskDirective {
    _phoneControl: AbstractControl;
    _mask: string;
    temporaryPhoneValue: string;
    caretPosition: number = 1;
    isFocused: boolean;

    /**
    * 
    * A directive to add phone mask in a format of `(000) 000-0000'` when `@Input() mask` is provided.
    * <ul>
    * <li>structures and places digits in predefined mask spots by skipping brackets, empty space and dash while typing in</li>
    * <li>makes sure to cursor always after digit on field focus</li>
    * <li>handles keyboard navigated events e.g. copy/paste or ctrl+A+delete</li>
    * </ul>
    * 
    * ### Example
    * 
    * `phoneMask [phoneControl]="myFormControl" [mask]="'(000) 000-0000'"`
    * 
    */
    @Input()
    set phoneControl(control: AbstractControl) {
        this._phoneControl = control;
    }

    @Input()
    set mask(value: string) {
        value = typeof value === 'string' ? value : '';
        this._mask = value.replace(/0/g, '_');
    }

    constructor(private el: ElementRef) { }

    get mask(): string {
        return this._mask;
    }

    get showMask(): boolean {
        return this.isFocused || this.phoneControlValue ? this.hasMask : false;
    }

    get hasMask(): boolean {
        return !!this.mask;
    }

    get phoneControl(): AbstractControl {
        return this._phoneControl as AbstractControl;
    }

    get phoneControlValue(): string {
        return this.phoneControl?.value;
    }

    get phoneInput(): HTMLInputElement {
        return this.el.nativeElement;
    }

    get phoneInputValue(): string {
        return this.phoneInput.value;
    }

    @HostBinding('class.rally-phone-mask') get showMaskStyles() { return this.showMask; }

    @HostListener('blur', ['$event'])
    onBlurEvent() {
        if (this.hasMask) {
            this.isFocused = false;
            if (this.phoneInputValue === this.mask) {
                this.phoneInput.value = '';
            }
            this.caretPosition = 1;
        }
    }

    @HostListener('focus', ['$event'])
    onFocusEvent() {
        if (this.hasMask) {
            this.isFocused = true;
            if (this.phoneInputValue === '') {
                this.phoneInput.value = this.mask;
            }
            this.moveCaret();
        }
    }


    @HostListener('click', ['$event'])
    onClickEvent() {
        if (this.hasMask) {
            this.setCaretPosition((document.activeElement as HTMLInputElement).selectionStart);
            if (this.phoneInputValue === this.mask) {
                this.moveCaret();
            }
        }
    }
    @HostListener('keydown', ['$event'])
    onKeypress(event: KeyboardEvent): void {
        if (event.key === EventKey.ArrowLeft || event.key === EventKey.ArrowRight ||
            event.key === EventKey.ArrowUp || event.key === EventKey.ArrowDown) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPasteEvent(event: any): void {
        if (this.hasMask) {
            let value = (event.clipboardData || window['clipboardData']).getData('text');
            value = value.replace(/[^A-Z0-9]+/ig, '').trim();
            if (value && value.length === 10) {
                this.handleInputMask({ target: { value } });
                this.phoneControl.patchValue(value);
            }
            event.preventDefault();
        }
    }

    @HostListener('input', ['$event'])
    handleInputMask(event: any) {
        if (this.hasMask) {

            let eventValue = event?.target['value'];
            if (eventValue === null) { eventValue = ''; }

            let previousValueLength = this.temporaryPhoneValue?.replace(/[^0-9]+/g, "").length || 0;
            let eventValueLength = eventValue.replace(/[^0-9]+/g, "").length;
            this.temporaryPhoneValue = eventValue.replace(/\D/g, '');

            // field's maxLenght is initially set to 15 because mask already takes all 14 character places of a field (if 14 is used - typing would not be possible)
            // if 15th character (11th number) is typed in - we slice it - creating 10 number maxLength lookalike
            if (eventValueLength >= 11) {
                eventValueLength = 10;
                this.temporaryPhoneValue = this.temporaryPhoneValue.slice(0, -1);
            }

            if (eventValueLength === previousValueLength && (eventValueLength === 3 || eventValueLength === 6)) {
                this.temporaryPhoneValue = this.temporaryPhoneValue.slice(0, -1);
                this.moveCaret();
                runOnNextTick(() => { this.moveCaret(); });
            }

            if (this.temporaryPhoneValue === '') {
                // if ctrl + A + delete key pressed and whole field value deleted (including mask characters) --> make sure to show again empty mask IF phone field is in focus
                if (this.isFocused && eventValue !== this.mask) {
                    this.phoneInput.value = this.mask;
                    // to prevent "blink" on caret movement assign it right away and runOnNextTick()
                    this.moveCaret();
                    runOnNextTick(() => { this.moveCaret(); });
                }
            } else {
                this.temporaryPhoneValue = this.maskPhone(this.temporaryPhoneValue);
                if (eventValueLength === 10) {
                    this.setCaretPosition(14);
                } else if (previousValueLength === 0 && eventValueLength === 9) {
                    this.setCaretPosition(13);
                } else if (previousValueLength < eventValueLength) {
                    this.setCaretPosition(this.caretPosition + 1);
                    if (this.caretPosition === 4) {
                        this.setCaretPosition(6);
                    } else if (this.caretPosition === 5) {
                        this.setCaretPosition(7);
                    } else if (this.caretPosition === 9) {
                        this.setCaretPosition(10);
                    } else if (this.caretPosition === 10) {
                        this.setCaretPosition(11);
                    }
                } else if (previousValueLength > eventValueLength) {
                    this.setCaretPosition(this.caretPosition - 1);
                    if (this.caretPosition === 5) {
                        this.setCaretPosition(3);
                    } else if (this.caretPosition === 6) {
                        this.setCaretPosition(4);
                    } else if (this.caretPosition === 10) {
                        this.setCaretPosition(9);
                    } else if (this.caretPosition === 9) {
                        this.setCaretPosition(8);
                    } else if (this.caretPosition === 0) {
                        this.setCaretPosition(1);
                    }
                }
                this.phoneInput.value = this.temporaryPhoneValue;
                this.phoneInput.selectionStart = this.phoneInput.selectionEnd = this.getCaretPosition();
                runOnNextTick(() => { this.phoneInput.selectionStart = this.phoneInput.selectionEnd = this.getCaretPosition(); });
            }
        }
    }

    private maskPhone(data: string) {
        if (data.length < 10) {
            let numberOfZerosPlaceholder = 10 - data.length;
            for (let i = 0; i < numberOfZerosPlaceholder; i++) {
                data = data + 'E';
            }
        }
        data = data.replace(/^(\w{0,3})(\w{0,3})(.*)/, '($1) $2-$3');
        data = data.replace(/E/g, '_');
        return data;
    }

    private moveCaret() {
        const emptySpotIndex = this.phoneInputValue.includes('_') ? this.phoneInputValue.indexOf('_') : null;
        const index = emptySpotIndex || this.phoneInputValue.length;
        this.setCaretPosition(index);
        this.phoneInput.selectionStart = this.phoneInput.selectionEnd = this.getCaretPosition();
    }

    private setCaretPosition(position: number) {
        this.caretPosition = Math.abs(position);
    }

    private getCaretPosition() {
        return Math.abs(this.caretPosition);
    }
}

export enum EventKey {
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    Tab = 'Tab',
    Backspace = 'Backspace',
    Delete = 'Delete',
    Esc = 'Escape'
}