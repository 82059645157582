import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserService } from './user/user.service';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { objectPropertiesToArray } from '@rallycommerce/common/utils';

const analytics = AnalyticsBrowser.load({ writeKey: 'vfRBtbAIABlnLFiqstdoJguAmMrWPnFU' })
declare const fbq: any;
declare const ttq: any;
declare const gtag: any;

@Injectable({
  providedIn: 'root'
})

export class InternalAnalyticsService {
  isUserIdentified = false;
  constructor(private user: UserService) {
    this.initializeAnalytics();
  }

  get isProductionEnv(): boolean {
    return environment.name === 'production';
  }

  get userId() {
    return this.user?.id || this.userExternalId;
  }

  get toltReferral(): string {
    return window['tolt_referral'] || localStorage.getItem('tolt_referral') || '';
  }

  get userExternalId(): string {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('uuid') || localStorage.getItem('userExternalId') || '';
  }

  get coupon(): string {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('coupon') || localStorage.getItem('signupCouponCode') || '';
  }

  get referral(): string {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('rrf') || localStorage.getItem('rrf') || '';
  }

  get campaign(): string {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('rcmp') || localStorage.getItem('rcmp') || '';;
  }

  initializeAnalytics() {
    if (this.isProductionEnv) {
      this.initializeFacebookPixel();
      this.initializeGA();
      this.initializeTikTok();
      this.identify();
    }

    this.handleMeta();
  }

  identify() {
    try {
      if (this.isProductionEnv && this.user.id && !this.isUserIdentified) {
        analytics.identify(this.userId, {
          name: this.user?.name,
          email: this.user?.email,
          plan: this.user.subscription?.planName
        });
        this.isUserIdentified = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  trackPage(pageTitle: string | AnalyticsPage) {
    try {
      if (this.isProductionEnv) {
        if (!this.user.hasCreditCardOnFile && !this.toltReferral) {
          this.initializeToltReferral();
        }
        const eventDetails = {
          userId: this.userId,
          name: pageTitle
        };
        analytics.page(eventDetails);
        this.handleFacebookPixelPageView(pageTitle as AnalyticsPage);
        this.handleTikTokPageView(pageTitle as AnalyticsPage);
        this.handleGAPageView(pageTitle as AnalyticsPage);
      }
    } catch (error) {
      console.log(error);
    }
  }

  trackEvent(eventName: AnalyticsEvent, eventDetails: any = {}) {
    try {
      if (this.isProductionEnv && eventName) {
        if (this.userId) {
          eventDetails.userId = this.userId;
        }
        analytics.track(eventName, eventDetails, this.userId as any);
        if (eventName === AnalyticsEvent.UserRegistered) {
          fbq('track', 'Lead');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  fireFirstTimeUserVisit(isSocialLogin = false) {
    try {
      if (this.isProductionEnv) {
        fbq('track', 'CompleteRegistration');
        ttq.identify({ "email": this.user?.email, "external_id": this.userId });
        ttq.track('CompleteRegistration', { "contents": [{ "content_id": "rosie" }] });
        gtag("event", "sign_up", { method: isSocialLogin ? "Google" : "email" });
        gtag('event', 'conversion_event_signup', {});
      }
    } catch (error) {
      console.log(error);
    }
  }

  private initializeFacebookPixel() {
    const facebookPixelEl = document.createElement('script');
    const facebookPixelNoScriptEl = document.createElement('noscript');
    facebookPixelEl.type = 'text/javascript';
    facebookPixelEl.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '891907839484716');
      fbq('track', 'PageView');
  `;
    facebookPixelNoScriptEl.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=26196183753362886&ev=PageView&noscript=1"/>`
    document.body.appendChild(facebookPixelEl);
    document.body.appendChild(facebookPixelNoScriptEl);
  }

  private initializeGA() {
    const GAInitializeEl = document.createElement('script');
    GAInitializeEl.async = true;
    GAInitializeEl.src = 'https://www.googletagmanager.com/gtag/js?id=G-8XN13HV4CR';
    const GAEl = document.createElement('script');
    GAEl.type = 'text/javascript';
    GAEl.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date()); 
        gtag('config', 'G-8XN13HV4CR');
  `;
    document.body.appendChild(GAInitializeEl);
    document.body.appendChild(GAEl);
  }

  private initializeTikTok() {
    const tikTokEl = document.createElement('script');
    tikTokEl.type = 'text/javascript';
    tikTokEl.innerHTML = `
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
        var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
        ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
          ttq.load('CRC9E7RC77UBSLKS3BEG');
          ttq.page();
        }(window, document, 'ttq');
  `;
    document.body.appendChild(tikTokEl);
  }

  initializeToltReferral() {
    const tolReferralEl = document.createElement('script');
    tolReferralEl.type = 'text/javascript';
    tolReferralEl.async = true;
    tolReferralEl.setAttribute('src', 'https://cdn.tolt.io/tolt.js');
    tolReferralEl.setAttribute('data-tolt', 'b1fde686-cdf1-4a24-8410-ebae42dc1f49');

    tolReferralEl.onload = () => {
      setTimeout(() => {
        if (window['tolt_referral']) {
          localStorage.setItem('tolt_referral', window['tolt_referral']);
        }
      }, 2500);
    };

    document.body.appendChild(tolReferralEl);
  }

  setExternalUserUuid(userUuid: string) {
    if (userUuid) {
      localStorage.setItem('userExternalId', userUuid);
    }
  }


  private handleFacebookPixelPageView(pageTitle: AnalyticsPage) {
    if (this.isOnboardingPage(pageTitle)) {
      fbq('track', 'ViewContent');
    }
    if (pageTitle === AnalyticsPage.UserRegistration) {
      fbq('track', 'Lead');
    }
  }

  private handleTikTokPageView(pageTitle: AnalyticsPage) {
    if (this.isOnboardingPage(pageTitle)) {
      ttq.track('ViewContent', {
        "contents": [
          {
            "content_id": "rosie",
            "content_type": "product",
            "content_name": "package"
          }
        ]
      });
    }
  }

  private handleGAPageView(pageTitle: AnalyticsPage) {
    if (this.isOnboardingPage(pageTitle)) {
      gtag('event', 'page_view', { 'page_title': pageTitle });
    }
    if (pageTitle === AnalyticsPage.UserRegistration) {
      gtag("event", "generate_lead", {
        lead_source: "Landing Page",
        items: [
          {
            item_id: "rosie",
          }
        ]
      });
    }
  }

  private handleMeta() {
    const queryParams = new URLSearchParams(window.location.search);
    const coupon = queryParams.get('coupon');
    const userUuid = queryParams.get('uuid');
    const referral = queryParams.get('rrf');
    const campaign = queryParams.get('rcmp');
    if (coupon) {
      localStorage.setItem('signupCouponCode', coupon);
    }

    if (referral) {
      localStorage.setItem('rrf', referral);
    }

    if (campaign) {
      localStorage.setItem('rcmp', campaign);
    }

    this.setExternalUserUuid(userUuid);
  }

  private isOnboardingPage(pageTitle: AnalyticsPage): boolean {
    return objectPropertiesToArray(AnalyticsPage).includes(pageTitle);
  }
}

export enum AnalyticsEvent {
  AppInstalled = 'app_installed',
  CallStarred = 'call_starred',
  CallUnstarred = 'call_unstarred',
  CallViewed = 'call_viewed',
  ClearRecordings = 'clear_recordings',
  CardAdded = 'card_added',
  CardAddedError = 'card_added_error',

  GooglePlacesBusinessSearched = 'google_places_business_searched',
  GooglePlacesBusinessSelected = 'google_places_business_selected',
  GooglePlacesBusinessClickedContinue = 'google_places_business_clicked_continue',
  GooglePlacesBusinessSearchAgain = 'google_places_business_search_again',
  GooglePlacesBusinessTrainAction = 'google_places_business_train_action',

  GooglePlacesAddressSearched = 'google_places_address_searched',
  GooglePlacesAddressSelected = 'google_places_address_selected',

  WebsiteUrlTrainingSelected = 'website_url_training_selected',
  WebsiteUrlEntered = 'website_url_entered',
  WensiteUrlTrainAction = 'website_url_train_action',

  ManualBusinessInfoSelected = 'manual_business_info_selected',
  ManualBusinessInfoEntered = 'manual_business_info_entered',
  ManualBusinessInfoTrainAction = 'manual_business_info_train_action',

  AgentBuildStarted = 'agent_build_started',
  AgentPreviewReady = 'agent_preview_ready',
  AgentPreviewClipClicked = 'agent_preview_clip_clicked',
  AgentPreviewClaimAction = 'agent_preview_claim_action',

  GoogleSSOClicked = 'google_sso_clicked',
  UserInfoEntered = 'user_info_entered',
  UserRegisterAction = 'user_register_action',
  UserRegistered = 'user_registered',

  OnboardingQuickSetup = 'onboarding_quick_setup',
  OnboardingTryItOut = 'onboarding_try_it_out',
  OnboardingLaunch = 'onboarding_launch',

  SourcesEditClicked = 'sources_edit_clicked',
  SourcesWebsiteUrlEdited = 'sources_website_url_edited',
  SourcesGoogleProfileEdited = 'sources_google_profile_edited',
  SourcesRetrainAction = 'sources_retrain_action',
  SourcesCancelAction = 'sources_cancel_action',

  OnboardingBusinessNameEdited = 'onboarding_business_name_edited',
  OnboardingBusinessOverviewEdited = 'onboarding_business_overview_edited',
  OnboardingBusinessHoursEdited = 'onboarding_business_hours_edited',
  OnboardingBusinessAddressEdited = 'onboarding_business_address_edited',
  OnboardingBusinessServiceAdded = 'onboarding_business_service_added',
  OnboardingBusinessServiceRemoved = 'onboarding_business_service_removed',
  OnboardingQuestionAdded = 'onboarding_question_added',
  OnboardingQuestionRemoved = 'onboarding_question_removed',
  OnboardingEmailNotificationChanged = 'onboarding_email_notification_changed',
  OnboardingTextNotificationChanged = 'onboarding_text_notification_changed',
  OnboardingTextNotificationNumberChanged = 'onboarding_text_notification_number_changed',
  OnboardingBusinessPhoneNumberChanged = 'onboarding_business_phone_number_changed',

  OnboardingTalkToRosieAction = 'onboarding_talk_to_rosie_action',
  OnboardingCallRosieWeb = 'onboarding_call_rosie_web',
  OnboardingCallRosiePhone = 'onboarding_call_rosie_phone',
  OnboardingContinueAction = 'onboarding_continue_action',

  OnboardingProgressBarAction = 'onboarding_progress_bar_action',

  OnboardingLaunchViewInstructions = 'onboarding_launch_view_instructions',
  OnboardingLaunchCheckedNumberIsForwarding = 'onboarding_launch_checked_number_is_forwarding',
  OnboardingLaunchUncheckedNumberIsForwarding = 'onboarding_launch_unchecked_number_is_forwarding',
  OnboardingLaunchNeedsHelpAction = 'onboarding_launch_needs_help_action',
  OnboardingLaunchAddCreditCardAction = 'onboarding_launch_add_credit_card_action',
  OnboardingFaqsAction = 'onboarding_launch_faqs_action',
  OnboardingNotificationAction = 'onboarding_launch_notification_action',
  OnboardingCallsAction = 'onboarding_launch_calls_action',

  CustomGreetingChanged = 'custom_greeting_changed',
  CustomGreetingEnabled = 'custom_greeting_enabled',
  CustomGreetingDisabled = 'custom_greeting_disabled',
  LegalDisclaimerEnabled = 'legal_disclaimer_enabled',
  LegalDisclaimerDisabled = 'legal_disclaimer_disabled',

  NotificationEmailAdded = 'notification_email_added',
  NotificationEmailRemoved = 'notification_email_removed',
  NotificationPhoneNumberAdded = 'notification_phone_number_added',
  NotificationPhoneNumberRemoved = 'notification_phone_number_removed',

  BusinessInformationTitleEdited = 'business_information_title_edited',
  BusinessInformationSummaryEdited = 'business_information_summary_edited',
  BusinessInformationAddressUpdated = 'business_information_address_updated',
  BusinessInformationTimezoneChanged = 'business_information_timezone_changed',
  BusinessPhoneNumberChanged = 'business_phone_number_changed',


  AgentProfileToneChanged = 'agent_profile_tone_changed',
  AgentProfileTitleChanged = 'agent_profile_title_changed',

  AppointmentsEnabled = 'appointments_enabled',
  AppointmentsDisabled = 'appointments_disabled',
  AppointmentsMessageChanged = 'appointments_message_changed',
  AppointmentsUrlChanged = 'appointments_url_changed',

  SpamFilterSmartDetectionEnabled = 'spam_filter_smart_detection_enabled',
  SpamFilterSmartDetectionDisabled = 'spam_filter_smart_detection_disabled',
  SpamFilterBlock1800NumbersEnabled = 'spam_filter_block_1800_numbers_enabled',
  SpamFilterBlock1800NumbersDisabled = 'spam_filter_block_1800_numbers_disabled',

  TrainingFaqsChanged = 'training_faqs_changed',

  TrainingFilesUploaded = 'training_files_uploaded',
  TrainingFilesDeleted = 'training_files_deleted',

  TransferCallsEnabled = 'transfer_calls_enabled',
  TransferCallsDisabled = 'transfer_calls_disabled',
  TransferCallsPhoneNumberChanged = 'transfer_calls_phone_number_changed',

  ShareRosieInitiated = 'share_rosie_initiated',
  ShareRosieAction = 'share_rosie_action',

  AddCreditCartAlertInitiated = 'add_credit_cart_alert_initiated',
}

export enum AnalyticsPage {
  LeadInfoGoogleProfile = 'lead_info_google_profile_page',
  LeadInfoManual = 'lead_info_manual_page',
  LeadInfoWebsite = 'lead_info_website_page',
  LeadGenerationStart = 'lead_generation_start_page',
  LeadGenerationPreview = 'lead_generation_preview_page',
  UserRegistration = 'account_creation_page',
  OnboardingQuickSetup = 'onboarding_quick_setup_page',
  OnboardingTryItOut = 'onboarding_try_it_out_page',
  OnboardingLaunch = 'onboarding_launch_page',

  ConfigurationBusinessInformation = 'configuration_business_information_page',
  ConfigurationAgentProfile = 'configuration_agent_profile_page',
  ConfigurationGreeting = 'configuration_greeting_page',
  ConfigurationTakeAMessage = 'configuration_take_a_message_page',
  ConfigurationAppointments = 'configuration_appointments_page',
  ConfigurationSpamFilter = 'configuration_spam_filter_page',
  ConfigurationTrainingFaqs = 'configuration_training_faqs_page',
  ConfigurationTrainingFiles = 'configuration_training_files_page',
  ConfigurationTransferCalls = 'configuration_transfer_calls_page'
}
