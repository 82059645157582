<div class="section-wrapper">
  <div class="section-header">
    <ng-icon class="section-icon" [name]="'heroBell'" [size]="'24px'" [color]="'#822AC6'" [strokeWidth]="'2'"></ng-icon>
    <div class="section-heading">
      {{ 'ACCOUNT_SECTION.ACCOUNT.CALL_NOTIFICATIONS' | translate }}
    </div>
  </div>
  <div class="section-info">
    <img class="icon" [src]="'assets/images/setup-guide-info.svg'">
    <span class="text">{{'ACCOUNT_SECTION.ACCOUNT.NOTIFICATIONS_DESCRIPTION' |
      translate}}</span>
  </div>
  <div class="section-content-wrapper pb-4">
    <div class="d-flex justify-content-between align-items-start">
      <div class="notifications-wrapper">
        <div class="section-content-label">{{ 'ACCOUNT_SECTION.ACCOUNT.EMAIL_NOTIFICATIONS' | translate }}</div>
      </div>
      <rosie-switch [control]="callNotifications?.get('emailNotificationEnabled')"
        class="notification-switch"></rosie-switch>
    </div>
  </div>
  <div class="section-content-wrapper pt-0" *ngIf="callNotifications?.get('emailNotificationEnabled')?.value">
    <div class="section-action-header" [ngClass]="{'with-items': emailControl?.controls?.length > 0}">
      <div>
        <rosie-text [control]="temporaryEmail" class="header-input"
          [placeholderTranslationKey]="'ACCOUNT_SECTION.ACCOUNT.TYPE_EMAIL'" [lowercaseOnly]="true"></rosie-text>
        <rosie-validation-error [show]="temporaryEmail?.errors?.email && temporaryEmail?.touched"
          [translationKey]="'COMMON.VALIDATION.PATTERN'"
          [translationAttributes]="{value: 'Email'}"></rosie-validation-error>
      </div>
      <rosie-button [customClass]="'action wide text-lg'" [translationKey]="'COMMON.ADD'"
        (click)="addEmail()"></rosie-button>
    </div>
    <div class="section-list-item-wrapper with-action-header" *ngIf="emailControl?.controls?.length > 0">
      <div *ngFor="let email of emailControl?.controls; let last = last; let i = index;" class="section-list-item"
        [ngClass]="{'last': last}">
        <div class="text">{{email.value}}</div>
        <div class="section-list-icons">
          <div class="icon"><ng-icon [name]="'heroPencilSquare'" [color]="'#6C6176'" [size]="'20px'"
              (click)="editNotification(i, CallFinishedNotificationChannel.Email)"></ng-icon>
          </div>
          <div class="icon"><ng-icon [name]="'heroTrash'" [color]="'#6C6176'" [size]="'20px'"
              (click)="removeEmail(i)"></ng-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="separator full my-0"></div>
  <div class="section-content-wrapper">
    <div class="d-flex justify-content-between align-items-start">
      <div class="notifications-wrapper">
        <div class="section-content-label">{{ 'ACCOUNT_SECTION.ACCOUNT.TEXT_NOTIFICATIONS' | translate }}</div>
      </div>
      <rosie-switch [control]=" callNotifications?.get('smsNotificationEnabled')"
        class="notification-switch"></rosie-switch>
    </div>
  </div>
  <div class="section-content-wrapper pt-0" *ngIf="callNotifications?.get('smsNotificationEnabled')?.value">
    <div class="section-action-header" [ngClass]="{'with-items': phoneNumbersControl?.controls?.length > 0}">
      <div><rosie-phone [control]="temporaryPhoneNumber" class="header-input"
          [placeholderTranslationKey]="'ACCOUNT_SECTION.ACCOUNT.TYPE_NUMBER'"></rosie-phone>
        <rosie-validation-error [show]="temporaryPhoneNumber?.errors?.required && temporaryPhoneNumber?.touched"
          [translationAttributes]="{value: 'Phone number'}"
          [translationKey]="'COMMON.VALIDATION.REQUIRED'"></rosie-validation-error>
      </div>
      <rosie-button [customClass]="'action wide text-lg'" [translationKey]="'COMMON.ADD'"
        (click)="addPhoneNumber()"></rosie-button>
    </div>
    <div class="section-list-item-wrapper with-action-header" *ngIf="phoneNumbersControl?.controls?.length > 0">
      <div *ngFor="let number of phoneNumbersControl?.controls; let last = last; let i = index;"
        class="section-list-item" [ngClass]="{'last': last}">
        <div class="text">{{number?.value | phoneNumber}}</div>
        <div class="section-list-icons">
          <div class="icon"><ng-icon [name]="'heroPencilSquare'" [color]="'#6C6176'" [size]="'20px'"
              (click)="editNotification(i, CallFinishedNotificationChannel.SMS)"></ng-icon>
          </div>
          <div class="icon"><ng-icon [name]="'heroTrash'" [color]="'#6C6176'" [size]="'20px'"
              (click)="removePhoneNumber(i)"></ng-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-smart-modal #editNotificationModal identifier="editNotificationModal">
  <div class="modal-title with-subtitle">
    {{ selectedNotificationItem?.type === CallFinishedNotificationChannel.Email ? 'Edit email' : 'Edit number' |
    translate}}
  </div>
  <div class="modal-subtitle with-border"></div>
  <div class="modal-content">
    <ng-container *ngIf="selectedNotificationItem?.type === CallFinishedNotificationChannel.Email">
      <rosie-text [control]="selectedNotificationItem.control"
        [placeholderTranslationKey]="'ACCOUNT_SECTION.ACCOUNT.TYPE_EMAIL'" [lowercaseOnly]="true"></rosie-text>
      <rosie-validation-error
        [show]="selectedNotificationItem.control?.errors?.email && selectedNotificationItem.control?.touched"
        [translationKey]="'COMMON.VALIDATION.PATTERN'"
        [translationAttributes]="{value: 'Email'}"></rosie-validation-error>
    </ng-container>
    <ng-container *ngIf="selectedNotificationItem?.type === CallFinishedNotificationChannel.SMS">
      <rosie-phone [control]="selectedNotificationItem.control"
        [placeholderTranslationKey]="'ACCOUNT_SECTION.ACCOUNT.TYPE_NUMBER'"></rosie-phone>
    </ng-container>
  </div>
  <div class="modal-action-line with-border">
    <rosie-button (click)="editNotificationModal.close()" [translationKey]="'COMMON.CANCEL'"
      [customClass]="'secondary narrow'">
    </rosie-button>
    <rosie-button (click)="handleNotificationChangeSave()" [customClass]="'slim narrow'"
      [translationKey]="'COMMON.SAVE'">
    </rosie-button>
  </div>
</ngx-smart-modal>