import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'rosie-display-field',
  templateUrl: './display-field.component.html',
  styleUrls: ['./display-field.component.scss']
})
export class DisplayFieldComponent {
  isRevealEnabled = false;

  @Input() labelKey = '';
  @Input() helpTextKey = '';
  @Input() value = '';
  @Input() behaviour: FieldBehaviour = FieldBehaviour.Plain;
  @Input() icon = '';

  constructor(private translate: TranslateService) {
  }

  get actionText(): string {
    const actionTranslationKey = this.isRevealEnabled ? 'FIELD.HIDE' : 'FIELD.REVEAL';
    return this.translate.instant(actionTranslationKey);
  }

  get label(): string {
    return this.translate.instant(this.labelKey);
  }

  get helpText(): string {
    return this.helpTextKey ? this.translate.instant(this.helpTextKey) : '';
  }

  get hasRevealBehaviour(): boolean {
    return this.behaviour === FieldBehaviour.Reveal;
  }

  get hasRestrictedBehaviour(): boolean {
    return this.behaviour === FieldBehaviour.Restricted;
  }

  get mockedDotsLength() {
    return new Array(Math.min(this.value.length, 50));
  }

  get hasIconValue() {
    return !!this.icon;
  }

  getValue() {
    return this.hasRestrictedBehaviour ? this.value.substring(this.value.length - 4) : this.value;
  }

  toggleReveal() {
    this.isRevealEnabled = !this.isRevealEnabled;
  }
}


export enum FieldBehaviour {
  Restricted = 'restricted',
  Reveal = 'reveal',
  Plain = 'plain',
  Hidden = 'hidden'
}
