import { Component, Input } from '@angular/core';
// import { faHeart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rosie-integration-animation',
  template: `
    <div class="row justify-content-center align-items-center integration-wrapper">
      <div class="col-sm-11 text-center">
        <div class="integration">
          <div class="text-center">
            <rosie-loader [config]="{show: true, customLoaderClass: 'integration-loader', customSpinnerClass: 'integration-spinner', customLoaderWrapperClass: 'integration-loader-wrapper'}"></rosie-loader>
            <div class="integration-heading">{{ "INTEGRATION.HEADING" | translate:{integrationTitle: integrationTitle} }}</div>
            <div class="integration-description">{{ "INTEGRATION.DESCRIPTION" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./integration-animation.component.scss']
})
export class IntegrationAnimationComponent {
  @Input() integrationTitle: string;
  @Input() integrationType: string;
  @Input() fullScreen = true;

  constructor() {
  }
}
