import { Injectable } from '@angular/core';
import { DataStorage } from '../data/data-storage';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getApiUrl } from '../helpers/rest-utils';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { QueryParamsService } from '../helpers/query-params.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  persistentStorage: DataStorage = DataStorage.persistent();
  sessionStorage: DataStorage = DataStorage.session();


  constructor(private http: HttpClient, private router: Router, private queryParams: QueryParamsService) {
    this.presetTokenFromUrl();
  }

  authenticate(email: string, password: string): Observable<any> {
    this.removeAuth();
    return this.http.post(`${getApiUrl()}login`, { email, password }, { headers: { 'Clear-Site-Data': '*' } })
      .pipe(map((res: any) => res?.data?.token), tap((token) => this.setToken(token)));
  }

  register(data: RegisterData): Observable<any> {
    this.removeAuth();
    if (!data.passwordConfirmation) {
      data.passwordConfirmation = data.password;
    }
    data.email = data.email.toLowerCase();
    return this.http.post(`${getApiUrl()}register`, data, { withCredentials: true })
      .pipe(map((res: any) => res?.data?.token), tap((token) => this.setToken(token)));
  }

  revoke(): void {
    this.http.post(`${getApiUrl()}logout`, {})
      .subscribe(() => this.clearAuthData());
  }

  clearAuthData() {
    this.removeAuth();
    this.router.navigateByUrl('login');
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  setToken(token: string): void {
    if (token) {
      this.persistentStorage.set(this.getPrefixedKey('token'), token);
    }
  }

  removeAuth(): void {
    this.persistentStorage.remove(this.getPrefixedKey('token'));
  }

  getToken(): string {
    return this.persistentStorage.get(this.getPrefixedKey('token'));
  }

  getPrefixedKey(key: string) {
    return `rosie-${key}`;
  }

  signInWithGoogle(leadId?: string) {
    const leadParam = leadId ? `?lead_id=${leadId}` : '';
    window.open(`https://${environment.api.baseUrl}/google/redirect${leadParam}`, '_self');
  }

  private presetTokenFromUrl() {
    const token = this.queryParams.getByKey('t', true);
    if (token) {
      DataStorage.persistent().set('login', true);
      this.setToken(token);
    }
  }
}

export interface AuthResponse {
  token?: string;
}


export interface RegisterData {
  email: string;
  password: string;
  passwordConfirmation: string;
  name: string;
  leadId: string;
}
