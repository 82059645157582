import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AppsService } from '../../../apps.service';
import { App, AppModal } from '../../app';

@Component({
  selector: 'rosie-app-status',
  templateUrl: './app-status.component.html',
})
export class AppStatusComponent {

  @Input() app: App;
  @Input() form: TemplateRef<any>;

  @Output() install = new EventEmitter<void>();
  @Output() updateAppProfile = new EventEmitter<any>();

  constructor(private apps: AppsService, public modal: NgxSmartModalService) {
  }

  get showPostInstallFields(): boolean {
    return this.app?.isInstalled && this.app?.hasPostInstallFields;
  }

  get updateModalActionConfig(): { translationKey: string, actionFunc: any } {
    return this.app?.hasFormFlow ?
      { translationKey: 'APP.UPDATE', actionFunc: () => this.install.emit() } :
      { translationKey: 'APP.UPDATE', actionFunc: () => this.updateAppProfile.emit(this.getUpdatedFormValues()) };
  }

  get formOptions(): any {
    const formOptions = this.app?.setupData?.formOptions || {};
    this.app?.setupData?.formFields?.forEach((field) => {
      const formFieldOption = formOptions[field.name] || {};
      formOptions[field.name] = { ...formFieldOption, hidden: !this.app.isFieldActionPostInstall(field.name) };
    });
    return formOptions;
  }

  getUpdatedFormValues() {
    const formValues = {};
    Object.keys(this.formOptions).forEach((key) => {
      if (!this.formOptions[key].hidden) {
        formValues[key] = this.app?.setupData?.form?.value[key];
      }
    });
    return formValues;
  }


  getAppStatusTranslation(translationKey: string, showPrefix = false): string {
    return this.apps.buildTranslation(translationKey, { app: this.app, useStatus: true, useAppKey: true, showPrefix });
  }

  close(): void {
    this.modal.getModal(AppModal.Install).close();
  }
}
