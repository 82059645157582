import {AfterViewInit, Component, EventEmitter, Input, Output, QueryList, ViewChildren} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FileConfig, FileUploadStatus} from 'src/app/shared/file-upload/file-upload.component';
import {
  FileData,
  FilePurpose,
  FileUploadComponent,
  FileUploadConfig,
  FileUploadData,
  FileUploadSource
} from '../../shared/file-upload/file-upload.component';
import {runOnNextTick} from '@rallycommerce/common/utils';

@Component({
  selector: 'rosie-image-upload',
  template: `
    <div class="w-100 d-block">
      <div *ngIf="showImage" class="w-100 logo-wrapper">
        <div class="logo">
          <img [src]="imageConfig.url"/>
        </div>
        <rosie-dropdown [config]="imageConfig.dropdownConfig" (actionSelected)="handleActionTriggered($event)"
                        (dropdownToggled)="handleDropdownToggled($event)"></rosie-dropdown>
      </div>
      <rosie-file-upload
        *ngIf="showFileUpload"
        #fileUpload
        [ngClass]="{'w-100 d-block': !isImageReplaceTriggered || isImageUploadInProgress || isImageUploadError, 'd-none': isImageReplaceTriggered && !isImageUploadError}"
        [config]="fileUploadConfig"
        (fileData)="handleImageUpload($event)"
        (uploadStatusChanged)="handleUploadStatusChanged($event)"
        [acceptFileTypes]='acceptFileTypes'
        [additionalData]="fileUploadAdditionalData"
      ></rosie-file-upload>
    </div>
    <div class="logo-description pt-3" [innerHTML]="getDescription(imageConfig.filePurpose)">
    </div>
  `,
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements AfterViewInit {
  imageStatus: FileUploadStatus;

  @Input() imageConfig: FileConfig;
  @Input() acceptFileTypes = 'image/png, image/jpeg, image/jpg, image/svg+xml';
  @Input() imageType: FilePurpose;
  @Output() imageChanged = new EventEmitter<string>();
  @ViewChildren('fileUpload') fileUploadQueryList: QueryList<FileUploadComponent>;

  private fileUpload: FileUploadComponent;

  constructor(private translate: TranslateService) {
  }

  get isImageReplaceTriggered(): boolean {
    return this.imageStatus === FileUploadStatus.ReplaceInProgress;
  }

  get isImageUploadInProgress(): boolean {
    return this.imageStatus === FileUploadStatus.UploadInProgress;
  }

  get isImageUploadError(): boolean {
    return this.imageStatus === FileUploadStatus.Error;
  }

  get showFileUpload(): boolean {
    return (this.imageConfig.url === '') || this.isImageReplaceTriggered || this.isImageUploadInProgress || this.isImageUploadError;
  }

  get showImage(): boolean {
    return this.imageConfig.url && !this.isImageUploadInProgress;
  }

  get fileUploadAdditionalData(): FileUploadData {
    return {
      origin: FileUploadSource.Media,
      data: {filePurpose: this.imageType}
    };
  }

  get fileUploadConfig(): FileUploadConfig {
    return {
      customClass: this.getFileUploadCustomClasses(),
      loaderText: 'EDITOR.DESIGN_SECTION.LOGO.UPLOADING_FILE',
      ctaTranslationKey: ''
    };
  }

  ngAfterViewInit() {
    this.fileUploadQueryList?.changes?.subscribe((components: QueryList<FileUploadComponent>) => this.fileUpload = components.first);
  }

  handleImageUpload(fileData: FileData) {
    this.imageConfig.url = fileData.src;
    this.imageStatus = FileUploadStatus.Uploaded;
    this.imageChanged.emit(fileData.src);
  }

  handleUploadStatusChanged(fileUploadStatus: FileUploadStatus) {
    this.imageStatus = fileUploadStatus;
  }

  clearLogo(): void {
    this.imageConfig.url = '';
    this.imageChanged.emit('');
  }

  handleActionTriggered(action: ImageAction): void {
    if (action == ImageAction.Remove) {
      this.deleteImage();
    }
    if (action == ImageAction.Replace) {
      this.imageStatus = FileUploadStatus.ReplaceInProgress;
      // we need to wait for `FileUploadComponent` to be rendered before accessing its function
      runOnNextTick(() => this.fileUpload?.openFileDialog());
    }
  }

  handleDropdownToggled(isOpened: boolean) {
    if (isOpened) {
      this.imageStatus = FileUploadStatus.Uploaded;
    }
  }

  deleteImage(): void {
    this.clearLogo();
  }

  getDescription(filePurpose: FilePurpose): string {
    return this.translate.instant(`EDITOR.DESIGN_SECTION.${filePurpose.toUpperCase()}.DESCRIPTION`);
  }

  getFileUploadCustomClasses(): string {
    let customClasses = '';
    if (this.isImageUploadError && this.showImage) {
      customClasses = `d-none ${customClasses}`;
    }
    if (this.imageConfig.fileUploadConfig) {
      customClasses = `height-${this.imageConfig.fileUploadConfig.style.height} ${customClasses}`;
    }
    return customClasses;
  }

}

export enum ImageAction {
  Replace = 'replace',
  Remove = 'remove'
}
