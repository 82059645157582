import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getApiUrl } from '../core/helpers/rest-utils';
import { Observable } from 'rxjs';
import {
  App,
  AppFieldStage,
  AppFieldType,
  AppFormField,
  AppFormFieldValidation,
  AppKey,
  AppProfile,
  AppSetup,
  AppStatus,
  AppTranslationConfig,
  DefaultApp,
  RawApp,
  RawFormField
} from './app/app';
import { map } from 'rxjs/operators';
import { FieldBehaviour } from '../shared/display-field/display-field.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { setRequired } from '../core/helpers/form-utils';
import { Router } from '@angular/router';
import { CaseType, convertCamelCaseToSnakeCase, sortArrayByNumericAttribute } from '../core/helpers/utils';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../core/user/user.service';
import { AnalyticsEvent, InternalAnalyticsService } from '../core/internal-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class AppsService {
  public allApps: App[] = [];


  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private router: Router, private user: UserService, private translate: TranslateService, private analytics: InternalAnalyticsService) {
  }

  get apps(): App[] {
    return this.allApps;
  }

  get scheduleAppointmentsApp(): App {
    return this.allApps.find(app => app.isInstalled);
  }

  getAvailableApps(): Observable<App[]> {
    return this.http.get(`${getApiUrl()}apps`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } }).pipe(map((res: any) => res?.data))
      .pipe(map((apps: RawApp[]) => {
        this.allApps = this.appsFactory(apps);
        return this.allApps;
      })) as Observable<App[]>;
  }

  install(app: App, data: any = {}): Observable<any> {
    this.analytics.trackEvent(AnalyticsEvent.AppInstalled, { app: app.key });
    return this.http.post(`${getApiUrl()}apps/${app.id}/install`, this.prepareFormData(app, data), { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => res.data));
  }

  uninstall(app: App): Observable<any> {
    return this.http.post(`${getApiUrl()}apps/${app.id}/uninstall`, {}, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } });
  }

  verify(appId: string): Observable<AppStatus> {
    return this.http.get(`${getApiUrl()}apps/${appId}/check-install`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => {
        const app = res.data;
        return app?.installStatus as AppStatus;
      }));
  }

  getUrl(app: App, isOauthFlow = true): Observable<AppSetup> {
    const data = {};
    if (isOauthFlow) {
      data['callbackUrl'] = `${window.location.origin}${this.router.url}/finalize/${app.key}`;
      app.setupData.formFields.forEach((field: AppFormField) => data[`${field.name}`] = app.setupData.form.controls[`${field.name}`]?.value);
    }
    return this.http.post(`${getApiUrl()}apps/${app.id}/install`, data, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => {
        const setup = res.data;
        return { url: setup.redirectUrl };
      }));
  }

  finalizeIntegration(params: any, appId: string) {
    return this.http.post(`${getApiUrl()}apps/${appId}/finalize`, params, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(
        map((res: any) => res?.data));
  }

  getAppData(app: App): Observable<AppProfile> {
    return this.http.get(`${getApiUrl()}apps/${app.id}`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } }).pipe(map((res: any) => this.buildAppProfile(res, app)));
  }

  refreshApp(app: App) {
    this.getAppData(app).subscribe((appData: AppProfile) => {
      app.details.profile = appData;
      app.inProgress = false;
      if (app.hasFormFields) {
        this.resetForm(app);
      }
    }, () => app.inProgress = false);
  }

  addFormArrayField(formArray: UntypedFormArray, fieldName: string, isRequired: boolean) {
    const fieldControl = this.formBuilder.control({ value: null, disabled: false });
    fieldControl['fieldName'] = fieldName;
    formArray?.push(fieldControl);
    if (isRequired) {
      setRequired(fieldControl);
    }
  }

  removeFormArrayField(formArray: UntypedFormArray, index: number) {
    formArray?.removeAt(index);
  }

  resetForm(app: App) {
    if (app.hasFormFlow) {
      if (app.setupData?.formFields?.some(field => !!field.fields)) {
        // if app has an array form, we need to rebuild the form
        app.setupData.form = this.buildFormGroup(app, app.setupData.formFields);
      } else {
        app.setupData?.form?.reset();
      }
    }
  }

  updateAppProfile(app: App, data: any = {}): Observable<AppProfile> {
    return this.http.patch(`${getApiUrl()}apps/${app.id}/profile`, this.prepareFormData(app, data))
      .pipe(map((res: any) => this.buildAppProfile(res, app)));
  }

  buildTranslation(translationKey: string, translationConfig: AppTranslationConfig) {
    const appType = this.translate.instant(`APP.${translationConfig.app?.type?.toUpperCase()}`).toLowerCase();
    const appKey = translationConfig.useAppKey && translationConfig.app ? `${translationConfig.app.key?.toUpperCase()}.` : '';
    const status = translationConfig.useStatus ? `STATUS.${translationConfig.app?.status?.toUpperCase()}.` : '';
    const finalTranslateKey = `APP.${appKey}${status}${translationKey}`;
    const translationWithAppKey = this.translate.instant(finalTranslateKey, { value: translationConfig.app?.name, appType });
    return translationWithAppKey === finalTranslateKey ? this.translate.instant(`APP.${status}${translationKey}`, { value: translationConfig.app?.name, appType }) : translationWithAppKey;
  }

  getHelpTextPerField(fieldName: string): string {
    let translationKey = fieldName.startsWith('APP.FIELDS') ? fieldName.split('.').pop() : fieldName;
    translationKey = `APP.FIELDS_DESCRIPTION.${translationKey === fieldName ? convertCamelCaseToSnakeCase(translationKey, CaseType.UpperCase) : translationKey}`;
    const translation = this.translate.instant(translationKey);
    return translation === translationKey ? '' : translation;
  }

  private buildAppProfile(res: any, app: App): AppProfile {
    const appData: RawApp = res.data;
    app.buildFormFields(appData.formFields);
    const formFieldMapper = (field: RawFormField | AppFormField) => {
      let value = field.type === AppFieldType.Boolean ? (field.value ? 'ON' : 'OFF') : (field.value || '');
      if (field.type === AppFieldType.Select && field.options) {
        value = field.options.find(option => option.value === field.value)?.label || '';
      }
      if (field.type === AppFieldType.Array && Array.isArray(field.value)) {
        value = (value as unknown as any[]).map(v => v[field.fields[0].name]).join(', ');
      }
      return {
        name: `APP.${app.key.toUpperCase()}.FIELDS.${convertCamelCaseToSnakeCase(field.name, CaseType.UpperCase)}`,
        value,
        behaviour: field.behaviour || FieldBehaviour.Plain,
        editability: field.editability || AppFieldStage.Never,
      };
    };
    const appProfile = { fields: appData.formFields.filter(field => field.behaviour !== FieldBehaviour.Hidden).map(formFieldMapper) } as AppProfile;
    return appProfile;
  }

  private createForm(app: App, formFieldProperty = 'formFields', formProperty = 'form') {
    const appFormFields = app.setupData?.[`${formFieldProperty}`];
    if (appFormFields?.length > 0) {
      app.setupData[`${formFieldProperty}`] = sortArrayByNumericAttribute(appFormFields, 'position');
      app.setupData[`${formProperty}`] = this.buildFormGroup(app, appFormFields);
      this.setRequiredFields(appFormFields, app.setupData[`${formProperty}`]);
    }
  }

  private buildFormGroup(app: App, formFields: AppFormField[]): UntypedFormGroup {
    const formFieldsObject = {};
    formFields.forEach((field) => {
      if (field.editability !== AppFieldStage.Never) {
        if (field.fields?.length > 0) {
          formFieldsObject[`${field.name}`] = this.formBuilder.array([]);
          const arrayField = field.fields[0];
          if (field.value && Array.isArray(field.value)) {
            field.value.forEach(() => {
              this.addFormArrayField(formFieldsObject[`${field.name}`], arrayField.name, arrayField.isRequired);
            });
          } else {
            this.addFormArrayField(formFieldsObject[`${field.name}`], arrayField.name, arrayField.isRequired);
          }
        } else {
          formFieldsObject[`${field.name}`] = this.addFormControlForField(field, app);
        }
      }
    });
    return this.formBuilder.group(formFieldsObject);
  }

  private setRequiredFields(formFields: AppFormField[], form: UntypedFormGroup) {
    const requiredControlKeys = formFields.filter((field) => field.isRequired).map((field) => field.name);
    requiredControlKeys.forEach((controlKey: string) => setRequired(form.get(controlKey)));
    const requiredTrueControlKeys = formFields.filter((field) => field.isRequired && field.type === AppFieldType.Boolean && field.validation === AppFormFieldValidation.RequiredBoolean).map((field) => field.name);
    requiredTrueControlKeys.forEach((controlKey: string) => {
      form.get(controlKey).setValidators([Validators.requiredTrue]);
    });
  }

  private prepareFormData(app: App, rawFormValue: any): any {
    const formData: FormData | {} = {};

    Object.keys(rawFormValue).forEach(field => {
      const fieldValue = rawFormValue[field];
      if (fieldValue && Array.isArray(fieldValue)) {
        const fieldName = app?.setupData?.formFields?.find(f => f.name === field)?.fields?.[0].name;
        formData[field] = fieldValue.map(value => {
          return { [`${fieldName}`]: value };
        });
      } else {
        formData[field] = fieldValue;
      }
    });
    return this.buildFormData(app, formData);
  }

  private buildFormData(app: App, data: any): FormData {
    if (app.hasAtLeastOneFileAppFieldType) {
      const formData = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          formData.append(convertCamelCaseToSnakeCase(key, CaseType.LowerCase), value as string);
        }
      }
      return formData;
    } else {
      return data;
    }
  }

  private appsFactory(rawApps: RawApp[]): App[] {
    const allApps: App[] = rawApps?.map((rawApp: RawApp) => {
      const app = new DefaultApp(rawApp.referenceId as AppKey, rawApp.name, rawApp.id) as App;
      app.buildApp(rawApp);
      this.createForm(app);
      return app;
    });
    allApps.forEach((app: App) => app.setStatus(allApps));
    return allApps;
  }

  private addFormControlForField(field: AppFormField, app: App): UntypedFormControl {
    const fieldControl: UntypedFormControl = this.formBuilder.control({ value: app.getFieldValue(field), disabled: false });
    fieldControl['fieldName'] = field.name;
    if (field.isRequired) {
      setRequired(fieldControl);
    }
    return fieldControl;
  }

}
