import { AfterViewInit, Component, Input } from '@angular/core';
import {
  App,
  AppFieldDisplayType,
  AppFieldStage,
  AppFieldType,
  AppFormField,
  AppFormFieldAction,
  AppModal,
  AppProfile,
  AppSetup,
  AppStatus
} from './app';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AppsService } from '../apps.service';
import { TranslateService } from '@ngx-translate/core';
import { DateAgoPipe } from '../../shared/pipes/date-ago.pipe';
import { Router } from '@angular/router';
import { DataStorage } from '../../core/data/data-storage';
import { markAllModified } from '../../core/helpers/form-utils';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Breakpoint, CaseType, convertCamelCaseToSnakeCase, fromMediaQuery } from 'src/app/core/helpers/utils';
import { UntypedFormArray } from '@angular/forms';
import { FileFieldData } from './file-field/file-field.component';
import { BusinessService } from '../../core/business/business.service';
import { FieldBehaviour } from '../../shared/display-field/display-field.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'rosie-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  @Input() app: App;
  @Input() showDetails = true;

  AppFieldType = AppFieldType;
  AppFormFieldActionEnum = AppFormFieldAction;
  AppModalEnum = AppModal;
  AppFieldDisplayType = AppFieldDisplayType;

  settingsModalClosedSubject: Subject<void> = new Subject<void>();

  constructor(public modal: NgxSmartModalService, public apps: AppsService, public business: BusinessService, private translate: TranslateService, private date: DateAgoPipe, private router: Router) {
  }

  get appCategory(): string {
    return `APP.CATEGORY.${this.app?.type?.toUpperCase()}`;
  }

  get actionIcon(): string {
    return this.app?.isInstalled || this.app?.isPending ? 'settings' : 'install';
  }

  get iconUrl(): string {
    return `assets/images/${this.actionIcon}.svg`;
   }

  get actionTranslationKey(): string {
    return this.app?.isNotInstalled ? 'APP.INSTALL_DEFAULT' : 'APP.SETTINGS';
  }

  get lastUpdated(): string {
    return this.date.transform(this.app?.lastUpdated);
  }

  get appDescriptionKey(): string {
    return `APP.${this.app?.key?.toUpperCase()}.DESCRIPTION`;
  }

  get settingUrl(): string {
    return `${window.location.origin}/settings;section=capabilities`;
  }

  ngAfterViewInit() {
    this.handleAppStatus();
  }

  action() {
    if (this.app?.hasFormFlow) {
      this.resetForm();
    }
    if (this.app?.isNotInstalled) {
      this.modal.open(AppModal.Install, true);
    } else {
      this.modal.open(AppModal.Settings);
    }
  }

  verify() {
    if (!this.app.inProgress) {
      this.app.inProgress = true;
      this.apps.verify(this.app.id).subscribe((appStatus) => {
        this.app.status = appStatus;
        this.app.inProgress = false;
        this.handleAppStatus();
        if (this.app.isInstalled) {
          this.modal.closeAll();
        }
      }, () => this.app.inProgress = false);
    }
  }

  getFieldType(formFields: AppFormField[], fieldName: string): string {
    return formFields?.find(field => field.name === fieldName)?.type;
  }

  getFieldArrayType(formFields: AppFormField[], fieldName: string): string {
    return formFields?.find(field => field.name === fieldName)?.fields?.[0]?.type;
  }

  getFieldTranslation(fieldName: string): string {
    return this.translate.instant(`APP.${this.app.key?.toUpperCase()}.FIELDS.${convertCamelCaseToSnakeCase(fieldName, CaseType.UpperCase)}`);
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  getHelpTextPerField(fieldName: string): string {
    let translationKey = fieldName.startsWith('APP.FIELDS') ? fieldName.split('.').pop() : fieldName;
    translationKey = `APP.FIELDS_DESCRIPTION.${translationKey === fieldName ? convertCamelCaseToSnakeCase(translationKey, CaseType.UpperCase) : translationKey}`;
    const translation = this.translate.instant(translationKey);
    return translation === translationKey ? '' : translation;
  }

  getFieldValidationErrorTranslation(fieldName: string): string {
    const translationKey = `APP.${this.app.key.toUpperCase()}.VALIDATION.${convertCamelCaseToSnakeCase(fieldName, CaseType.UpperCase)}`;
    const translation = this.translate.instant(translationKey);
    return translation === translationKey ? 'COMMON.VALIDATION.REQUIRED' : translationKey;
  }

  handleAppStatus(hasActionFailed = false, isUpdate = false) {
    if (this.app && !this.app.isIntegrating) {
      if (DataStorage.persistent().hasKey(`installInProgress-${this.getAppIdentificator(this.app)}`)) {
        if (this.app.isNotInstalled || hasActionFailed) {
          this.app.error = true;
          if (!isUpdate) {
            this.modal.open(AppModal.Install);
          }
        } else if (this.app.isInstalled || this.app.isPending) {
          this.app.error = false;
          this.modal.closeAll();
          this.modal.open(AppModal.Status);
        }
        if (!isUpdate && !this.app.error) {
          this.apps.refreshApp(this.app);
        }
        DataStorage.persistent().remove(`installInProgress-${this.getAppIdentificator(this.app)}`);
      }
    }
  }

  close(): void {
    this.modal.closeAll();
  }

  install(isUpdate = false) {
    if (this.app?.hasFormFields) {
      markAllModified(this.app.setupData.form);
    }
    if (this.app?.hasCredentialsFlow) {
      this.apps.install(this.app).subscribe((formFields) => {
        this.app.setupData.formFields.push({ name: 'Api Key', value: formFields.apiKey || '', behaviour: FieldBehaviour.Plain, type: AppFieldType.Text, actions: [AppFormFieldAction.Copy], visibility: AppFieldStage.Always });
        DataStorage.persistent().set(`installInProgress-${this.getAppIdentificator(this.app)}`, 'true');
      })
    } else if (this.app?.hasFormFlow || (isUpdate && this.app?.hasAnyEditableFieldsOnceInstalled)) {
      this.handleFormActionInstall(isUpdate);
    } else if (this.app?.hasExternalFlow) {
      this.externalFlowActionInstall();
    }
  }

  closeInstallModal() {
    if (this.app?.hasCredentialsFlow) {
      this.verify();
    }
  }

  update() {
    if (this.app.hasAnyEditableFieldsOnceInstalled) {
      this.modal.open(AppModal.Update);
    } else if (this.app.hasExternalFlow) {
      this.externalFlowActionInstall();
    } else {
      this.resetForm();
      markAllModified(this.app.setupData.form, true);
      this.modal.open(AppModal.Update);
    }
  }

  uninstall() {
    if (!this.app.inProgress) {
      this.app.inProgress = true;
      this.apps.uninstall(this.app).subscribe(() => {
        this.app.uninstall();
        this.modal.closeAll();
        this.app.inProgress = false;
        this.apps.refreshApp(this.app);
      });
    }
  }

  addFormField(fieldName: string) {
    const formArray = this.app?.setupData?.form?.get(fieldName) as UntypedFormArray;
    const arrayField = this.app?.setupData?.formFields?.find(f => f.name === fieldName)?.fields[0];
    this.apps.addFormArrayField(formArray, arrayField?.name, arrayField?.isRequired);
  }

  removeFormField(fieldName: string, index: number) {
    const formArray = this.app?.setupData?.form?.get(fieldName) as UntypedFormArray;
    this.apps.removeFormArrayField(formArray, index);
  }

  redirectToUrl(url: string) {
    window.location.href = url;
  }

  updateAppProfile(data) {
    this.apps.updateAppProfile(this.app, data).subscribe((appDetails: AppProfile) => {
      this.app.details.profile = appDetails;
      this.modal.closeLatestModal();
    });
  }

  handleSettingsModalClose() {
    this.settingsModalClosedSubject.next();
  }

  unsorted() {
  }

  handleFileUpload(event: { data: File, fieldName: string }) {
    this.app.setupData.form.get(event.fieldName).setValue(event.data);
  }

  isFieldEditable(fieldName: string): boolean {
    return this.app.isFieldEditable(fieldName);
  }

  getFileFieldData(fieldName: string): FileFieldData {
    return {
      label: this.getFieldTranslation(fieldName),
      value: this.app.setupData.form.get(fieldName).value,
      key: fieldName
    };
  }

  getFieldAdditionalInformation(fieldName: string): string {
    return this.app?.setupData?.formFields?.find(field => field.name === fieldName)?.additionalInformation;
  }

  getFieldDisplayType(fieldName: string): string {
    return this.app?.setupData?.formFields?.find(field => field.name === fieldName)?.displayType;
  }

  handleFieldSelectOptionChanged(fieldName: string, value: string | number) {
    if (this.app.hasExternalFlowWithFormFields) {
      DataStorage.persistent().set(`field-${this.getAppIdentificator(this.app)}-${fieldName}`, value);
    }
  }

  initCredentialsFlow() {
    if (this.app.hasCredentialsFlow) {
      this.install();
    }
  }

  getLogo(app: App): string {
    return `assets/images/${app.referenceId}.svg`;
  }

  private getAppIdentificator(app: App): string {
    return app.id;
  }

  private externalFlowActionInstall() {
    const isValidForm = this.app.hasFormFields ? this.app.setupData.form.valid : true;
    if (!this.app.inProgress && isValidForm) {
      this.app.inProgress = true;
      this.apps.getUrl(this.app).subscribe((setup: AppSetup) => {
        DataStorage.persistent().set(`installInProgress-${this.getAppIdentificator(this.app)}`, 'true');
        this.app.setupData.url = setup.url;
        this.redirectToUrl(this.app.setupData.url);
      }, () => {
        this.app.error = true;
        this.app.inProgress = false;
      });
    }
  }

  private handleFormActionInstall(isUpdate = false) {
    if (this.app.hasConditionalRequiredFields()) {
      this.app.setConditionalRequiredFields();
    }
    if (!this.app.inProgress && this.app.setupData.form.valid) {
      DataStorage.persistent().set(`installInProgress-${this.getAppIdentificator(this.app)}`, 'true');
      this.app.inProgress = true;
      this.apps.install(this.app, this.app.setupData.form.getRawValue())
        .pipe(catchError(() => {
          this.app.inProgress = false;
          return throwError('Something went wrong, please try again.');
        }))
        .subscribe(() => {
          this.app.inProgress = false;
          this.app.status = AppStatus.Installed;
          this.handleAppStatus();
        }, () => {
          this.handleAppStatus(true, isUpdate);
        });
    }
  }

  private resetForm() {
    this.apps.resetForm(this.app);
    const form = this.app.setupData.form;
    const formValues = this.getFormValues(this.app.setupData.formFields);
    form.patchValue(formValues);
  }

  private getFormValues(formFields: AppFormField[]): any {
    const formValues = {};
    formFields?.forEach(field => {
      if (field.type === AppFieldType.Array) {
        const fieldValue = field.value && Array.isArray(field.value) ? field.value : [];
        formValues[field.name] = fieldValue.map(value => {
          return value[field.fields[0].name];
        });
      } else {
        formValues[field.name] = this.app.getFieldValue(field);
      }
    });
    return formValues;
  }
}
