<div class="refer-friend-wrapper">
    <div class="refer-friend" (click)="referFriendModal.open()">
        <ng-icon [name]="'heroGift'" [size]="'20px'" [color]="'#9332E0'" [strokeWidth]="'2'"></ng-icon>
        <div class="refer-rosie-text">{{'REFER.REFER_ROSIE' | translate}}</div>
    </div>
</div>

<ngx-smart-modal #referFriendModal identifier="referFriendModal" [closable]="true" [customClass]="'refer-friend-modal'"
    (onOpenFinished)="handleReferFriendModalOpen()">
    <div class="modal-content refer-friend-modal-content">
        <div class="d-flex justify-content-center">
            <div class="rosie-logo-refer"></div>
        </div>
        <div class="refer-heading"> {{'REFER.HEADING' | translate}} </div>
        <div class="refer-subheading"> {{'REFER.SUBHEADING' | translate}} </div>
        <div class="refer-message-wrapper">
            <div class="d-flex align-items-center">
                <ng-icon class="refer-message-icon" [name]="'heroChatBubbleOvalLeftSolid'" [size]="'16px'"
                    [color]="'#822AC6'" [strokeWidth]="'1'"></ng-icon>
                <span class="refer-message-heading"> {{'REFER.MESSAGE_HEADING' | translate}} </span>
            </div>
            <div class="refer-message-content"> {{'REFER.MESSAGE_CONTENT' | translate}} </div>
            <div class="refer-message-url"> {{referrerUrl}} </div>
            <div class="modal-action-line">
                <rosie-button (click)="handleShare()" [customClass]="'narrow'" class=" w-100"
                    [customClass]="getCustomClass()" [translationKey]="getTranslationKey()"
                    [iconSuffixConfig]="getIconSuffixConfig()">
                </rosie-button>
            </div>
        </div>
    </div>
</ngx-smart-modal>