import { Injectable } from '@angular/core';

import { forkJoin, map, Observable } from 'rxjs';
import { SubscriptionService } from './subscription.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionResolver {
  constructor(private subscription: SubscriptionService) {
  }

  resolve(): Observable<any> {
    return forkJoin(this.subscription.getBilling(), this.subscription.getPlans()).pipe(map((reqs) => {
      return { billing: reqs[0], plans: reqs[1] };
    }));
  }
}
