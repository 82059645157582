import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {AppsService} from '../../../apps.service';
import {App, AppModal} from '../../app';

@Component({
  selector: 'rosie-app-update',
  templateUrl: './app-update.component.html',
})
export class AppUpdateComponent {

  @Input() app: App;
  @Input() form: TemplateRef<any>;

  @Output() install = new EventEmitter<void>();
  @Output() updateAppProfile = new EventEmitter<any>();

  constructor(private apps: AppsService, private modal: NgxSmartModalService) {
  }

  get updateModalActionConfig(): { translationKey: string, actionFunc: any } {
    if (this.app?.hasAnyEditableFieldsOnceInstalled && !this.app?.hasFormFlow) {
      return {translationKey: 'APP.UPDATE', actionFunc: () => this.updateAppProfile.emit(this.getUpdatedFormValues())};
    } else {
      return {translationKey: 'APP.UPDATE', actionFunc: () => this.install.emit()};
    }
  }

  get formOptions(): any {
    const formOptions = this.app?.setupData?.formOptions || {};
    this.app?.setupData?.formFields?.forEach((field) => {
      const formFieldOption = formOptions[field.name] || {};
      formOptions[field.name] = {...formFieldOption, hidden: !this.app.isFieldEditableOnceInstalled(field.name)};
    });
    return formOptions;
  }

  getUpdatedFormValues() {
    const formValues = {};
    Object.keys(this.formOptions).forEach((key) => {
      if (!this.formOptions[key].hidden) {
        formValues[key] = this.app?.setupData?.form?.value[key];
      }
    });
    return formValues;
  }

  getAppStatusTranslation(translationKey: string, showPrefix = false): string {
    return this.apps.buildTranslation(translationKey, {app: this.app, useStatus: true, showPrefix});
  }

  getTranslationWithAppName(translationKey: string, showPrefix = false): string {
    return this.apps.buildTranslation(translationKey, {app: this.app, showPrefix});
  }

  close(): void {
    this.modal.getModal(AppModal.Update).close();
  }

}
