<div class="credit-card-wrapper" (click)="handleSubscription()">
    <div class="credit-card-heading">
        <div class="d-flex align-items-center">
            <ng-icon class="credit-card-icon" [name]="icon" [size]="'20px'" [color]="'#9F1239'"
                [strokeWidth]="'2'"></ng-icon>
            <div class="credit-card-heading-text">{{heading | translate}}</div>
        </div>
        <ng-icon [name]="'heroArrowUpRight'" [size]="'16px'" [color]="'#9F1239'" [strokeWidth]="'2'"></ng-icon>
    </div>
    <div class="credit-card-description">
        {{description | translate}}
    </div>
</div>