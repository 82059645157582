
import { Injectable } from '@angular/core';
import { Breakpoint, fromMediaQuery } from '../core/helpers/utils';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    isMobileVersion: boolean = false;
    activeSection: AccountSection;

    constructor() {
        fromMediaQuery(Breakpoint.UpToMedium).subscribe(matches => this.isMobileVersion = matches);
    }
}

export enum AccountAction {
    ClearRecordings = 'ClearRecordings',
    AddAPhoneNumber = 'AddAPhoneNumber',
    AddAnEmail = 'AddAnEmail',
    None = 'None'
}

export enum AccountSection {
    Profile = 'profile',
    Notifications = 'notifications',
    Billing = 'billing',
}