<div class="section-wrapper">
  <div class="section-header">
    <ng-icon class="section-icon" [name]="'heroCreditCard'" [size]="'24px'" [color]="'#822AC6'"
      [strokeWidth]="'2'"></ng-icon>
    <div class="section-heading">
      {{ 'ACCOUNT_SECTION.ACCOUNT.SUBSCRIPTION' | translate }}
    </div>
  </div>
  <div class="section-content-wrapper">
    <div class="section-content">
      <div>
        <div class="section-content-label">{{ planName }} Plan</div>
        <div class="plan-details-wrapper section-content-sublabel">
          <ng-container *ngIf="subscription.isTrialing && subscription.hasMinutesRemainingInTrial">
            <ng-container *ngIf="hasCreditCardOnFile">
              {{'SUBSCRIPTION.TRIAL_SUBSCRIPTION' | translate:{value: subscription.minutesRemainingInTrialFormatted,
              planName: subscription.subscriptionData?.planName, planCost: subscription.subscriptionData?.planCost}
              }}
            </ng-container>
            <ng-container *ngIf="!hasCreditCardOnFile">
              {{'SUBSCRIPTION.TRIAL_SUBSCRIPTION_NO_CREDIT_CARD' |
              translate:{value:subscription.minutesRemainingInTrialFormatted} }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!subscription.hasMinutesRemainingInTrial && !hasCreditCardOnFile">
            {{'SUBSCRIPTION.TRIAL_SUBCRIPTION_ENDED_NO_CREDIT_CARD' | translate}}
          </ng-container>
          <ng-container *ngIf="subscription.isActive">
            <ul class="pl-5">
              <ng-container *ngIf="subscription.subscriptionData?.minutesIncluded">
                <li>
                  Up to {{subscription.subscriptionData?.minutesIncluded}} minutes<br>
              </ng-container>
              <ng-container *ngIf="subscription.subscriptionData?.overageCost">
                <li>
                  Then {{subscription.subscriptionData?.overageCost | rosieCurrency}} per minute
                </li>
              </ng-container>
              <li *ngFor="let feature of getSelectedPlan()?.features">
                {{feature}}
              </li>
            </ul>
          </ng-container>
        </div>
        <ng-container *ngIf="subscription.isCanceled">
          <rosie-alert [type]="'error'" [message]="'SUBSCRIPTION.CANCELED_SUBSCRIPTION'"
            [customClass]="'mt-2'"></rosie-alert>
        </ng-container>
      </div>
      <ng-container *ngIf="hasCreditCardOnFile">
        <rosie-button [customClass]="'secondary text-sm'"
          [translationKey]="subscription?.isCanceled ? 'ACCOUNT_SECTION.ACCOUNT.RESUBSCRIBE' :'ACCOUNT_SECTION.ACCOUNT.CHANGE_PLAN'"
          (click)="subscribe()"></rosie-button>
      </ng-container>
    </div>
  </div>
  <div class="separator full my-0"></div>
  <div class="section-content-wrapper">
    <div class="section-content">
      <div class="d-flex align-items-center">
        <div class="section-content-description-label mr-sm">{{'ACCOUNT_SECTION.ACCOUNT.NEED_MORE_MINUTES' |
          translate}}
        </div>
        <rosie-button [customClass]="'secondary text-sm contact-us-intercom-trigger'"
          [translationKey]="'ACCOUNT_SECTION.ACCOUNT.CONTACT_US'"
          [iconSuffixConfig]="{name: 'heroPaperAirplane', color: '#6C6176', size: '20px', strokeWidth: '2px'}"></rosie-button>
      </div>
      <ng-container *ngIf="hasCreditCardOnFile && !subscription.isCanceled">
        <rosie-button [customClass]="'secondary text-sm'" [translationKey]="'ACCOUNT_SECTION.ACCOUNT.MANAGE_BILLING'"
          [iconPrefixConfig]=" {name: 'heroPencilSquare' , size: '20px' , strokeWidth: '2' , color: '#6C6176' }"
          (click)="redirectToBillingPortal()"></rosie-button>
      </ng-container>
      <ng-container *ngIf="!hasCreditCardOnFile">
        <rosie-button (click)="subscribe()" [translationKey]="'ACCOUNT_SECTION.ACCOUNT.SUBSCRIBE'"
          [customClass]="'text-sm slim narrow'"></rosie-button>
      </ng-container>
    </div>
  </div>
</div>