import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { getApiUrl, getHeaders } from '../helpers/rest-utils';
import { map } from 'rxjs/operators';
import { DataStorage } from '../data/data-storage';
import { PaginationInfo } from '../pagination';
import { Call } from './call';
import { UserService } from '../user/user.service';
import { AnalyticsEvent, InternalAnalyticsService } from '../internal-analytics.service';

@Injectable({
  providedIn: 'root'
})

export class CallsService {
  activeFilter = CallStatus.All;
  persistentStorage: DataStorage = DataStorage.persistent();
  list: BehaviorSubject<Call[]> = new BehaviorSubject([]);
  starredCalls: BehaviorSubject<Call[]> = new BehaviorSubject([]);
  sortedCallList: Call[] = [];

  isLoading = false;
  meta: PaginationInfo = null;

  constructor(public http: HttpClient, private user: UserService, private analytics: InternalAnalyticsService) {
  }

  get isStarredFilterActive(): boolean {
    return this.activeFilter === CallStatus.Starred;
  }

  get isArchivedFilterActive(): boolean {
    return this.activeFilter === CallStatus.Archived;
  }

  get isUnviewedFilterActive(): boolean {
    return this.activeFilter === CallStatus.Open;
  }

  hasMore(): boolean {
    return this.meta.currentPage < this.meta.lastPage;
  }

  loadMore(): Observable<any> {
    const pagination = this.meta.currentPage + 1;
    return this.getAll(pagination);
  }

  getAll(page = 1): Observable<Call[]> {
    this.isLoading = true;
    return this.http.get(`${getApiUrl()}calls?page=${page}&perPage=50${this.isStarredFilterActive ? '&flagged=1' : ''}${this.isArchivedFilterActive ? '&archived=1' : ''}${this.isUnviewedFilterActive ? '&viewed=0' : ''}`,
      { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((response: CallResponse) => {
        const newCalls = response.data;
        newCalls.forEach((newLead) => newLead.customer.phoneNumber = newLead.customer.phoneNumbers?.find((phoneNumber) => phoneNumber.isPrimary)?.number);
        let calls = [];
        if (page !== this.meta?.currentPage) {
          calls = [...this.list.getValue(), ...newCalls];
        } else {
          calls = newCalls;
        }
        this.resetCallList(calls);
        this.list.next(calls);
        const meta = response.meta;
        meta.filteredCallsCount = { open: meta.filteredCallsCount.notViewed, starred: meta.filteredCallsCount.flagged, archived: meta.filteredCallsCount.archived, all: meta.filteredCallsCount.all };
        this.setMeta(meta);
        this.isLoading = false;
        return calls;
      })) as Observable<Call[]>;
  }

  reset(): void {
    this.list.next([]);
  }

  view(id: string, viewed: boolean = true): Observable<any> {
    return this.http.put(`${getApiUrl()}calls/${id}`, { viewed }, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map(() => {
        const selectedCall = this.sortedCallList?.find(lead => lead?.id === id);
        if (selectedCall) {
          selectedCall.viewed = true;
        }
        this.resetCallList();
      }));
  }

  star(id: string, flagged: boolean = true): Observable<any> {
    return this.http.put(`${getApiUrl()}calls/${id}`, { flagged }, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
  }

  handleCallArchive(id: string,): Observable<any> {
    return this.isArchivedFilterActive ? this.restore(id) : this.archive(id);
  }

  archive(id: string,): Observable<any> {
    return this.http.delete(`${getApiUrl()}calls/${id}`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } });
  }

  restore(id: string): Observable<any> {
    return this.http.patch(`${getApiUrl()}calls/${id}/restore`, {}, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
  }

  setMeta(meta: PaginationInfo) {
    this.meta = meta;
  }

  clearRecordings(): Observable<any> {
    this.analytics.trackEvent(AnalyticsEvent.ClearRecordings);
    return this.http.delete(`${getApiUrl()}recordings`, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
  }

  private resetCallList(calls?: Call[]): void {
    const callList = calls || this.list.getValue();
    this.sortedCallList = callList?.sort((a, b) => {
      return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
    });
  }
}

export enum CallStatus {
  All = 'all',
  Open = 'open',
  Starred = 'starred',
  Archived = 'archived'
}

export interface CallResponse {
  data: Call[];
  meta: PaginationInfo;
}
