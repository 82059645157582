<div class="section-wrapper">
  <div class="section-header">
    <ng-icon class="section-icon" [name]="'heroClipboard'" [size]="'24px'" [color]="'#822AC6'"
      [strokeWidth]="'2'"></ng-icon>
    <div class="section-heading">{{ 'ACCOUNT_SECTION.ACCOUNT.EMAIL' | translate }}</div>
  </div>
  <div class="section-content-wrapper">
    <span class="section-content">
      <span class="section-content-label">{{ userData?.email}}</span>
      <rosie-button [customClass]="'secondary text-sm'" [translationKey]="'COMMON.LOGOUT'" class=""
        [iconPrefixConfig]="{name: 'heroArrowRightStartOnRectangle', color: '#6C6176', size: '20px', strokeWidth: '2px'}"
        (click)="logout()"></rosie-button>
    </span>
  </div>
</div>
<div class="section-wrapper">
  <div class="section-header">
    <ng-icon class="section-icon" [name]="'heroFolderOpen'" [size]="'24px'" [color]="'#822AC6'"
      [strokeWidth]="'2'"></ng-icon>
    <div class="section-heading">
      {{ 'ACCOUNT_SECTION.ACCOUNT.RECORDINGS' | translate }}
    </div>
  </div>
  <div class="section-content-wrapper">
    <div class="section-content">
      <div>
        <div class="section-content-label">{{'ACCOUNT_SECTION.ACCOUNT.CLEAR_RECORDINGS' | translate}}</div>
        <div class="section-content-sublabel">{{'ACCOUNT_SECTION.ACCOUNT.CLEAR_RECORDINGS_DESCRIPTION' | translate}}
        </div>
      </div>
      <rosie-button [customClass]="'secondary text-sm'"
        [translationKey]="'ACCOUNT_SECTION.ACCOUNT.CLEAR_RECORDINGS_ACTION'" class=""
        [iconPrefixConfig]="{name: 'heroTrash', color: '#6C6176', size: '20px', strokeWidth: '2px'}"
        (click)="clearRecordingModal.open()"></rosie-button>
    </div>
  </div>
</div>
<div class="section-wrapper">
  <div class="section-header">
    <ng-icon class="section-icon" [name]="'heroDevicePhoneMobile'" [size]="'24px'" [color]="'#822AC6'"
      [strokeWidth]="'2'"></ng-icon>
    <div class="section-heading">
      {{ 'ACCOUNT_SECTION.ACCOUNT.VERIFICATION_CODES' | translate }}
    </div>
  </div>
  <div class="section-content-wrapper">
    <div class="d-flex align-items-md-center justify-content-between gap-2 flex-wrap">
      <div>
        <div class="section-content-label">{{'ACCOUNT_SECTION.ACCOUNT.VERIFICATION_CODES_RECEIVE' | translate}}
        </div>
        <div class="section-content-sublabel">{{'ACCOUNT_SECTION.ACCOUNT.VERIFICATION_CODES_RECEIVE_DESCRIPTION' |
          translate}}
        </div>
      </div>
      <rosie-switch [control]="smsEnabledForm" class="notification-switch"></rosie-switch>
    </div>
  </div>
  <ng-container *ngIf="smsEnabledForm.value">
    <div class="separator full my-0"></div>
    <div class="section-content-wrapper">
      <div class="section-content">
        <div>
          <div class="section-content-description-label">{{'ACCOUNT_SECTION.ACCOUNT.VERIFICATION_CODES_HISTORY' |
            translate}}
          </div>
        </div>
        <rosie-button [customClass]="'secondary text-sm capitalize'"
          [translationKey]="'ACCOUNT_SECTION.ACCOUNT.VIEW_TEXTS'" (click)="smsModal.open()"></rosie-button>
      </div>
    </div>
  </ng-container>
</div>
<ngx-smart-modal #clearRecordingModal identifier="clearRecordingModal">
  <div class="modal-title with-subtitle">
    {{'ACCOUNT_SECTION.ACCOUNT.CLEAR_ALL_RECORDINGS' | translate}}
  </div>
  <div class="modal-subtitle with-border"></div>
  <div class="modal-content" [innerHTML]="'ACCOUNT_SECTION.ACCOUNT.CLEAR_RECORDINGS_DELETION' | translate">
  </div>
  <div class="modal-action-line with-border">
    <rosie-button (click)="clearRecordingModal.close()" [translationKey]="'COMMON.CANCEL'"
      [customClass]="'secondary d-block'">
    </rosie-button>
    <rosie-button (click)="clearRecordingData()" [customClass]="'danger d-block slim'"
      [inProgress]="isClearRecordingsInProgress" [translationKey]="'COMMON.DELETE'">
    </rosie-button>
  </div>
</ngx-smart-modal>
<ngx-smart-modal #smsModal identifier="smsModal" (onOpen)="fetchSmsList()">
  <div class="modal-title with-subtitle">
    {{'ACCOUNT_SECTION.ACCOUNT.VIEW_TEXTS' | translate}}
  </div>
  <div class="modal-subtitle with-border">
    {{'ACCOUNT_SECTION.ACCOUNT.VIEW_TEXTS_DESCRIPTION' | translate}}
    <span (click)="fetchSmsList()"
      class="text-underline pointer refresh-messages">{{'ACCOUNT_SECTION.ACCOUNT.REFRESH_MESSAGES' |
      translate}}</span>
  </div>
  <div class="modal-content">
    <div class="modal-description mb-0">
      <div *ngFor="let sms of smsList; let first = first;" class="message" [ngClass]="{'first': first}">
        <div class="text">{{sms}}</div>
      </div>
    </div>
  </div>
</ngx-smart-modal>