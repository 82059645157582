<button [class]="customClass ? customClass : 'btn-primary'" [dataHook]="translationKey" [disabled]="isDisabled"
  [ngClass]="{'w-100': fullWidth, 'disabled': isDisabled, 'in-progress': inProgress}" [type]="customType" class="btn">
  <div class="d-flex align-items-center flex-nowrap justify-content-center">
    <ng-container>
      <ng-content></ng-content>
      <circle-progress *ngIf="inProgress && !iconSuffixConfig?.name" class="spinner centered" [percent]="20"
        [maxPercent]="100" [radius]="9" [innerStrokeWidth]="3" [space]="'-3'" [outerStrokeColor]="spinnerColor?.outer"
        [innerStrokeColor]="spinnerColor?.inner" [outerStrokeWidth]="3"></circle-progress>
      <ng-icon *ngIf="!inProgress && iconPrefixConfig?.name" [name]="iconPrefixConfig?.name"
        [size]="iconPrefixConfig?.size" [strokeWidth]="iconPrefixConfig?.strokeWidth" class="icon-prefix"
        [color]="isDisabled ? iconPrefixConfig?.disabledStateColor : iconPrefixConfig.color"></ng-icon>
      <span [ngClass]="{'rosie-invisible': inProgress && !iconSuffixConfig?.name}">{{ translationKey | translate
        }}</span>
      <div class="icon-loader-wrapper" *ngIf="iconSuffixConfig?.name">
        <ng-icon *ngIf="!inProgress && iconSuffixConfig?.name" [name]="iconSuffixConfig?.name"
          [size]="iconSuffixConfig?.size" [strokeWidth]="iconSuffixConfig?.strokeWidth"
          [color]="isDisabled ? iconSuffixConfig?.disabledStateColor : iconSuffixConfig.color"></ng-icon>
        <circle-progress *ngIf="inProgress" class="spinner d-block" [percent]="20" [maxPercent]="100" [radius]="9"
          [innerStrokeWidth]="3" [space]="'-3'" [outerStrokeColor]="spinnerColor?.outer"
          [innerStrokeColor]="spinnerColor?.inner" [outerStrokeWidth]="3"></circle-progress>
      </div>
    </ng-container>
  </div>
</button>