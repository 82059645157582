import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AnalyticsEvent, InternalAnalyticsService } from '../../core/internal-analytics.service';
import { CallFinishedNotificationChannel } from '../../core/agent/agent';
import { debounceTime, forkJoin } from 'rxjs';
import { AccountAction } from '../account.service';
import { User, UserService } from '../../core/user/user.service';
import { PageService } from '../../core/page.service';
import { BusinessService } from '../../core/business/business.service';
import { AgentService } from '../../core/agent/agent.service';
@Component({
  selector: 'rosie-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  CallFinishedNotificationChannel = CallFinishedNotificationChannel;
  inProgress: AccountAction = AccountAction.None;
  errorMessage = '';
  selectedNotificationItem: { type: CallFinishedNotificationChannel, index: number, control: FormControl } = { type: null, index: null, control: new FormControl('') };
  temporaryPhoneNumber: FormControl = new FormControl('');
  temporaryEmail: FormControl = new FormControl('', [Validators.email]);
  callNotifications: FormGroup;
  @ViewChild('editNotificationModal') editNotificationModal: NgxSmartModalComponent;

  constructor(private user: UserService, private business: BusinessService, private agent: AgentService, private analytics: InternalAnalyticsService, private page: PageService, private fb: FormBuilder) {
    this.setBrowserTabTitle();
  }

  get userData(): User {
    return this.user.userData;
  }

  get phoneNumbersControl() {
    return this.callNotifications.get('contactPhoneNumber') as FormArray;
  }

  get emailControl() {
    return this.callNotifications.get('contactEmail') as FormArray;
  }

  get isPhoneNumberAddInProgress(): boolean {
    return this.inProgress === AccountAction.AddAPhoneNumber;
  }

  get isEmailAddInProgress(): boolean {
    return this.inProgress === AccountAction.AddAnEmail;
  }

  get activeFormArray(): FormArray {
    return this.callNotifications.get(this.selectedNotificationItem.type === this.CallFinishedNotificationChannel.Email ? 'contactEmail' : 'contactPhoneNumber') as FormArray
  }

  ngOnInit(): void {
    this.createNotificationForm();
  }

  addPhoneNumber() {
    this.inProgress = AccountAction.AddAPhoneNumber;
    if (this.temporaryPhoneNumber.value && this.temporaryPhoneNumber.valid) {
      this.analytics.trackEvent(AnalyticsEvent.NotificationPhoneNumberAdded);
      const phone = this.temporaryPhoneNumber.value;
      this.phoneNumbersControl.push(this.fb.control(phone, Validators.required));
      this.temporaryPhoneNumber.patchValue('')
      this.temporaryPhoneNumber.markAsUntouched();
      this.inProgress = AccountAction.None;
    }
  }

  removePhoneNumber(index: number) {
    this.analytics.trackEvent(AnalyticsEvent.NotificationPhoneNumberRemoved);
    this.phoneNumbersControl.removeAt(index);
  }

  addEmail() {
    this.inProgress = AccountAction.AddAnEmail;
    if (this.temporaryEmail.value && this.temporaryEmail.valid) {
      this.analytics.trackEvent(AnalyticsEvent.NotificationEmailAdded);
      const email = this.temporaryEmail.value;
      this.emailControl.push(this.fb.control(email, Validators.required));
      this.temporaryEmail.patchValue('');
      this.temporaryEmail.markAsUntouched();
      this.inProgress = AccountAction.None;
    }
  }

  removeEmail(index: number) {
    this.analytics.trackEvent(AnalyticsEvent.NotificationEmailRemoved);
    this.emailControl.removeAt(index);
  }

  handleNotificationChangeSave() {
    if (this.selectedNotificationItem.control.invalid) {
      this.selectedNotificationItem.control.markAsTouched();
      return;
    }
    this.activeFormArray.at(this.selectedNotificationItem.index).patchValue(this.selectedNotificationItem.control.value);
    this.editNotificationModal.close();
  }

  editNotification(index: number, type) {
    this.selectedNotificationItem.type = type;
    if (this.selectedNotificationItem.type === CallFinishedNotificationChannel.Email) {
      this.selectedNotificationItem.control.addValidators(Validators.email);
    } else {
      this.selectedNotificationItem.control.removeValidators(Validators.email);
    }
    this.selectedNotificationItem.index = index;
    const value = this.activeFormArray.at(index)?.value;
    this.selectedNotificationItem.control.patchValue(value);
    this.editNotificationModal.open();
  }

  private setBrowserTabTitle(): void {
    this.page.initializePage(`Rosie - Account - Notifications`);
  }

  private createNotificationForm() {
    const phoneNumbers = this.agent.primary.business.information.additionalProperties.contactPhoneNumber || [];
    const emails = this.agent.primary.business.information.additionalProperties.contactEmail || [];

    this.callNotifications = this.fb.group({
      contactPhoneNumber: this.fb.array(phoneNumbers?.filter((number) => !!number).map(phone => this.fb.control(phone, Validators.required))),
      contactEmail: this.fb.array(emails.filter((email) => !!email).map(email => this.fb.control(email, Validators.required))),
      emailNotificationEnabled: this.agent.primary.configuration.callFinishedNotificationChannel?.includes(CallFinishedNotificationChannel.Email),
      smsNotificationEnabled: this.agent.primary.configuration.callFinishedNotificationChannel?.includes(CallFinishedNotificationChannel.SMS),
    });

    this.callNotifications.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
      forkJoin([this.updateNotificationsContacts(), this.updateNotificationsSettings()]).subscribe(() => {
        this.agent.getAgent().subscribe();
      });
    });
  }

  private updateNotificationsSettings() {
    const value = this.callNotifications.value;
    const callFinishedNotificationChannel = [];
    if (value.emailNotificationEnabled) { callFinishedNotificationChannel.push(CallFinishedNotificationChannel.Email); }
    if (value.smsNotificationEnabled) { callFinishedNotificationChannel.push(CallFinishedNotificationChannel.SMS); }
    return this.agent.updateAgentConfiguration({ configuration: { callFinishedNotificationChannel } }, true);
  }

  private updateNotificationsContacts() {
    const value = this.callNotifications.value;
    const businessInformationData: any = {
      additionalProperties: {
        contactEmail: value.contactEmail,
        contactPhoneNumber: value.contactPhoneNumber
      }
    };
    return this.business.updateInformation(businessInformationData);
  }
}