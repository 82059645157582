import { Component } from '@angular/core';
import { DataStorage } from '../core/data/data-storage';
import { Router } from '@angular/router';

@Component({
  selector: 'rosie-dashboard',
  template: `
     <div class="d-flex rosie-dashboard-wrapper">
      <div>
        <rosie-nav-sidebar></rosie-nav-sidebar>
      </div>
      <div class="content-section" [ngClass]="{'has-scrollable-content':  hasScrollableContent}">
        <router-outlet></router-outlet>
      </div>
    <div>
  `,
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  persistentStorage: DataStorage = DataStorage.persistent();

  constructor(private router: Router) {
  }

  get hasScrollableContent(): boolean {
    return this.router.url.includes('/setup');
  }
}
