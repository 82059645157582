import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileService } from './file.service';
import { LoaderConfig } from '../loader/loader.component';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DropdownConfig } from '../dropdown/dropdown.component';

@Component({
  selector: 'rosie-file-upload',
  template: `
    <div class="file-upload-wrapper" [ngClass]="config.customClass">
      <input
        type="file"
        class="file-input"
        (change)="onFileSelected($event)"
        [accept]="acceptFileTypes"
        #fileUpload
        [dataHook]="'FILE_UPLOAD.DRAG_DROP'"
      />
      <div class="icon-text-wrapper" *ngIf="isUploadInProgress == false">
        <ng-container *ngIf="config.ctaTranslationKey else defaultCta">
          {{ config.ctaTranslationKey | translate }}
        </ng-container>
        <ng-template #defaultCta>
          <img [src]="'assets/images/'+ctaIcon"/>
          {{ 'FILE_UPLOAD.DRAG_DROP' | translate }}&nbsp;
          <span>{{ 'FILE_UPLOAD.BROWSE' | translate }}</span>
        </ng-template>
      </div>
      <rosie-loader [config]="loaderConfig"></rosie-loader>
      <span *ngIf="hasTooltip" class="icon tooltip-icon position-absolute pointer" [ngbTooltip]="tooltip"
            tooltipClass="tooltip-wrapper status-tooltip"><img src="assets/images/question.svg"/></span>
    </div>
    <rosie-alert [message]="uploadError" [type]="'error'" [customClass]="'mt-2 upload-error'"
                 *ngIf="uploadError && !isUploadInProgress && !hideUploadError"></rosie-alert>
  `,
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit {
  uploadError = '';

  @Input() filePurpose: FilePurpose = FilePurpose.File;
  @Input() acceptFileTypes = 'image/png, image/jpeg, image/jpg, application/pdf';
  /* Maximum allowed file size in Kb */
  @Input() maxSize = 1024;
  @Input() config: FileUploadConfig = { customClass: '', loaderText: '', ctaTranslationKey: '', ctaIcon: 'image-placeholder.svg' };
  @Input() additionalData: FileUploadData = { origin: FileUploadSource.Media, data: {} };
  @Input() hideUploadError = false;
  @Input() tooltip = '';

  @Output() fileData = new EventEmitter<FileData | File>();
  @Output() uploadStatusChanged = new EventEmitter<FileUploadStatus>();
  @Output() uploadErrorMsg = new EventEmitter<string>();

  @ViewChild('fileUpload', { read: ElementRef }) fileUpload: ElementRef;

  private uploadStatus = new BehaviorSubject<FileUploadStatus>(FileUploadStatus.None);
  private imageSrc = '';

  constructor(private fileService: FileService, private translate: TranslateService) {
  }

  get loaderConfig(): LoaderConfig {
    return {
      show: this.isUploadInProgress,
      size: 'lg',
      full: false,
      icon: false,
      customLoaderWrapperClass: 'rosie-dark-loader inline-loader',
      loaderText: this.config.loaderText
    };
  }

  get isUploadInProgress(): boolean {
    return this.uploadStatus.getValue() === FileUploadStatus.UploadInProgress;
  }

  get isUploadError(): boolean {
    return this.uploadStatus.getValue() === FileUploadStatus.Error;
  }

  get ctaIcon(): string {
    return this.config?.ctaIcon || 'image-placeholder.svg';
  }

  get hasTooltip(): boolean {
    return !!this.tooltip;
  }

  ngOnInit(): void {
    this.uploadStatus.subscribe((fileUploadStatus: FileUploadStatus) => {
      if (fileUploadStatus !== FileUploadStatus.None) {
        this.uploadStatusChanged.emit(this.uploadStatus.getValue());
      }
    });
  }

  onFileSelected(event): void {
    this.uploadError = '';
    const reader = new FileReader();
    const file: File = event.dataTransfer
      ? event.dataTransfer.files[0]
      : event.target.files[0];
    if (file && !this.validateFile(file)) {
      return;
    }

    if (file) {
      this.uploadStatus.next(FileUploadStatus.UploadInProgress);

      reader.onload = (event: any) => {
        this.imageSrc = event.target.result;
      };

      reader.readAsDataURL(file);
      if (this.additionalData.origin === FileUploadSource.Self) {
        this.fileData.emit(file);
        this.uploadStatus.next(FileUploadStatus.Uploaded);
      } else {
        this.uploadFile(file);
      }
    }
  }

  openFileDialog(): void {
    this.fileUpload.nativeElement.click();
  }

  private validateFile(file: File): boolean {
    let isValid = true;
    if (this.maxSize && (file.size / 1024 > this.maxSize)) {
      isValid = false;
      this.uploadError = this.translate.instant('FILE_UPLOAD.EXCEEDED_SIZE_ERROR', { maxSize: this.maxSize / 1024 });
      this.uploadStatus.next(FileUploadStatus.Error);
      this.uploadErrorMsg.next(this.uploadError);
    }
    return isValid;
  }

  private uploadFile(file: File) {
    this.fileService
      .upload(file, this.additionalData)
      .subscribe((response: any) => {
        if (response.data.src) {
          this.imageSrc = response.data.src;
        }
        this.fileData.emit(response.data);
        this.uploadStatus.next(FileUploadStatus.Uploaded);
        this.fileUpload.nativeElement.value = '';
      }, (error) => {
        this.uploadError = error;
        this.uploadStatus.next(FileUploadStatus.Error);
        this.uploadErrorMsg.next(this.uploadError);
        this.fileUpload.nativeElement.value = '';
      });
  }
}

export enum FilePurpose {
  Image = 'image',
  Logo = 'logo',
  Favicon = 'favicon',
  File = 'file'
}

export enum FileUploadStatus {
  ReplaceInProgress = 'replaceInProgress',
  UploadInProgress = 'uploadInProgress',
  Uploaded = 'uploaded',
  Error = 'error',
  None = 'none'
}

export enum FileUploadSource {
  Media = 'media',
  OfferImage = 'admin/offers/images',
  Self = 'self'
}

export interface FileUploadData {
  origin: FileUploadSource,
  data: Record<string, string | FilePurpose>
}

export interface FileData {
  src: string;
  customProductMediaId?: string;
  productId?: string;
}

export interface FileUploadConfig {
  customClass: string;
  loaderText: string;
  ctaTranslationKey: string;
  ctaIcon?: string;
}

export interface FileConfig {
  filePurpose: FilePurpose;
  url: string;
  dropdownConfig: DropdownConfig;
  fileUploadConfig?: { style: { height: string } };
}
