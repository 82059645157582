import {ErrorHandler, Injectable} from '@angular/core';
import {createErrorHandler} from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {
  static isSentryEnabled: boolean;

  sentryError: any = createErrorHandler({showDialog: false});

  constructor() {
  }

  handleError(error: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else if (CustomErrorHandler.isSentryEnabled) {
      this.sentryError.handleError(error);
    } else {
      console.error(error);
    }
  }
}
