export class DataStorage implements AbstractStorage {

  private constructor(private storage: Storage | CookieStorage) {
  }

  static persistent(): DataStorage {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return new DataStorage(localStorage);
    } catch (e) {
      return new DataStorage(new CookieStorage());
    }
  }

  static session(): DataStorage {
    try {
      sessionStorage.setItem('test', 'test');
      sessionStorage.removeItem('test');
      return new DataStorage(sessionStorage);
    } catch (e) {
      return new DataStorage(new CookieStorage());
    }
  }

  public set(key: string, value: any): void {
    if (value === undefined) {
      this.storage.removeItem(key);
    } else {
      this.storage.setItem(key, encodeURIComponent(JSON.stringify(value)));
    }
  }

  public get(key: string): any {
    const item = this.storage.getItem(key);
    return item ? JSON.parse(decodeURIComponent(item)) : null;
  }

  public remove(key: string): void {
    if (this.hasKey(key)) {
      this.storage.removeItem(key);
    }
  }

  public removeAll(): void {
    this.storage.clear();
  }

  public hasKey(key: string): boolean {
    return this.storage.getItem(key) !== null && this.storage.getItem(key) !== undefined;
  }
}

export interface AbstractStorage {
  set(key: string, value: any): void;
  get(key: string): any;
  remove(key: string): void;
  removeAll(): void;
  hasKey(key: string): boolean;
}

export class CookieStorage {
  public setItem(key: string, value: string): void {
    document.cookie = `${key}=${value}; path=/; SameSite=Strict;`;
  }

  public getItem(key: string): string | null {
    const cookies = document.cookie.split('; ');
    const foundCookie = cookies.find(cookie => cookie.startsWith(`${key}=`));
    return foundCookie ? foundCookie.split('=')[1] : null;
  }

  public removeItem(key: string): void {
    document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict;`;
  }

  public clear(): void {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const key = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      this.removeItem(key);
    }
  }
}
