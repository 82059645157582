<div class="modal-title text-left">{{ getTranslationWithAppName(app ? 'INSTALL' : 'INSTALL_DEFAULT', true) }}</div>
<div class="modal-subtitle with-border"></div>
<div class="modal-content">
  <rosie-alert *ngIf="app?.hasError" [message]="getTranslationWithAppName('INSTALL_FAILED')" [type]="'error'"
    class="my-3">
  </rosie-alert>
  <div [innerHtml]="getAppSpecificTranslation(app ? 'INSTALL_DESCRIPTION' : 'INSTALL_DESCRIPTION_DEFAULT', !app)">
  </div>
  <ng-content></ng-content>
  <ng-container
    [ngTemplateOutletContext]="{ form: app?.setupData?.form, formFields: app?.setupData?.formFields, formOptions: formOptions }"
    [ngTemplateOutlet]="form">
  </ng-container>
</div>
<div *ngIf="!app?.hasCredentialsFlow" class="modal-action-line with-border">
  <rosie-button (click)="close()" class="d-block" [customClass]="'secondary narrow'" [translationKey]="'COMMON.CANCEL'">
  </rosie-button>
  <rosie-button (click)="install.emit()" class="d-block" [customClass]="'narrow slim'" [inProgress]="app?.inProgress"
    [translationKey]="app?.hasExternalFlow ? getTranslationWithAppName('CONNECT_WITH_ACTION') : 'APP.CONNECT_ACTION'">
  </rosie-button>
</div>