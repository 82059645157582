import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { EMPTY, throwError } from 'rxjs';
import { AuthenticationService } from './authentication-service';
import { catchError, map } from 'rxjs/operators';
import { Convert, convertTo } from '@rallycommerce/common/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user/user.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  refreshTokenInProgress = false;

  constructor(private auth: AuthenticationService, private router: Router, private route: ActivatedRoute) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    request = this.buildRequest(request);
    return this.buildNext(next, request);
  }

  private buildRequest(request: HttpRequest<any>) {
    const body = request.body instanceof FormData ? request.body : convertTo(request?.body, Convert.Underscore);
    const headers = {};

    if (this.getHeaderAuthenticationDetails(request)) {
      headers['Authorization'] = this.getHeaderAuthenticationDetails(request);
    }
    request = request.clone({
      setHeaders: headers,
      body: body || null
    });
    return request;
  }

  private buildNext(next: HttpHandler, request: HttpRequest<any>) {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const parsedResponse = { body: {} };
          const conditionalConvert = request.url.includes('assets') ? event.body : { data: convertTo(event.body) };
          parsedResponse.body = event.body?.data ?
            { data: convertTo(event.body?.data || event.body), meta: convertTo(event.body.meta || null) } :
            conditionalConvert;
          return event.clone(parsedResponse);
        } else {
          return event;
        }
      }),
      catchError((res: any, caught: any) => {
        if (res instanceof HttpErrorResponse) {
          return this.handleResponseError(res);
        }
      }));
  }

  private getHeaderAuthenticationDetails(request: HttpRequest<any>): string {
    const token = this.auth.getToken() || '';
    return this.auth.getToken() ? `Bearer ${token}` : '';
  }

  private handleResponseError(response: HttpErrorResponse): any {
    if (response.status === 401 || response.status === 403 || response.status === 423) {
      const isLoginPage = this.router.url.includes('login');
      if (!isLoginPage) {
        this.auth.clearAuthData();
      }
      return isLoginPage ? throwError(response?.error?.message || 'Something went wrong, please try again') : EMPTY;
    } else if (response.status === 404 && response.headers.get('Content-Type') !== 'application/json') {
      this.router.navigateByUrl('');
    } else if (response.status === 404) {
      this.router.navigateByUrl('404');
    }
    return throwError(response?.error?.message || 'Something went wrong, please try again');
  }
}
