<div class="subscription-wrapper">
  <div class="d-flex justify-content-center mb-4">
    <img src="assets/images/rosie-icon-logo.svg">
  </div>
  <div class="heading text-center mt-4 mb-1">
    {{(hasCreditCardOnFile ? 'SUBSCRIPTION.SELECT_PLAN' : 'SUBSCRIPTION.HEADING') | translate}} </div>
  <div class="sub-heading text-center">
    {{(hasCreditCardOnFile ? 'SUBSCRIPTION.SELECT_PLAN_DESCRIPTION' : 'SUBSCRIPTION.SUBHEADING') | translate}}
  </div>
  <div class="payment" [ngClass]="{'plan-change': hasCreditCardOnFile}">
    <div class="plan-selection"
      [ngClass]="{'half-width': !hasCreditCardOnFile, 'flex-fill w-100': hasCreditCardOnFile}">
      <rosie-radio-group [control]="selectedPlanControl" [dataHook]="'SUBSCRIPTION.PLAN'" [options]="planOptions">
      </rosie-radio-group>
    </div>
    <div [hidden]="hasCreditCardOnFile && !isPaymentMethodInvalid" class="credit-card-wrapper"
      [ngClass]="{'w-100': !hasCreditCardOnFile && isPaymentMethodInvalid}">
      <div class="heading text-center"> {{'SUBSCRIPTION.PAYMENT_DETAILS' | translate }}</div>
      <div class="rosie-pymt-container" [hidden]="!isInitializingPaymentMethod">
        <div [ngClass]="{'shimmer': isInitializingPaymentMethod}" class="rosie-pymt-loader-container">
          <div *ngFor="let i of [].constructor(3)" [shimmer]="isInitializingPaymentMethod"
            class="rosie-pymt-loader-item">
          </div>
        </div>
      </div>
      <form class="rosie-credit-card">
        <div [hidden]="isInitializingPaymentMethod">
          <div class="form-group credit-card-number">
            <span #cardElement class="form-control rosie-form-control no-border" id="card-number"></span>
            <span [id]="getErrorElId('card-number')" class="rosie-error-message"></span>
            <label class="rosie-form-control-label" for="card-number">
              {{ 'SUBSCRIPTION.CARD_NUMBER_LABEL' | translate }}
            </label>
          </div>
          <div class="d-flex gap-2">
            <div class="form-group half-width">
              <span class="form-control rosie-form-control no-border" id="card-expiry"></span>
              <span [id]="getErrorElId('card-expiry')" class="rosie-error-message"></span>
              <label class="rosie-form-control-label" for="card-expiry">
                {{ 'SUBSCRIPTION.EXPIRY_DATE_LABEL' | translate }}
              </label>
            </div>
            <div class="form-group half-width">
              <span class="form-control rosie-form-control no-border" id="card-cvc"></span>
              <span [id]="getErrorElId('card-cvc')" class="rosie-error-message"></span>
              <label class="rosie-form-control-label" for="card-cvc">
                {{ 'SUBSCRIPTION.CVC_LABEL' | translate }}
              </label>
            </div>
          </div>
          <div class="form-group half-width mb-0">
            <input id="card-zip" class="form-control rla-form-control" [formControl]="cardholderForm.get('zip')">
            <rosie-validation-error
              [show]="cardholderForm.get('zip').errors?.required && cardholderForm.get('zip').touched"
              [translationKey]="'COMMON.VALIDATION.REQUIRED'" [translationAttributes]="{value: 'ZIP code' | translate}">
            </rosie-validation-error>
            <label class="rosie-form-control-label" for="card-cvc">
              {{ 'SUBSCRIPTION.ZIP' | translate }}
            </label>
          </div>
        </div>
        <rosie-alert [message]="paymentError" [type]="'error'" *ngIf="paymentError" class="mt-4 d-block"></rosie-alert>
      </form>
      <rosie-button (click)="subscribe()" [inProgress]="inProgress" [translationKey]="'SUBSCRIPTION.GET_STARTED'"
        class="d-block w-100 subscribe-credit-card-button">
      </rosie-button>
      <div class="coupon-code my-1" [ngClass]="{'invisible': !hasPromotionExpand}">
        <rosie-text [control]="cardholderForm.get('couponCode')" [labelTranslationKey]="'Promotion code'"></rosie-text>
      </div>
      <div class="custom-text text-center text-underline pointer" (click)="addPromotionCode()">
        {{'SUBSCRIPTION.HAVE_PROMOTION' |
        translate}} </div>
      <div class="custom-text mt-3">Need something more custom? <span
          class="text-underline pointer contact-us-intercom-trigger">Contact Us</span>
      </div>
    </div>
    <ng-container *ngIf="hasCreditCardOnFile && !isPaymentMethodInvalid">
      <div class="w-100">
        <rosie-button (click)="resubscribe()" [inProgress]="inProgress" [translationKey]="'SUBSCRIPTION.GET_STARTED'"
          class="w-100 mt-4">
        </rosie-button>
        <div class="custom-text mt-4 w-100">Need something more custom? <span
            class="text-underline pointer contact-us-intercom-trigger">Contact
            Us</span>
        </div>
      </div>
    </ng-container>
  </div>