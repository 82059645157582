import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export function markAllModified(control: AbstractControl, markOnlyPopulated = false) {
  if ((markOnlyPopulated && control.value) || !markOnlyPopulated) {
    control.markAsDirty({onlySelf: true});
    control.markAsTouched({onlySelf: true});
  }

  if (control instanceof UntypedFormGroup) {
    Object.keys(control.controls).forEach(key => markAllModified(control.controls[key], markOnlyPopulated));
  } else if (control instanceof UntypedFormArray) {
    control.controls.forEach(con => markAllModified(con, markOnlyPopulated));
  }
}

export function setRequired(control: AbstractControl) {
  if (control instanceof UntypedFormControl) {
    control.setValidators([Validators.required]);
  } else if (control instanceof UntypedFormGroup) {
    Object.keys(control.controls).forEach(key => setRequired(control.controls[key]));
  } else if (control instanceof UntypedFormArray) {
    control.controls.forEach(con => setRequired(con));
  }
}

export function isEmptyAndNotFocused(control: AbstractControl): boolean {
  const isEmptyAndNotTouched: boolean = control.pristine && control.untouched;
  return !control.value && (isEmptyAndNotTouched || control.touched);
}
