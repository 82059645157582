import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { businessDays, timeZones } from './configuration';
import { BusinessPhoneNumber, BusinessPhoneNumberType, Service, Address, Area, Business, AdditionalProperties } from '../core/business/business';
import { areEqual } from '@rallycommerce/common/utils';
import { Agent, CustomQuestion, TrainingFaq } from '../core/agent/agent';
import { PhoneNumber } from '../core/phone/phone';
import { removeCountryCode } from '../core/helpers/utils';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationHelperService {
    constructor(private fb: FormBuilder) { }

    createService(service: Service = { name: '', isActive: true }): FormGroup {
        return this.fb.group({
            name: [service.name, Validators.required],
            id: [service.id],
            isActive: [service.isActive === undefined ? true : service.isActive]
        });
    }

    createArea(area: Area = { name: '', isActive: true }): FormGroup {
        return this.fb.group({
            name: [area?.name],
            isActive: [area.isActive === undefined ? true : area.isActive]
        });
    }

    createAddress(address: Address = { street: '', city: '', state: '', zipCode: '' }): FormGroup {
        return this.fb.group({
            street: [address?.street],
            city: [address?.city],
            state: [address?.state],
            zipCode: [address?.zipCode],
            id: [address?.id]
        });
    }

    createAddresses(defaultAddresses: Address[]): FormArray {
        defaultAddresses = defaultAddresses.filter(address => address?.city);
        return this.fb.array(
            defaultAddresses.length > 0 ?
                defaultAddresses.map(address => this.createAddress(address)) :
                [this.createAddress()]
        );
    }

    createBusinessHours(defaultHours: any[] = []): FormArray {
        return this.fb.array(
            businessDays().map(day => {
                const defaultHour = defaultHours.find(h => h.day === day.day) || { open: '', close: '' };
                return this.createBusinessHour(day, defaultHour);
            })
        );
    }

    createBusinessHour(day, defaultHour = { open: '', close: '' }): FormGroup {
        return this.fb.group({
            day: day.day,
            open: new FormControl(this.convert24To12HourFormat(defaultHour.open)),
            close: new FormControl(this.convert24To12HourFormat(defaultHour.close))
        });
    }

    createFaqs(faqs: TrainingFaq[]): FormArray {
        return this.fb.array(faqs.length > 0 ? faqs.map(faq => this.createFaq(faq)) : []);
    }

    createBlockedPhoneNumbers(phoneNumbers: PhoneNumber[]): FormArray {
        return this.fb.array(phoneNumbers.length > 0 ? phoneNumbers.map(phoneNumber => this.createBlockedPhoneNumber(phoneNumber)) : []);
    }

    createBlockedPhoneNumber(phoneNumber: PhoneNumber = { number: '' }): FormGroup {
        return this.fb.group({
            number: [removeCountryCode(phoneNumber.number), [Validators.required]],
            countryPrefix: '1',
            id: phoneNumber.id
        });
    }

    createLanguages(languages: string[] = [], supportedLanguages: { value: string, label: string }[]): FormArray {
        return this.fb.array(supportedLanguages.map(language => this.createLanguage(language, languages.includes(language.value) || language.value === 'en')));
    }

    createLanguage(language: { value: string, label: string }, isEnabled: boolean): FormGroup {
        return this.fb.group({ value: language.value, label: language.label, isEnabled: isEnabled });
    }

    createCustomQuestions(customQuestions: CustomQuestion[], isRequired: boolean = true): FormArray {
        return this.fb.array(customQuestions.length > 0 ? customQuestions.map(customQuestion => this.createCustomQuestion(customQuestion, isRequired)) : []);
    }

    createCustomQuestion(customQuestion: CustomQuestion = { question: '' }, isRequired: boolean = true): FormGroup {
        const validators = [Validators.maxLength(150)];
        if (isRequired) validators.push(Validators.required);
        return this.fb.group({
            question: [customQuestion.question, validators],
        });
    }

    createFaq(faq: TrainingFaq = { answer: '', question: '' }): FormGroup {
        return this.fb.group({
            question: [faq.question, Validators.required],
            answer: [faq.answer, [Validators.required, Validators.maxLength(500)]],
            id: [faq.id]
        });
    }

    convert24To12HourFormat(time: string): string {
        if (!time) return '';
        if (time === 'Closed') return time;
        if (time.toLowerCase().includes('pm') || time.toLowerCase().includes('am')) return time.toLowerCase();
        const [hour, minute] = time.split(':').map(Number);
        const period = hour < 12 ? ' AM' : ' PM';
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        return `${formattedHour}:${minute < 10 ? '0' + minute : minute}${period?.toLowerCase()}`;
    }

    convert12To24HourFormat(time: string): string {
        if (!time) return 'Closed';
        if (time === 'Closed') return time;
        const [timePart, period] = time.split(/(am|pm)/i).filter(Boolean);
        const [hour, minute] = timePart.split(':').map(Number);
        let formattedHour = period.toLowerCase() === 'am' ? hour : hour + 12;
        if (formattedHour === 24) formattedHour = 12;
        if (formattedHour === 12 && period.toLowerCase() === 'am') formattedHour = 0;
        return `${formattedHour < 10 ? '0' + formattedHour : formattedHour}:${minute < 10 ? '0' + minute : minute}`;
    }

    createPhoneNumbers(defaultPhoneNumbers: BusinessPhoneNumber[]): FormArray {
        const primaryPhoneNumber = defaultPhoneNumbers.find(p => p.transferDepartmentName === BusinessPhoneNumberType.Main);
        const emergencyPhoneNumber = defaultPhoneNumbers.find(p => p.transferDepartmentName === BusinessPhoneNumberType.Emergency);
        const customerSupportPhoneNumber = defaultPhoneNumbers.find(p => p.transferDepartmentName === BusinessPhoneNumberType.CustomerSupport);
        const phoneNumbers = [
            { isActive: !!primaryPhoneNumber, number: removeCountryCode(primaryPhoneNumber?.number), transferDepartmentName: BusinessPhoneNumberType.Main, id: primaryPhoneNumber?.id, isPrimary: true },
            { isActive: !!emergencyPhoneNumber?.isActive, number: removeCountryCode(emergencyPhoneNumber?.number), transferDepartmentName: BusinessPhoneNumberType.Emergency, id: emergencyPhoneNumber?.id, isPrimary: false },
            { isActive: !!customerSupportPhoneNumber?.isActive, number: removeCountryCode(customerSupportPhoneNumber?.number), transferDepartmentName: BusinessPhoneNumberType.CustomerSupport, id: customerSupportPhoneNumber?.id, isPrimary: false }
        ];

        return this.fb.array(phoneNumbers.map(phoneNumber => this.fb.group(phoneNumber)));
    }



    createServices(defaultServices: Service[]) {
        return this.fb.array(defaultServices.length > 0 ?
            defaultServices.map(service => this.createService(service)) :
            []);
    }

    createAreas(defaultAreas: Area[]) {
        return this.fb.array(defaultAreas.length > 0 ?
            defaultAreas.map(area => this.createArea(area)) :
            []);
    }

    areEqual(currentValues: any, initialValues: any): boolean {
        return areEqual(currentValues, initialValues);
    }

    initializeAgentForm(agent: Agent, faqs: TrainingFaq[], customQuestions: CustomQuestion[], supportedLanguages: { value: string, label: string }[]): FormGroup {
        const business: Business = agent?.business;
        const hasLegalDisclaimerEnabled = agent.configuration.hasOwnProperty('hasLegalDisclaimerEnabled') ? agent.configuration.hasLegalDisclaimerEnabled : true;
        const customGreeting = !hasLegalDisclaimerEnabled ? agent.configuration.customGreeting?.replace(':legal_disclaimer', '') : agent.configuration.customGreeting;
        const urlPattern = /^(https:\/\/)?([\da-z.-]+\.[a-z]{2,6})(.*)$/;
        return this.fb.group({
            profile: this.fb.group({
                tone: [agent?.configuration?.tone, Validators.required],
                title: [agent?.title, Validators.required],
            }),
            languages: this.createLanguages(agent?.configuration?.languages || [], supportedLanguages),
            configuration: this.fb.group({
                hasCustomQuestionsForNotesEnabled: true,
                appointmentLengthMinutes: agent.configuration.appointmentLengthMinutes || null,
            }),
            training: this.createFaqs(faqs || []),
            capabilities: this.createCustomQuestions(customQuestions || []),
            spam: this.fb.group({
                hasBlock1800NumbersEnabled: agent.configuration.hasBlock_1800NumbersEnabled || false,
                hasSmartSpamDetectionEnabled: agent.configuration.hasSmartSpamDetectionEnabled || false
            }),
            blockedPhoneNumbers: this.createBlockedPhoneNumbers(agent?.blockedPhoneNumbers || []),
            customGreeting: this.fb.group({ message: [customGreeting || '', Validators.maxLength(250)], hasLegalDisclaimerEnabled: hasLegalDisclaimerEnabled, legalDisclaimer: agent.greeting.legalDisclaimer || '', hasCustomGreetingEnabled: agent.configuration.hasCustomGreetingEnabled || false }),
            booking: this.fb.group({ id: agent.bookingMessage?.id, message: [agent.bookingMessage?.message || `Thanks for calling ${business?.title || ''}! Use the link below to schedule an appointment with us.`, [Validators.maxLength(2048)]], url: [agent.bookingMessage?.url || '', [Validators.pattern(urlPattern), Validators.maxLength(250)]], hasAppointmentsEnabled: agent.configuration.hasAppointmentsEnabled || false }),
        });
    }

    initializeBusinessForm(agent: Agent): FormGroup {
        const businessInformation: AdditionalProperties = agent?.business?.information?.additionalProperties;
        const business: Business = agent?.business;
        const primaryPhoneNumber = business?.phoneNumbers?.find(p => p.transferDepartmentName === BusinessPhoneNumberType.Main)
        const timezone = timeZones.find(tz => tz.value === businessInformation?.timezone)?.label || businessInformation?.timezone;
        return this.fb.group({
            details: this.fb.group({
                title: [business?.title, Validators.required],
                summary: [businessInformation?.summary],
                addresses: this.createAddresses(businessInformation?.addresses || []),
                hasCustomSummaryEnabled: agent.configuration.hasCustomSummaryEnabled || false,
                customSummary: [agent.configuration.customSummary || '', Validators.maxLength(300)],
                primaryBusinessPhoneNumber: this.fb.group({ isActive: !!primaryPhoneNumber, number: removeCountryCode(primaryPhoneNumber?.number), transferDepartmentName: BusinessPhoneNumberType.Main, id: primaryPhoneNumber?.id, isPrimary: true }),
            }),
            schedule: this.fb.group({
                hours: this.createBusinessHours(businessInformation?.businessHours || []),
                timezone: timezone
            }),
            services: this.fb.control(business.services?.map(service => service?.name) || []),
            areas: this.createAreas(businessInformation?.serviceAreas || []),
            takeMessage: [false],
            transferCalls: this.fb.group({
                hasTransferCallsEnabled: [agent.configuration.hasTransferCallsEnabled || false],
                phoneNumbers: this.createPhoneNumbers(business?.phoneNumbers || []),
            }),
            sourceTraining: this.fb.group({ placeId: businessInformation?.placeId, url: businessInformation?.url, name: businessInformation?.name }),
        });
    }

    createAgentFormValue(agent: Agent, faqs: TrainingFaq[], customQuestions: CustomQuestion[]): any {
        const hasLegalDisclaimerEnabled = agent.configuration.hasOwnProperty('hasLegalDisclaimerEnabled') ? agent.configuration.hasLegalDisclaimerEnabled : true;
        const customGreeting = !hasLegalDisclaimerEnabled
            ? agent.configuration.customGreeting?.replace(':legal_disclaimer', '')
            : agent.configuration.customGreeting;

        return {
            profile: {
                tone: agent?.configuration?.tone || null,
                title: agent?.title || ''
            },
            configuration: {
                hasTransferCallsEnabled: agent.configuration.hasTransferCallsEnabled || false,
                hasCustomQuestionsForNotesEnabled: true,
                appointmentLengthMinutes: agent.configuration.appointmentLengthMinutes || null,
            },
            training: faqs || [],
            capabilities: customQuestions || [],
            spam: {
                hasBlock1800NumbersEnabled: agent.configuration.hasBlock_1800NumbersEnabled || false,
                hasSmartSpamDetectionEnabled: agent.configuration.hasSmartSpamDetectionEnabled || false
            },
            blockedPhoneNumbers: agent.blockedPhoneNumbers || [],
            customGreeting: {
                message: customGreeting || '',
                hasLegalDisclaimerEnabled,
                legalDisclaimer: agent.greeting.legalDisclaimer || '',
                hasCustomGreetingEnabled: agent.configuration.hasCustomGreetingEnabled || false
            },
            booking: {
                id: agent.bookingMessage?.id || null,
                message:
                    agent.bookingMessage?.message ||
                    `Thanks for calling ${agent?.business?.title || ''}! Use the link below to schedule an appointment with us.`,
                url: agent.bookingMessage?.url || '',
                hasAppointmentsEnabled: agent.configuration.hasAppointmentsEnabled || false
            }
        };
    }

    createBusinessFormValue(agent: Agent): any {
        const businessInformation: AdditionalProperties = agent?.business?.information?.additionalProperties;
        const business: Business = agent?.business;
        const timezone = timeZones.find(tz => tz.value === businessInformation?.timezone)?.label || businessInformation?.timezone;

        return {
            details: {
                title: business?.title || '',
                summary: businessInformation?.summary || '',
                hasCustomSummaryEnabled: agent.configuration.hasCustomSummaryEnabled || false,
                customSummary: agent.configuration.customSummary || '',
                addresses: businessInformation.addresses?.filter((address) => address?.street) || [],
            },
            schedule: {
                hours: businessInformation?.businessHours?.map(hour => ({
                    day: hour.day,
                    open: this.convert24To12HourFormat(hour.open),
                    close: this.convert24To12HourFormat(hour.close)
                })) || [],
                timezone: timezone || null
            },
            services: business.services?.map(service => service?.name) || [],
            areas: businessInformation?.serviceAreas || [],
            transferCalls: {
                hasTransferCallsEnabled: agent.configuration.hasTransferCallsEnabled,
                phoneNumbers: business.phoneNumbers || [],
            },
            takeMessage: false,
            sourceTraining: {
                placeId: businessInformation?.placeId || '',
                url: businessInformation?.url || '',
                name: businessInformation?.name || ''
            }
        };
    }

}