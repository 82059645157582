<div class="modal-title text-left">{{ getTranslationWithAppName('UNINSTALL_APP') }}</div>
<div class="modal-subtitle with-border"></div>
<div class="modal-content">
  <div class="mb-2">{{ getAppSpecificTranslation('UNINSTALL_DESCRIPTION') }}</div>
  <div>{{ getAppSpecificTranslation('UNINSTALL_WARNING') }}
  </div>
</div>
<div class="modal-action-line with-border">
  <rosie-button (click)="close()" [customClass]="'secondary narrow'" [translationKey]="'COMMON.CANCEL'">
  </rosie-button>
  <rosie-button (click)="uninstall.emit()" [customClass]="'danger narrow slim'" [inProgress]="app?.inProgress"
    [translationKey]="'APP.UNINSTALL'">
  </rosie-button>
</div>