import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { objectPropertiesToArray } from '@rallycommerce/common/utils';
import { OnboardingService, OnboardingStep } from '../../onboarding/onboarding.service';

@Component({
    selector: 'rosie-agent-setup-process',
    templateUrl: 'agent-setup-process.component.html',
    styleUrls: ['./agent-setup-process.component.scss']
})
export class AgentSetupProcessComponent implements OnInit, OnDestroy {
    @Input() progressSubject;
    @Input() errorMessage = '';
    @Input() shorterText = false;
    @Input() defaultIntervalTime = 60;
    faCheck = faCheck;
    private completedAgentSteps = [];
    private stepProgressSubscription: Subscription;
    private progressSubscription: Subscription;

    currentStep: AgentStep = AgentStep.Analyzing;
    inProgress = false;
    progressValue: number = 1;
    stepProgressValue: number = 1;

    constructor(private onboarding: OnboardingService) {
    }

    ngOnInit(): void {
        this.progressSubject.subscribe((progressStatus: ProgressStatus) => {
            if (progressStatus === ProgressStatus.Start) {
                this.startProgressIncrement();
            } else if (progressStatus === ProgressStatus.Stop) {
                this.stopProgress();
            } else if (progressStatus === ProgressStatus.Finalize) {
                this.finalizeProgress();
            }
        });
    }

    ngOnDestroy(): void {
        this.progressValue = 1;
        this.stepProgressValue = 1;
        this.completedAgentSteps = [];
    }

    get agentSteps(): AgentStep[] {
        return objectPropertiesToArray(AgentStep);
    }

    isAgentStepComplete(step): boolean {
        return this.completedAgentSteps.includes(step);
    }

    getAgentStepTranslationKey(step): string {
        return `AGENT.STEPS.${this.shorterText ? 'SHORTER_VERSION.' : ''}${step}`;
    }

    private startProgressIncrement(): void {
        const stopAtNumber = 100;
        let incrementBy = 1;
        let intervalTime = 0;
        if (this.onboarding.chosenPath === OnboardingStep.GoogleLookupVerification) {
            intervalTime = 25;
        } else if (this.onboarding.chosenPath === OnboardingStep.BusinessInfo) {
            intervalTime = 20;
        } else {
            intervalTime = this.defaultIntervalTime;
        }
        this.progressSubscription = interval(intervalTime).subscribe(() => {
            if (this.progressValue < stopAtNumber) {
                this.progressValue += (this.progressValue > 95 ? 0.05 : incrementBy);
                if (this.progressValue % 25 === 0 || this.progressValue > 95) {
                    this.completedAgentSteps.push(this.currentStep);
                    this.currentStep = this.agentSteps[Math.floor(this.progressValue / 25)];
                    this.stepProgressValue = 0;
                }
            }
        });

        this.stepProgressSubscription = interval(intervalTime / 4).subscribe(() => {
            if (this.stepProgressValue < 100 && this.progressValue < stopAtNumber) {
                this.stepProgressValue += incrementBy;
            }
        });
    }

    private finalizeProgress(): void {
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
        if (this.stepProgressSubscription) {
            this.stepProgressSubscription.unsubscribe();
        }

        this.completedAgentSteps = this.agentSteps;
        this.stepProgressValue = 100;
        this.progressValue = 100;
    }

    private stopProgress() {
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
        if (this.stepProgressSubscription) {
            this.stepProgressSubscription.unsubscribe();
        }
        this.completedAgentSteps = [];
        this.progressValue = 0;
        this.stepProgressValue = 0;
    }
}

export enum AgentStep {
    Analyzing = 'ANALYZING',
    Processing = 'PROCESSING',
    Optimizing = 'OPTIMIZING',
    Generating = 'GENERATING',
}

export enum ProgressStatus {
    Start = 'START',
    Stop = 'STOP',
    Finalize = 'FINALIZE',
}