import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { generateUniqueAdminId } from '../../core/helpers/utils';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'rosie-switch',
  template: `
    <ng-container *ngIf="control">
      <ng-container *ngIf="isCheckSelector;then checkContent else toggleContent"></ng-container>
    </ng-container>
    <ng-template #checkContent>
      <label [for]="id" class="checkbox-label" [ngClass]="customClass">
        <span class="checkbox-description">{{ labelTranslationKey | translate }}</span>
        <input type="checkbox" [id]="id" [name]="name" [formControl]="control" (click)="toggle($event)" [ngClass]="{'checked': isChecked}"
               [dataHook]="labelTranslationKey"/>
        <span class="checkbox" [ngClass]="{'checked': isChecked}"><ng-icon [hidden]="!isChecked" [name]="'heroCheck'" [color]="'white'" [size]="'18px'"></ng-icon></span>
      </label>
    </ng-template>
    <ng-template #toggleContent>
      <input class="toggle" [ngClass]="customClass" type="checkbox" [id]="id" [formControl]="control"/>
      <label class="toggle-label" [for]="id" (click)="toggle($event)" (keydown.space)="toggle($event)" [dataHook]="labelTranslationKey"
             [ngClass]="{'disabled': isDisabled}"></label>
    </ng-template>
  `,
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnChanges {
  @Input() id = generateUniqueAdminId();
  @Input() labelTranslationKey = '';
  @Input() control: UntypedFormControl | AbstractControl = new UntypedFormControl();
  @Input() type: SwitchType = SwitchType.Toggle;
  @Input() customClass: '';
  @Input() isDisabled = false;
  @Input() name = '';
  @Output() state = new EventEmitter<boolean>();
  @Output() handleClick = new EventEmitter<boolean>();

  constructor() {
  }

  get isCheckSelector(): boolean {
    return this.type === SwitchType.Checkbox || this.type === SwitchType.Radio;
  }

  get isInputTypeRadio(): boolean {
    return this.type === SwitchType.Radio;
  }

  get isChecked(): boolean {
    return this.control.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('isDisabled')) {
      if (changes.isDisabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  toggle(event?: KeyboardEvent) {
    if (this.type !== SwitchType.Radio && event) {
      event.preventDefault();
      if (!this.isDisabled) {
        this.control.markAsDirty();
        this.control.setValue(!this.control.value);
      }
    }
    this.state.emit(this.control.value);
  }

}

export enum SwitchType {
  Checkbox = 'checkbox',
  Toggle = 'toggle',
  Radio = 'radio'
}
