import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../core/user/user.service';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    constructor(private user: UserService) { }

    get businessTimezone(): string {
        return this.user.activeBusiness?.timezone;
    }

    transform(value: string, fullDate?: boolean): string {
        if (!value) return '';

        const inputDate = new Date(value);
        const currentDate = new Date();
        const timezone = this.businessTimezone || 'UTC';

        if (fullDate) {
            const formatter = new Intl.DateTimeFormat('en-US', {
                timeZone: timezone,
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            });
            const daysAgo = this.getDaysAgo(inputDate, currentDate);
            return `${formatter.format(inputDate)} (${daysAgo})`;
        } else {
            if (this.isSameDay(inputDate, currentDate)) {
                const timeFormatter = new Intl.DateTimeFormat('en-US', {
                    timeZone: timezone,
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                });
                return timeFormatter.format(inputDate);
            } else {
                const dateFormatter = new Intl.DateTimeFormat('en-US', {
                    timeZone: timezone,
                    month: 'numeric',
                    day: 'numeric',
                    year: '2-digit',
                });
                return dateFormatter.format(inputDate);
            }
        }
    }

    private getDaysAgo(inputDate: Date, currentDate: Date): string {
        const diffInTime = currentDate.getTime() - inputDate.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

        if (diffInDays === 0) return 'Today';
        if (diffInDays === 1) return '1 day ago';
        return `${diffInDays} days ago`;
    }

    private isSameDay(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }
}
