import { Injectable } from '@angular/core';

import { Observable, tap } from 'rxjs';
import { User, UserService } from './user.service';
import { InternalAnalyticsService } from '../internal-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver {
  constructor(private user: UserService, private analytics: InternalAnalyticsService) {
  }

  resolve(): Observable<User> {
    return this.user.getUser().pipe(tap(() => this.analytics.identify()));
  }
}
