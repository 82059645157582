import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ScriptLoaderService {
  private renderer: Renderer2;
  private scripts: Script[] = [];

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public load(script: Script, override: boolean = true): Observable<Script> {
    return new Observable<Script>((observer: Observer<Script>) => {
      const existingScript = this.scripts.find(s => s.src === script.src);
      if (existingScript) {
        observer.next(existingScript);
        observer.complete();
      } else {
        const scriptElement = this.renderer.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = script.src;
        scriptElement.async = script.async == undefined ? true : script.async;

        if (!this.scripts.includes(script) && !override) {
          this.scripts = [...this.scripts, script];
        }

        scriptElement.onload = () => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any) => {
          const existingScriptIndex = this.scripts.findIndex(s => s.src === script.src);

          this.renderer.removeChild(document.body, scriptElement);
          this.scripts.splice(existingScriptIndex, 1);

          script.loaded = false;

          observer.error(`Couldn't load script${script.src}`);
        };

        this.renderer.appendChild(document.body, scriptElement);
      }
    });
  }
}

export interface Script {
  name: string;
  src: string;
  loaded?: boolean;
  async?: boolean;
}
