import { generateUniqueId } from '@rallycommerce/common/utils';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export function generateUniqueAdminId(prefix = 'rosie-'): string {
  return generateUniqueId(prefix);
}

export function getSimplifiedObject<T>(myObj: T, properties: string[]): Partial<T> {
  const simplifiedObject = {};
  Object.keys(myObj).filter((key: string) => properties.includes(key)).forEach((key: string) => simplifiedObject[key] = myObj[key]);
  return simplifiedObject;
}

export const removeFalsyValuesFromObject = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const sortArrayBy = (arr: any[], attribute: string, sortOrder: SortOrder = SortOrder.Asc) => {
  return arr.sort((a: any, b: any) => sortOrder === SortOrder.Asc ? a[`${attribute}`].localeCompare(b[`${attribute}`]) : b[`${attribute}`].localeCompare(a[`${attribute}`]));
};

export const sortNumericArrayBy = (arr: any[], attribute: string) => {
  return arr.sort((a: any, b: any) => a[`${attribute}`].localeCompare(b[`${attribute}`], undefined, { numeric: true }));
};

export const sortArrayByNumericAttribute = (arr: any[], attribute: string, desc = false) => {
  return arr.sort((a: any, b: any) => desc ? b[`${attribute}`] - a[`${attribute}`] : a[`${attribute}`] - b[`${attribute}`]);
};

export const mergeArrayWithObject = (arr: any[], obj: any) => arr && arr.map(t => t.id === obj.id ? obj : t);

export function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

export function convertCamelCaseToSnakeCase(value: string, caseType: CaseType): string {
  const camelCaseToSnakeCaseValue = value.replace(/([A-Z])/g, '_$1');
  return caseType === CaseType.UpperCase ? camelCaseToSnakeCaseValue?.toUpperCase() : camelCaseToSnakeCaseValue.toLowerCase();
}

export function convertSnakeCaseToCamelCase(value: string): string {
  return value.toLowerCase().replace(/([-_][a-z])/g, group =>
    group?.toUpperCase().replace('-', '').replace('_', '')
  );
}

export function getPrefixedKey(string: string, prefix = ''): string {
  return prefix ? `${prefix}-${environment.name || ''}-${string}` : `${environment.name || ''}-${string}`;
}

export function removeSpecialCharacters(value: string): string {
  return value.replace(/[^a-zA-Z ]/g, '');
}

function getLuminance(rgb: number[]) {
  const RED = 0.2126;
  const GREEN = 0.7152;
  const BLUE = 0.0722;

  const GAMMA = 2.4;
  const a = rgb.map((v) => {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : Math.pow((v + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

function hex2Rgb(hex: string): number[] | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

export function getContrast(hex1: string, hex2: string): number | null {
  const rgb1 = hex2Rgb(hex1);
  const rgb2 = hex2Rgb(hex2);
  if (rgb1 && rgb2) {
    const lum1 = getLuminance(rgb1);
    const lum2 = getLuminance(rgb2);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  }
  return null;
}

export function fromMediaQuery(breakpoint: Breakpoint
): Observable<boolean> {
  const media = matchMedia(breakpoint);

  return new Observable(observer => {
    const listener = (e: MediaQueryListEvent) => {
      observer.next(e.matches);
    };
    observer.next(media.matches);
    if (media.addEventListener) {
      media.addEventListener('change', listener)
    } else {
      media.addListener(listener);
    }
  });
}

export function capitalizeFirstLetter(val = '') {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  )
}

export function formatSecondsAsMinutes(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
  return `${minutes}:${formattedSeconds}`;
}

export function removeCountryCode(phoneNumber: string): string {
  if (phoneNumber?.startsWith('+1')) {
    return phoneNumber.slice(2);
  }
  return phoneNumber;
}

export enum CaseType {
  LowerCase = 'lowerCase',
  UpperCase = 'upperCase'
}


export interface LinkConfig {
  title: string;
  text?: string;
  url: string;
  target?: string;
}

export enum Breakpoint {
  Small = `(max-width: 575px)`,
  Medium = `(min-width: 576px) and (max-width: 767px)`,
  Large = `(min-width: 768px) and (max-width: 1023px)`,
  XLarge = `(min-width: 1024px)`,
  UpToMedium = `(max-width: 767px)`,
  UpToLarge = `(max-width: 1023px)`,
  FromMedium = `(min-width: 576px)`,
  FromLarge = `(min-width: 768px)`,
  FromXLarge = `(min-width: 1024px)`,
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}