import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as utc from 'dayjs/plugin/utc';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';

@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {
  private today: string;
  private aMinuteAgo: string;

  constructor(private translate: TranslateService) {
    dayjs.extend(relativeTime);
    dayjs.extend(utc);
    this.translate.get('TIME.TODAY').pipe(take(1)).subscribe({next: translation => this.today = translation});
    this.translate.get('TIME.A_MINUTE_AGO').pipe(take(1)).subscribe({next: translation => this.aMinuteAgo = translation});
  }

  transform(value: string, from?: dayjs.QUnitType | dayjs.OpUnitType, ...args: unknown[]): string {
    if (!value) {
      return '';
    }
    if (from) {
      const timeAgo = dayjs.utc().diff(value, from);
      if (from === 'day' && timeAgo === 0) {
        return this.today;
      }
      if (from === 'minute' && timeAgo < 1) {
        return this.aMinuteAgo;
      }
    }
    return dayjs(value).fromNow();
  }
}
