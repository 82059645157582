<div class="text-edit-wrapper-label text-uppercase">{{ fieldData.label }}</div>
<ng-container *ngIf="isFileUploadVisible else fileUploaded">
  <rosie-file-upload #fileUpload (fileData)="handleFileUpload($event, fieldData.key)"
    (uploadStatusChanged)="handleUploadStatusChanged($event)" [acceptFileTypes]="acceptFileTypes"
    [additionalData]="fileUploadAdditionalData" [config]="getFileFieldUploadConfig()"
    [ngClass]="{'w-100 d-block': isFileUploadVisible, 'd-none': !isFileUploadVisible }"
    [tooltip]="tooltip"></rosie-file-upload>
</ng-container>
<ng-template #fileUploaded>
  <div class="w-100 d-flex justify-content-between align-items-center file-field">
    <div class="d-flex justify-content-center align-items-center">
      <span *ngIf="hasFileJustBeenUploaded()" class="file-field-label mr-2">{{
        'FILE_UPLOAD.SUCCESSFUL' | translate
        }}</span>
      <span><img src="assets/images/checkbox-checked-circle.svg" /></span>
    </div>
    <button (click)="handleReplaceTriggered()" *ngIf="isFieldEditable()" class="file-replace">
      {{ 'COMMON.REPLACE' | translate }}
    </button>
  </div>
</ng-template>