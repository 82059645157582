import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { OnboardingService, OnboardingStep } from './onboarding.service';

@Injectable({
    providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
    constructor(private router: Router, private onboarding: OnboardingService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const path = route.routeConfig?.path;
        const queryParams = route.queryParams;
        let step = OnboardingStep.GoogleLookup;

        if (path?.includes('agent')) step = OnboardingStep.Agent;
        else if (path?.includes('preview')) step = OnboardingStep.Preview;
        else if (path?.includes('user')) step = OnboardingStep.User;
        this.onboarding.goToStep(step, queryParams, 'merge');
        return false;
    }
}
