import { Component, OnInit } from '@angular/core';
import { App, AppKey, AppProfile, AppType } from './app/app';
import { objectPropertiesToArray } from '@rallycommerce/common/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { AppsService } from './apps.service';
import { UntypedFormBuilder } from '@angular/forms';
import { PageService } from '../core/page.service';
import { environment } from '../../environments/environment';
import { UserService } from '../core/user/user.service';
import { Breakpoint, fromMediaQuery } from '../core/helpers/utils';


@Component({
  selector: 'rosie-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit {
  activeType: AppType = null;
  selectedApp: App;
  isMobileVersion: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private appsService: AppsService, private formBuilder: UntypedFormBuilder,
    private page: PageService, private user: UserService) {
    this.page.initializePage(`Rosie - ${this.user.activeBusiness.title} - Integrations`);
    this.route.paramMap
      .subscribe((paramMap) => {
        const selectedAppKey = paramMap.get('id') as AppKey;
        this.selectedApp = this.apps.find((app) => app.key === selectedAppKey);
        const subTitle = this.selectedApp?.name ? `- ${this.selectedApp.name}` : '';
        this.page.initializePage(`Rosie - ${this.user.activeBusiness.title} - Integrations ${subTitle}`);
        this.fetchAppRelatedData();

        const selectedAppType = paramMap.get('type') as AppType;
        if (selectedAppType) {
          this.setActiveAppTypeFilter(selectedAppType);
        }
      });
  }

  get appTypes(): string[] {
    const appTypes = objectPropertiesToArray<string>(AppType);
    appTypes.unshift(null);
    return appTypes;
  }

  get apps(): App[] {
    return this.appsService.apps;
  }

  get appsByActiveType(): App[] {
    return this.apps.filter((app) => this.activeType ? app.type === this.activeType : true)
      .sort((a: any, b: any) => b.isPreferredPartner - a.isPreferredPartner || a.name.localeCompare(b.name));
  }

  get isProduction(): boolean {
    return environment.name === 'production' || environment.name === 'sandbox';
  }

  ngOnInit(): void {
    this.route.paramMap
      .subscribe((paramMap) => {
        const selectedAppKey = paramMap.get('id') as AppKey;
        this.selectedApp = this.apps.find((app) => app.key === selectedAppKey);
        const subTitle = this.selectedApp?.name ? `- ${this.selectedApp.name}` : '';
        this.page.initializePage(`Rosie - ${this.user.activeBusiness.title} - Integrations ${subTitle}`);
        this.fetchAppRelatedData();

        const selectedAppType = paramMap.get('type') as AppType;
        if (selectedAppType) {
          this.setActiveAppTypeFilter(selectedAppType);
        }
      });
    fromMediaQuery(Breakpoint.UpToMedium).subscribe(matches => this.isMobileVersion = matches);
  }

  setActiveAppTypeFilter(appType: AppType) {
    this.activeType = appType;
  }

  isAppHidden(app: App): boolean {
    return app.key === AppKey.GoogleCalendar || app.key === AppKey.AppointmentConfirmation;
  }

  getAppTypeTranslationKey(appType: string) {
    return appType ? `APPS.${appType?.toUpperCase()}` : `APPS.ALL`;
  }

  getAppTranslation(app: App, suffix: string): string {
    return `APP.${app.key?.toUpperCase()}?.${suffix}`;
  }

  seeApp(appKey: AppKey) {
    this.router.navigate(['/integrations', { id: appKey }]);
  }

  getLogo(app: App): string {
    return `assets/images/${app.referenceId}.svg`;
  }

  private fetchAppRelatedData() {
    if (this.selectedApp && !this.selectedApp.isIntegrating) {
      this.selectedApp.inProgress = true;
      if (this.selectedApp.isInstalled) {
        this.appsService.getAppData(this.selectedApp).subscribe((appData: AppProfile) => {
          this.selectedApp.details.profile = appData;
          this.selectedApp.inProgress = false;
        }, () => this.selectedApp.inProgress = false);
      } else {
        this.selectedApp.inProgress = false;
      }
    }
  }

}
