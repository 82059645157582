import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  FileConfig,
  FilePurpose,
  FileUploadComponent,
  FileUploadConfig,
  FileUploadData,
  FileUploadSource,
  FileUploadStatus
} from 'src/app/shared/file-upload/file-upload.component';
import {runOnNextTick} from '@rallycommerce/common/utils';
import {AppFieldType} from '../app';
import {ImageAction} from '../../../shared/image-upload/image-upload.component';

@Component({
  selector: 'rosie-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss']
})
export class FileFieldComponent implements OnInit, AfterViewInit {

  @Input() fieldData: FileFieldData;
  @Input() isEditable = true;
  @Input() customClass = '';
  @Input() tooltip = '';
  @Output() fileUploaded: EventEmitter<{ data: File, fieldName: string }> = new EventEmitter<{ data: File, fieldName: string }>();
  @ViewChildren('fileUpload') fileUploadQueryList: QueryList<FileUploadComponent>;

  AppFieldType = AppFieldType;
  acceptFileTypes = 'file/*';
  fileUploadAdditionalData: FileUploadData = {
    origin: FileUploadSource.Self,
    data: {filePurpose: FilePurpose.File}
  };
  private fileUpload: FileUploadComponent;
  private fileStatus: FileUploadStatus = FileUploadStatus.None;

  constructor(private translate: TranslateService) {
  }

  get isFileUploadVisible(): boolean {
    return this.fileStatus === FileUploadStatus.ReplaceInProgress || this.fileStatus === FileUploadStatus.None;
  }

  ngOnInit() {
    this.fileStatus = this.fieldData.value ? FileUploadStatus.Uploaded : FileUploadStatus.None;
  }

  ngAfterViewInit() {
    this.fileUploadQueryList?.changes?.subscribe((components: QueryList<FileUploadComponent>) => {
      this.fileUpload = components.first;
    });
  }

  getFileFieldConfig(): FileConfig {
    return {
      filePurpose: FilePurpose.File,
      url: this.fieldData.value,
      dropdownConfig: {
        style: {dropdown: 'dropdown-wrapper'},
        items: [{id: ImageAction.Replace, name: this.translate.instant('COMMON.REPLACE')}]
      }
    };
  }

  getFileFieldUploadConfig(): FileUploadConfig {
    return {
      customClass: `app-file-upload ${this.customClass}`,
      loaderText: 'EDITOR.DESIGN_SECTION.LOGO.UPLOADING_FILE',
      ctaTranslationKey: '',
      ctaIcon: 'plus.svg'
    };
  }

  handleFileUpload(data: File, fieldName: string) {
    this.fileUploaded.emit({data, fieldName});
    this.fileStatus = FileUploadStatus.Uploaded;
  }

  handleUploadStatusChanged(fileStatus: FileUploadStatus) {
    this.fileStatus = fileStatus;
  }

  handleReplaceTriggered() {
    this.fileStatus = FileUploadStatus.ReplaceInProgress;
    // we need to wait for `FileUploadComponent` to be rendered before accessing its function
    runOnNextTick(() => this.fileUpload?.openFileDialog());
  }

  hasFileJustBeenUploaded(): boolean {
    return !this.fieldData.value;
  }

  isFieldEditable(): boolean {
    return this.isEditable;
  }

}

export interface FileFieldData {
  key: string,
  label: string,
  value: string
}
