import {Component} from '@angular/core';
import {PageService} from '../../core/page.service';

@Component({
  selector: 'rosie-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(private page: PageService) {
    this.page.initializePage(`Rosie - Error`);
  }
}
