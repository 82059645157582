import { Component, OnInit, ViewChild } from '@angular/core';
import { User, UserService } from '../../core/user/user.service';
import { Breakpoint, fromMediaQuery } from '../../core/helpers/utils';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsEvent, InternalAnalyticsService } from '../../core/internal-analytics.service';

@Component({
    selector: 'rosie-refer-friend',
    templateUrl: './refer-friend.component.html',
    styleUrls: ['./refer-friend.component.scss']
})
export class ReferFriendComponent implements OnInit {
    private progressStatus: InProgress = null;

    referrerUrl: string = '';
    isMobileVersion: boolean = false;

    @ViewChild('referFriendModal') referFriendModal: NgxSmartModalComponent;

    constructor(private user: UserService, private translate: TranslateService, private analytics: InternalAnalyticsService) {
    }

    get userInformation(): User {
        return this.user.userData;
    }

    get activeBusiness() {
        return this.user.activeBusiness;
    }

    get inProgress(): boolean {
        return this.progressStatus === InProgress.Updating;
    }

    get isComplete(): boolean {
        return this.progressStatus === InProgress.Complete;
    }

    ngOnInit(): void {
        fromMediaQuery(Breakpoint.UpToMedium).subscribe(matches => this.isMobileVersion = matches);
        const referrerId = this.userInformation?.campaign?.rrf;
        const referrerCampaign = this.userInformation?.campaign?.rcmp;
        this.referrerUrl = `https://heyrosie.com?rrf=${referrerId}&rcmp=${referrerCampaign}`;
    }

    getCustomClass() {
        return this.isComplete && !this.isMobileVersion ? 'btn feedback narrow complete' : 'btn feedback narrow';
    }

    getTranslationKey() {
        const initialCopy = this.isMobileVersion ? 'REFER.REFER_ROSIE' : 'REFER.COPY_MESSAGE'
        return this.isComplete && !this.isMobileVersion ? 'REFER.MESSAGE_COPIED' : initialCopy;
    }

    getIconSuffixConfig() {
        return { name: this.isComplete ? 'heroCheckCircle' : '', color: '#99F6DF', size: '20px', strokeWidth: '2px' };
    }

    handleShare() {
        this.analytics.trackEvent(AnalyticsEvent.ShareRosieAction);
        if (navigator.share && this.isMobileVersion) {
            this.shareOnMobile();
        } else {
            this.copyToClipboard();
            this.progressStatus = InProgress.Complete;
            setTimeout(() => this.progressStatus = null, 2000);
        }
    }

    handleReferFriendModalOpen() {
        this.analytics.trackEvent(AnalyticsEvent.ShareRosieInitiated)
    }

    private shareOnMobile() {
        navigator.share({
            title: `${this.translate.instant('REFER.MESSAGE_HEADING')}\n`,
            text: `${this.translate.instant('REFER.MESSAGE_CONTENT')}\n\n${this.referrerUrl}`,
            // url: `${this.referrerUrl}`
        })
            .then(() => console.log('Share successful!'))
            .catch((error) => console.error('Error sharing:', error));
    }

    private copyToClipboard() {
        const message = `${this.translate.instant('REFER.MESSAGE_CONTENT')}\n\n${this.referrerUrl}`;

        navigator.clipboard.writeText(message)
            .then(() => {
                console.log('Message copied to clipboard!');
            })
            .catch((error) => console.error('Failed to copy message:', error));
    }
}


export enum InProgress {
    Save = 'save',
    Edit = 'edit',
    Cancel = 'cancel',
    Updating = 'updating',
    Complete = 'complete'
}
