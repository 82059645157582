<div class="configuration-phone-number">
    <div class="phone-number">
        <img src="assets/images/phone.svg" class="mr-2">
        <a class="number" *ngIf="agentPhoneNumber" [href]="'tel:' + agentPhoneNumber">{{ agentPhoneNumber |
            phoneNumber
            }}</a>
        <span *ngIf="!agentPhoneNumber">
            <rosie-loader
                [config]="{show: true, customLoaderWrapperClass: 'rosie-dark-loader rosie-btn-loader'}"></rosie-loader>
        </span>
    </div>
    <div class="time-usage d-flex align-items-center" *ngIf="isTrialing">
        <circle-progress [percent]="percentsUsed" [maxPercent]="100" [outerStrokeColor]="strokeColor"></circle-progress>
        <span class="time-usage-text">{{
            minutesRemainingFormatted }}
            {{'SUBSCRIPTION.MINUTES_LEFT' | translate}}</span>
        <img src="assets/images/info.svg" [ngbTooltip]="trailTooltip"
            tooltipClass="tooltip-wrapper content-tooltip description-tooltip" positionTarget=".time-usage" />
        <ng-template #trailTooltip>
            <span *ngIf="!isCreditCardAdded"
                [innerHTML]="'SUBSCRIPTION.MINUTES_USED_TRIAL_DESCRIPTION' | translate:{minutesUsed: minutesUsedFormatted, minutesIncluded: subscriptionData?.minutesIncluded, minutesRemaining: minutesRemaining, billingDate: subscriptionData?.billingDate | dateFormat, overageMinutes: extraMinutes, overageCost: userInformation?.subscription?.overageCost | rosieCurrency }">
            </span>
            <span *ngIf="isCreditCardAdded"
                [innerHTML]="'SUBSCRIPTION.MINUTES_LEFT_DESCRIPRION' | translate:{value: minutesRemainingFormatted, selectedPlan: selectedPlan, selectedPlanPrice: selectedPlanPrice}">
            </span>
        </ng-template>
    </div>
    <div class="time-usage d-flex align-items-center" *ngIf="isSubscriptionActive">
        <circle-progress [percent]="percentsUsed" [maxPercent]="100" [outerStrokeColor]="strokeColor"></circle-progress>
        <span class="time-usage-text">
            {{ isOverages ? extraMinutes : minutesUsedFormatted }}
            {{ (isOverages ? 'SUBSCRIPTION.MINUTES_EXTRA' : 'SUBSCRIPTION.MINUTES_USED') | translate}}</span>
        <ng-template #tipContent>
            <span *ngIf="isOverages"
                [innerHTML]="'SUBSCRIPTION.MINUTES_EXTRA_DESCRIPTION' | translate:{minutesUsed: minutesUsedFormatted, minutesIncluded: subscriptionData?.minutesIncluded, minutesRemaining: minutesRemaining, billingDate: subscriptionData?.billingDate | dateFormat, overageMinutes: extraMinutes, overageCost: userInformation?.subscription?.overageCost | rosieCurrency }">
            </span>
            <span *ngIf="!isOverages"
                [innerHTML]="'SUBSCRIPTION.MINUTES_USED_DESCRIPTION' | translate:{minutesUsed: minutesUsedFormatted, minutesIncluded: subscriptionData?.minutesIncluded, minutesRemaining: minutesRemaining, billingDate: subscriptionData?.billingDate | dateFormat, overageMinutes: extraMinutes, overageCost: userInformation?.subscription?.overageCost | rosieCurrency }">
            </span>
        </ng-template>
        <img src="assets/images/info.svg" [ngbTooltip]="tipContent"
            tooltipClass="tooltip-wrapper content-tooltip description-tooltip" positionTarget=".time-usage" />
    </div>
</div>