import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputViewComponent } from './input-view/input-view.component';
import { ButtonComponent } from './button/button.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TextComponent } from './text/text.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDatepickerModule, NgbDropdownModule, NgbHighlight, NgbProgressbarModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoaderComponent } from './loader/loader.component';
import { SwitchComponent } from './switch/switch.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { PasswordFieldComponent } from './password-field/password-field.component';
import { IntegrationAnimationComponent } from './integration-animation/integration-animation.component';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { TextInputAddonComponent } from './text-input-addon/text-input-addon.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertComponent } from './alert/alert.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { DisplayFieldComponent } from './display-field/display-field.component';
import { DataHookDirective } from './directives/data-hook.directive';
import { NavigatorComponent } from './navigator/navigator.component';
import { UpdatedAtPipe } from './pipes/updated-at.pipe';
import { ShimmerDirective } from './directives/shimmer.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ScrollIntoViewOnInitDirective } from './directives/scroll-into-view-on-init.directive';
import { FieldValueCopyComponent } from './field-value-copy/field-value-copy.component';
import { InfoTileComponent } from './info-tile/info-tile.component';
import { HoverComponent } from './hover/hover.component';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from './svg/svg.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { RosieCurrencyPipe } from './pipes/rosie-currency.pipe';
import { SubscriptionComponent } from './subscription/subscription.component';
import { DateFormatPipe } from './date-format.pipe';
import { PlyrModule } from '@atom-platform/ngx-plyr';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { PhoneMaskDirective } from './phone-mask.directive';
import { NgxMaskModule } from 'ngx-mask';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { PhoneNumberPipe } from './phone-number.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PossessivePipe } from './possessive/possessive.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { NgIconsModule } from '@ng-icons/core';
import { heroArrowRight, heroCheck, heroMagnifyingGlass } from '@ng-icons/heroicons/outline';
import { heroSparklesSolid } from '@ng-icons/heroicons/solid';
import { GooglePlacesSearchComponent } from './google-places-search/google-places-search.component';
import { AgentSetupProcessComponent } from './agent-setup-process/agent-setup-process.component';
import { PillsComponent } from './pills/pills.component';


@NgModule({
  declarations: [
    InputViewComponent,
    ButtonComponent,
    SelectInputComponent,
    TextComponent,
    DateAgoPipe,
    LoaderComponent,
    SwitchComponent,
    LoaderComponent,
    NumberInputComponent,
    PasswordFieldComponent,
    IntegrationAnimationComponent,
    ValidationErrorComponent,
    TextInputAddonComponent,
    AlertComponent,
    FileUploadComponent,
    DropdownComponent,
    TruncateTextPipe,
    DisplayFieldComponent,
    DataHookDirective,
    NavigatorComponent,
    UpdatedAtPipe,
    ShimmerDirective,
    ClickOutsideDirective,
    ScrollIntoViewOnInitDirective,
    FieldValueCopyComponent,
    InfoTileComponent,
    HoverComponent,
    SvgIconComponent,
    RadioGroupComponent,
    ImageUploadComponent,
    RosieCurrencyPipe,
    SubscriptionComponent,
    DateFormatPipe,
    TypeaheadComponent,
    PhoneMaskDirective,
    PhoneInputComponent,
    PhoneNumberPipe,
    PossessivePipe,
    CapitalizePipe,
    GooglePlacesSearchComponent,
    AgentSetupProcessComponent,
    PillsComponent
  ],
  imports: [
    NgbProgressbarModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgxSmartModalModule,
    FontAwesomeModule,
    NgbDropdownModule,
    NgbTypeaheadModule,
    NgbHighlight,
    InfiniteScrollModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    RouterModule,
    PlyrModule,
    NgbTypeaheadModule,
    NgxMaskModule.forRoot(),
    NgIconsModule.withIcons({ heroArrowRight, heroSparklesSolid, heroMagnifyingGlass, heroCheck }),
    NgCircleProgressModule.forRoot({
      "backgroundPadding": 0,
      "radius": 8,
      "space": -3,
      "toFixed": 0,
      "maxPercent": 100,
      "outerStrokeWidth": 3,
      "outerStrokeColor": "#9332e0",
      "outerStrokeGradientStopColor": "#53a9ff",
      "innerStrokeColor": "#dab7fb42",
      "innerStrokeWidth": 3,
      "title": "UI",
      "animateTitle": false,
      "animationDuration": 1000,
      "showTitle": false,
      "showSubtitle": false,
      "showUnits": false,
      "showBackground": false,
      "startFromZero": false,
      "showZeroOuterStroke": false,
      "lazy": true
    })
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    InputViewComponent,
    ButtonComponent,
    TextComponent,
    SelectInputComponent,
    DateAgoPipe,
    NgxSmartModalModule,
    FontAwesomeModule,
    NgbTypeaheadModule,
    NgbHighlight,
    NgbDropdownModule,
    LoaderComponent,
    SwitchComponent,
    LoaderComponent,
    NumberInputComponent,
    PasswordFieldComponent,
    IntegrationAnimationComponent,
    ValidationErrorComponent,
    TextInputAddonComponent,
    NgSelectModule,
    AlertComponent,
    FileUploadComponent,
    DropdownComponent,
    TruncateTextPipe,
    DisplayFieldComponent,
    NgbDatepickerModule,
    DataHookDirective,
    NavigatorComponent,
    UpdatedAtPipe,
    ShimmerDirective,
    ClickOutsideDirective,
    ScrollIntoViewOnInitDirective,
    FieldValueCopyComponent,
    InfoTileComponent,
    HoverComponent,
    NgbTooltipModule,
    SvgIconComponent,
    RadioGroupComponent,
    ImageUploadComponent,
    RosieCurrencyPipe,
    SubscriptionComponent,
    DateFormatPipe,
    PlyrModule,
    TypeaheadComponent,
    PhoneMaskDirective,
    PhoneInputComponent,
    NgCircleProgressModule,
    PhoneNumberPipe,
    PossessivePipe,
    CapitalizePipe,
    GooglePlacesSearchComponent,
    AgentSetupProcessComponent,
    PillsComponent
  ]
})
export class SharedModule {
}
