import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User, UserService } from '../core/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountSection, AccountService } from './account.service';
import { objectPropertiesToArray } from '@rallycommerce/common/utils';
@Component({
  selector: 'rosie-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  AccountSection = AccountSection;
  constructor(private user: UserService, private translate: TranslateService, private router: Router, private account: AccountService, private route: ActivatedRoute) {
  }

  get userData(): User {
    return this.user.userData;
  }

  get isMobileVersion(): boolean {
    return this.account.isMobileVersion
  }

  get activeSection(): AccountSection {
    return this.account.activeSection;
  }

  get sections(): string[] {
    return objectPropertiesToArray(AccountSection);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      const selectedSection = paramMap.get('section') as AccountSection;
      if (this.account.activeSection !== selectedSection && (!this.isMobileVersion || (this.isMobileVersion && selectedSection))) {
        this.account.activeSection = selectedSection || AccountSection.Profile;
      }
    });
  }

  handleSettingsSectionChange(section: AccountSection): void {
    this.router.navigate(['/account', { section }]);
  }

  getTranslation(section: string, translationKey: string): string {
    return this.translate.instant(`ACCOUNT_SECTION.${section?.toUpperCase()}.${translationKey?.toUpperCase()}`);
  }

  getIcon(section: string): string {
    return sectionIcons[section];
  }

  back() {
    this.account.activeSection = null;
    this.router.navigate(['/account']);
  }
}

export const sectionIcons = {
  [AccountSection.Profile]: 'heroClipboard',
  [AccountSection.Billing]: 'heroCreditCard',
  [AccountSection.Notifications]: 'heroBell',

};