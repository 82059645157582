import {Component, Input, OnInit} from '@angular/core';
import {timer} from 'rxjs';

@Component({
  selector: 'rosie-field-value-copy',
  templateUrl: './field-value-copy.component.html',
  styleUrls: ['./field-value-copy.component.scss']
})
export class FieldValueCopyComponent implements OnInit {
  @Input() labelKey = '';
  @Input() value = '';
  @Input() customClass = '';
  @Input() showShimmer: boolean;

  copiedValues = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  hasCopiedValue(): boolean {
    return this.copiedValues.includes(this.value);
  }

  copyFieldValue() {
    this.copiedValues.push(this.value);
    navigator.clipboard.writeText(this.value).then();
    timer(2500).subscribe(() => this.copiedValues = []);
  }
}
