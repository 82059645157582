import {Component, Input} from '@angular/core';

@Component({
  selector: 'rosie-loader',
  template: `
    <div class="loader-wrapper" [ngClass]="{'full': config.full}" class="{{config.size}} {{config.customLoaderWrapperClass}}"
         *ngIf="config.show">
      <div class="loader {{config.customLoaderClass}}">
        <div class="spinner {{config.customSpinnerClass}}"></div>
        <svg *ngIf="config.icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M18 10V6A6 6 0 006 6v4H3v14h18V10h-3zm-5 7.7V20h-2v-2.3a2 2 0 011-3.7 2 2 0 011 3.7zM8 10V6a4 4 0 018 0v4H8z"/>
        </svg>
      </div>
      <p *ngIf="config.loaderText">{{ config.loaderText | translate }}</p>
    </div>
  `,
  styleUrls: ['./loader.scss']
})
export class LoaderComponent {

  @Input() config: LoaderConfig = {
    show: false,
    full: false,
    size: 'sm',
    icon: false,
    customLoaderWrapperClass: ''
  };

  constructor() {
  }
}

export interface LoaderConfig {
  show: boolean;
  full: boolean;
  size: 'lg' | 'md' | 'sm';
  loaderText?: string;
  icon: boolean;
  customLoaderWrapperClass?: string;
  customLoaderClass?: string;
  customSpinnerClass?: string;
}
