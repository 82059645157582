import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { runOnNextTick } from '@rallycommerce/common/utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { generateUniqueAdminId } from '../../core/helpers/utils';

@Component({
  selector: 'rosie-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit, AfterViewInit {
  @Input() id = generateUniqueAdminId();
  @Input() disabled = false;
  @Input() subType = TextSubtype.Text;
  @Input() placeholderTranslationKey = '';
  @Input() labelTranslationKey = '';
  @Input() control: UntypedFormControl | AbstractControl;
  @Input() type = TextType.Input;
  @Input() rows = 13;
  @Input() prefix: string;
  @Input() alwaysDisplayPrefix = false;
  @Input() lowercaseOnly = false;
  @Input() customClass = '';
  @Input() min: number = null;
  @Input() maxLength: number = null;
  @Input() tooltip = '';
  @Output() onFocusChanged = new EventEmitter<Event>();
  @Output() onBlur = new EventEmitter<Event>();
  @Output() onEnter = new EventEmitter<Event>();

  @ViewChild('prefixContainer', { read: ElementRef }) prefixEl: ElementRef;

  inputPaddingLeft?: string;

  constructor() {
  }

  get hasValue(): boolean {
    return this.control.value !== null && this.control.value !== undefined && this.control.value !== '';
  }

  get isInputType(): boolean {
    return this.type !== TextType.Area;
  }

  get hasTooltip(): boolean {
    return !!this.tooltip;
  }

  get isUrlInputType(): boolean {
    return this.type === TextType.Url;
  }

  ngOnInit(): void {
    if (this.lowercaseOnly) {
      this.control.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(value => {
          this.control.patchValue(value?.toLowerCase());
        });
    }
  }

  ngAfterViewInit(): void {
    if (this.prefix && this.prefixEl) {
      runOnNextTick(() => {
        this.inputPaddingLeft = `calc(0.875rem + ${this.prefixEl.nativeElement.offsetWidth || 9}px + 5px)`;
      });
    }
  }

  handleOnFocus(event: Event) {
    this.control.markAsUntouched();
    this.onFocusChanged.next(event);
  }

  handleOnBlur(event: Event) {
    this.onFocusChanged.next(event);
    this.onBlur.next(event);
  }

  handleOnEnter(event: Event) {
    this.onEnter.next(event);
  }
}

export enum TextType {
  Input = 'input',
  Area = 'textarea',
  Number = 'number',
  Text = 'text',
  Url = 'url',
  Email = 'email',
}

export enum TextSubtype {
  Text = 'text',
}

export enum DiscountUnit {
  Percentage = '%',
  Dollar = '$'
}

export enum EventType {
  Focus = 'focus'
}
