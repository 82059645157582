import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {AppsService} from '../../../apps.service';
import {App} from '../../app';

@Component({
  selector: 'rosie-app-install',
  templateUrl: './app-install.component.html',
  styleUrls: ['./app-install.component.scss']
})
export class AppInstallComponent {

  @Input() app: App;
  @Input() form: TemplateRef<any>;

  @Output() install = new EventEmitter<void>();
  @Output() updateAppProfile = new EventEmitter<any>();

  constructor(private apps: AppsService, private modal: NgxSmartModalService) {
  }

  get formOptions(): any {
    const formOptions = this.app?.setupData?.formOptions || {};
    this.app?.setupData?.formFields?.forEach((field) => {
      const formFieldOption = formOptions[field.name] || {};
      formOptions[field.name] = {...formFieldOption, hidden: this.app.isFieldActionPostInstall(field.name)};
    });
    return formOptions;
  }

  getTranslationWithAppName(translationKey: string, showPrefix = false): string {
    return this.apps.buildTranslation(translationKey, {app: this.app, showPrefix});
  }

  getAppSpecificTranslation(translationKey: string, showPrefix = false): string {
    return this.apps.buildTranslation(translationKey, {
      app: this.app,
      useAppKey: !!this.app,
      showPrefix
    });
  }

  close(): void {
    this.modal.getModal('installModal').close();
  }

}
