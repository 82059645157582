import { Component, OnInit } from '@angular/core';
import { User, UserService } from '../../core/user/user.service';
import { AuthenticationService } from '../../core/auth/authentication-service';
import { CaseType, convertCamelCaseToSnakeCase, formatSecondsAsMinutes } from '../../core/helpers/utils';
import { SubscriptionService } from '../../core/subscription/subscription.service';
import { AgentService } from '../../core/agent/agent.service';
import { environment } from '../../../environments/environment';
import { SubscriptionStatus, Subscription } from '../../core/subscription/subscription';

@Component({
    selector: 'rosie-usage-status',
    templateUrl: './usage-status.component.html',
    styleUrls: ['./usage-status.component.scss']
})
export class UsageStatusComponent implements OnInit {

    constructor(private user: UserService, private auth: AuthenticationService, private agent: AgentService, private subscription: SubscriptionService) {
    }

    get userInformation(): User {
        return this.user.userData;
    }

    get activeBusiness() {
        return this.user.activeBusiness;
    }

    get isProduction(): boolean {
        return environment.name === 'production' || environment.name === 'sandbox';
    }

    get agentPhoneNumber(): string {
        return this.agent.primary?.phoneNumbers?.find(p => p.isPrimary)?.number;
    }

    get hasTrialMinutesLeft(): boolean {
        return this.agent.primary?.trialSecondsRemaining > 0;
    }

    get subscriptionData(): Subscription {
        return this.user.subscription;
    }

    get availableMinutes(): number {
        const trialMinutes = this.user?.subscription?.trialMinutes || 50;
        return this.user.subscription?.status === SubscriptionStatus.Trialing ? trialMinutes : this.subscriptionData?.minutesIncluded;
    }

    get strokeColor(): string {
        return this.percentsUsed < 90 ? '#9332e0' : '#F43F5E';
    }

    get isTrialing(): boolean {
        return this.user.subscription?.status === SubscriptionStatus.Trialing;
    }

    get isSubscriptionActive() {
        return this.user.subscription?.status === SubscriptionStatus.Active;
    }

    get extraMinutes(): string {
        return formatSecondsAsMinutes((this.subscriptionData?.secondsUsed / 60 - this.availableMinutes) * 60);
    }

    get minutesRemaining(): number {
        const minutesRemainingInTrial = Math.floor(this.agent.primary?.trialSecondsRemaining / 60);
        const minutesRemainingOnPlan = this.availableMinutes - Math.floor(this.subscriptionData?.secondsUsed / 60);

        return this.isTrialing ? minutesRemainingInTrial : minutesRemainingOnPlan;
    }

    get minutesUsedFormatted() {
        return formatSecondsAsMinutes(this.subscriptionData?.secondsUsed);
    }

    get isOverages(): boolean {
        return this.percentsUsed >= 100;
    }

    get percentsUsed(): number {
        const minutesRemaining = Math.floor(this.agent.primary?.trialSecondsRemaining / 60);
        const percentsUsedTrial = Math.floor((this.availableMinutes - minutesRemaining) / this.availableMinutes * 100);
        const percentsUsedSubscription = Math.floor(this.subscriptionData?.secondsUsed / 60 / this.availableMinutes * 100);
        return this.isTrialing ? percentsUsedTrial : percentsUsedSubscription;
    }

    get minutesRemainingFormatted(): string {
        return formatSecondsAsMinutes(this.agent.primary?.trialSecondsRemaining);
    }

    get isCreditCardAdded(): boolean {
        return this.user.subscription?.isCreditCardAdded;
    }

    get selectedPlan(): string {
        return this.user.subscription?.planName;
    }

    get selectedPlanPrice(): number {
        return this.user.subscription?.planCost;
    }

    ngOnInit() {
    }

    getTranslationKey(key: string): string {
        return `SIDEBAR.${key.replace(/[^a-zA-Z0-9 ]/g, '')?.toUpperCase()}`;
    }

    getKebabCaseKey(key: string): string {
        return convertCamelCaseToSnakeCase(key, CaseType.LowerCase).replace(/_/g, '-');
    }
}