<div class="not-found-wrapper w-100 d-flex justify-content-center align-items-center position-relative">
  <div class="left-column">
    <div class="content">
      <h1>{{ 'NOT_FOUND.WHOOPS' | translate }}</h1>
      <p class="mt-4">{{ 'NOT_FOUND.HEADING' | translate }}</p>
      <button clas="pointer" onclick="history.go(-1)">
        <img class="arrow-left" src="assets/images/arrow-left-gray.svg" />
        {{ 'NOT_FOUND.GO_BACK' | translate }}
      </button>
    </div>
  </div>
  <div class="right-column">
    <img src="assets/images/ghost-error.svg" />
  </div>
</div>