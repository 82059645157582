<div class="modal-title text-left">{{ getAppStatusTranslation('SETTINGS', true) }}</div>
<div class="modal-subtitle with-border"></div>
<div class="modal-content">
  <div *ngIf="app && app?.isPending" class="app-section-heading-wrapper mb-4">
    <div [innerHtml]="'APP.STATUS.PENDING.SETTINGS_DESCRIPTION' | translate:{value: this.app?.name }"
      class="app-section-description"></div>
  </div>
  <div *ngIf="app && !app?.hasAnyEditableFieldsOnceInstalled && !app?.isPending"
    class="app-section-heading-wrapper mb-4">
    <div [innerHtml]="getAppSpecificTranslation('SETTINGS_DESCRIPTION')" class="app-section-description"></div>
  </div>
  <div *ngIf="app?.inProgress" class="mb-3">
    <rosie-loader [config]="{show: app?.inProgress, customLoaderWrapperClass: 'rosie-dark-loader', size: 'lg'}">
    </rosie-loader>
  </div>
  <div *ngFor="let field of readOnlyFields; let last=last; let first=first"
    [ngClass]="{'last': last, 'first': first, 'app-field': true}">
    <rosie-display-field [behaviour]="field.behaviour" [helpTextKey]="getHelpTextPerField(field.name)"
      [icon]="field.value ? getFieldIconValue(field.name) : ''" [labelKey]="field.name" [value]="field.value">
    </rosie-display-field>
  </div>
  <div *ngIf="app?.hasAnyEditableFieldsOnceInstalled">
    <div class="app-section-heading-wrapper mb-4  mt-6">
      <div class="app-section-heading">{{ getAppStatusTranslation('SETTINGS_HEADING', true) }}</div>
    </div>
    <div *ngFor="let field of editableFields; let last=last; let first=first" [ngClass]="{'last': last, 'first': first}"
      class="app-field">
      <rosie-display-field [behaviour]="field.behaviour" [helpTextKey]="getHelpTextPerField(field.name)"
        [icon]="field.value ? getFieldIconValue(field.name) : ''" [labelKey]="field.name" [value]="field.value">
      </rosie-display-field>

    </div>
    <div *ngIf="isReauthorizeAllowed" class="app-field reauthorize">
      <div class="d-flex align-items-center"><i class="refresh-icon mr-2"></i>{{
        getAppStatusTranslation('LAST_AUTHORIZED') }},
        {{ getFormattedCreatedAt() }}
      </div>
      <div class="action-button">
        <rosie-button (click)="install.emit()" [customClass]="'reauthorize-button'" [darkLoader]="true"
          [fullWidth]="true" [translationKey]="getAppStatusTranslation('RE_AUTHORIZE')"></rosie-button>
      </div>
    </div>
  </div>
  <rosie-alert *ngIf="showSuccessMessage" [customClass]="'mb-0'"
    [message]="getAppStatusTranslation('DESCRIPTION', true)" [type]="'success'" class="mt-5"></rosie-alert>
  <rosie-alert *ngIf="app?.isPending && !app?.inProgress" [message]="getAppStatusTranslation('INCOMPLETE_WARNING')"
    [type]="'warning'" class="mt-5" [customClass]="'mb-0'"></rosie-alert>
</div>
<div *ngIf="app?.isInstalled" class="modal-action-line with-border">
  <rosie-button (click)="openUninstallModal()" class="d-block" [customClass]="'narrow danger slim'"
    [translationKey]="'APP.UNINSTALL'">
  </rosie-button>
  <ng-container *ngIf="settingsModalActionConfig">
    <rosie-button (click)="settingsModalActionConfig.actionFunc()"
      *ngIf="(app?.hasAnyEditableFieldsOnceInstalled || isReauthorizeAllowed) && !hasHiddenEdit"
      [customClass]="'secondary narrow'" [fullWidth]="true" [translationKey]="settingsModalActionConfig.translationKey"
      class="d-block">
      <i [ngClass]="settingsModalActionConfig.iconClass"></i>
    </rosie-button>
  </ng-container>
</div>
<div *ngIf="app?.isPending" class="modal-action-line with-border">
  <rosie-button (click)="close()" class="d-block" [customClass]="'secondary narrow'" [translationKey]="'COMMON.CANCEL'">
  </rosie-button>
  <rosie-button (click)="verify.emit()" class="d-block" [customClass]="'narrow slim'" [inProgress]="app?.inProgress"
    [translationKey]="getAppStatusTranslation('SETTINGS_ACTION')">
  </rosie-button>
</div>