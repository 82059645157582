<div *ngIf="showDetails" class="app-wrapper" [ngClass]="app?.key">
  <div class="heading-section d-flex justify-content-between align-items-center gap-1 flex-wrap">
    <div class="d-flex align-items-center">
      <h3>{{ app?.name | translate }}</h3>
      <div *ngIf="app?.isInstalled" class="status-tag installed d-flex align-items-center">
        <img class="icon" [src]="'assets/images/install.svg'" />
        <span>{{ 'APP.INSTALLED' | translate }}</span>
      </div>
      <div *ngIf="app?.isPending" class="status-tag pending d-flex align-items-center">
        <div class="pending icon"></div>
        <span>{{ 'APP.PENDING' | translate }}</span>
      </div>
    </div>
    <div (click)="action()" [class]="actionIcon" [dataHook]="actionTranslationKey"
      class="d-flex justify-content-center align-items-center pointer action">
      <img class="icon" [src]="iconUrl" />
      <span class="text">{{ actionTranslationKey | translate | uppercase }}</span>
    </div>
  </div>
  <div class="app-separator mb-6"></div>
  <div class="row">
    <div class="col-auto description-section">
      <div class="logo-wrapper position-relative">
        <img [src]="getLogo(app)">
      </div>
      <div *ngIf="app?.statusMessage" class="status-tile d-flex align-items-center p-3 mt-6">
        <img src="assets/images/information-circle.svg" />
        <div>
          <span *ngFor="let msg of app?.statusMessage" [ngClass]="msg.customClass">
            <ng-container *ngIf="msg.url else simpleText">
              <a (click)="navigateTo(msg.url)">{{ msg.title | translate }}</a>&nbsp;
            </ng-container>
            <ng-template #simpleText>{{ msg | translate }}&nbsp;</ng-template>
          </span>
        </div>
      </div>
      <div [innerHTML]="appDescriptionKey | translate:{value: settingUrl}" class="description"></div>
    </div>
    <div class="col metadata-section">
      <div class="item d-flex flex-column">
        <span class="label">{{ 'APP.DETAILS' | translate }}</span>
        <div class="content d-flex align-items-center">
          <span>{{ 'APP.LAST_UPDATED' | translate:{value: lastUpdated} }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-smart-modal #settingsModal (onAnyCloseEventFinished)="handleSettingsModalClose()" [closable]="true"
  identifier="{{AppModalEnum.Settings}}">
  <rosie-app-settings (install)="install()" (update)="update()" (verify)="verify()" [app]="app"
    [modalClosed]="settingsModalClosedSubject">
  </rosie-app-settings>
</ngx-smart-modal>
<ngx-smart-modal #updateModal [closable]="true" [customClass]="'install-update-modal'"
  identifier="{{AppModalEnum.Update}}">
  <rosie-app-update (install)="install(true)" (updateAppProfile)="updateAppProfile($event)" [app]="app" [form]="form">
    <ng-content></ng-content>
  </rosie-app-update>
</ngx-smart-modal>
<ngx-smart-modal #installModal [closable]="true" (onOpen)="initCredentialsFlow()" [customClass]="'install-update-modal'"
  (onAnyCloseEvent)="closeInstallModal()" identifier="{{AppModalEnum.Install}}">
  <rosie-app-install (install)="install()" [app]="app" [form]="form">
    <ng-container *ngIf="app?.hasCredentialsFlow">
      <div *ngFor=" let field of app?.setupData?.formFields; let last=last; let first=first"
        [ngClass]="{'mb-2': !last, 'mt-5': first}">
        <rosie-field-value-copy [labelKey]="'Api Key'" [value]="field.value" *ngIf="field.value"
          [showShimmer]="!field.value"></rosie-field-value-copy>
      </div>
      <div class="d-flex mt-5 gap-2">
        <rosie-button (click)="close()" [customClass]="'secondary d-block'" [translationKey]="'COMMON.CANCEL'"
          class="d-block btn-install">
        </rosie-button>
        <rosie-button (click)="verify()" [fullWidth]="true" [inProgress]="app?.inProgress"
          [translationKey]="'APP.VERIFY'" class="d-block btn-install">
        </rosie-button>
      </div>
    </ng-container>
    <ng-content></ng-content>
  </rosie-app-install>
</ngx-smart-modal>

<ngx-smart-modal #statusModal [closable]="true" identifier="{{AppModalEnum.Status}}">
  <rosie-app-status (install)="install()" (updateAppProfile)="updateAppProfile($event)" [app]="app" [form]="form">
  </rosie-app-status>
</ngx-smart-modal>
<ngx-smart-modal #uninstallModal [closable]="true" identifier="{{AppModalEnum.Uninstall}}">
  <rosie-app-uninstall (uninstall)="uninstall()" [app]="app"></rosie-app-uninstall>
</ngx-smart-modal>
<ng-template #form let-form="form" let-formFields="formFields" let-formOptions="formOptions">
  <div *ngFor="let field of form?.controls | keyvalue: unsorted; let last = last; let first = first;"
    [ngClass]="{'mt-3': first}" class="{{ formOptions?.[field.key]?.customClass || 'mb-2' }}">
    <div *ngIf="getFieldType(formFields, field.key) !== AppFieldType.Array">
      <ng-container
        [ngTemplateOutletContext]="{ form: form, field: field, type: getFieldType(formFields, field.key), options: formOptions?.[field.key] }"
        [ngTemplateOutlet]="formField">
      </ng-container>
    </div>

    <div *ngIf="getFieldType(formFields, field.key) === AppFieldType.Array">
      <div class="array-form-wrapper">
        <rosie-alert *ngIf="formOptions?.[field.key]?.description"
          [customClass]="'status-tile d-flex align-items-center p-3 mb-2'"
          [innerHtml]="formOptions?.[field.key]?.description.message | translate" [type]="'info'"></rosie-alert>
        <div
          *ngFor="let arrayField of form.get(field.key)?.controls | keyvalue: unsorted; let i = index; let first = first;"
          class="mb-2 position-relative">
          <ng-container
            [ngTemplateOutletContext]="{ form: form?.get(field.key), field: arrayField, type: getFieldArrayType(formFields, field.key) }"
            [ngTemplateOutlet]="formField">
          </ng-container>
          <div (click)="removeFormField(field.key, i)" *ngIf="!first && formOptions?.[field.key]?.actionRemove"
            class="remove-field" title="{{ formOptions?.[field.key]?.actionRemove.message | translate }}">
            <div class="remove-field-icon-wrapper">
              <span class="remove-field-icon"></span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="formOptions?.[field.key]?.actionAdd" class="add-field">
        <a (click)="addFormField(field.key)" class="link">{{
          formOptions?.[field.key]?.actionAdd?.message |
          translate
          }}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #formField let-field="field" let-form="form" let-options="options" let-type="type">
  <rosie-alert *ngIf="options?.description" [customClass]="'status-tile d-flex align-items-center p-3 mb-1'"
    [innerHtml]="options?.description.message | translate" [type]="'info'"></rosie-alert>
  <div [hidden]="options?.hidden" [ngSwitch]="type">
    <div *ngSwitchCase="AppFieldType.Text">
      <rosie-text *ngIf="!app?.isFieldReadOnly(field.key)" [control]="form?.get(field.key)"
        [labelTranslationKey]="getFieldTranslation(field.value?.['fieldName'] || field.key)"
        [tooltip]="getFieldAdditionalInformation(field.key)"></rosie-text>
      <rosie-field-value-copy
        *ngIf="app?.isFieldReadOnly(field.key) && app?.hasFieldAction(field.key, AppFormFieldActionEnum.Copy)"
        [customClass]="'status-tile mb-1 p-3'" [labelKey]="getFieldTranslation(field.value?.['fieldName'] || field.key)"
        [value]="form?.get(field.key)?.value"></rosie-field-value-copy>
    </div>
    <div *ngSwitchCase="AppFieldType.Select">
      <rosie-select-input *ngIf="getFieldDisplayType(field.key) === AppFieldDisplayType.Default"
        [appendTo]="'.install-update-modal-content'" [control]="form?.get(field.key)"
        [customClass]="'select-full-arrow-sm'" [dataHook]="form?.get(field.key)"
        [options]="app.getFieldSelectOptions(field.value?.['fieldName'] || field.key)"
        [placeholderTranslationKey]="'APP.FIELDS.SELECT_OPTION' | translate:{value: getFieldTranslation(field.key)}">
      </rosie-select-input>
      <div *ngIf="getFieldDisplayType(field.key) === AppFieldDisplayType.Options">
        <div class="text-edit-wrapper-label text-uppercase">
          {{ getFieldAdditionalInformation(field.key) }}
        </div>
        <div class="radio-group-field">
          <rosie-radio-group (valueChanged)="handleFieldSelectOptionChanged(field.key, $event)"
            [control]="form?.get(field.key)" [dataHook]="form?.get(field.key)"
            [options]="app.getFieldSelectOptions(field.value?.['fieldName'] || field.key)"
            [title]="getFieldTranslation(field.key)"></rosie-radio-group>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="AppFieldType.Boolean">
      <div class="boolean-field">
        <div class="title-text-switch-wrapper">
          <div>
            <div class="title text-primary pr-2">{{
              getFieldTranslation(field.value?.['fieldName'] ||
              field.key)
              }}
            </div>
          </div>
          <rosie-switch [control]="form?.get(field.key)" [dataHook]="field?.key + '-toggle'" class="d-flex">
          </rosie-switch>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="AppFieldType.File">
      <rosie-file-field (fileUploaded)="handleFileUpload($event)" [control]="form?.get(field.key)"
        [customClass]="!!form?.get(field.key)?.errors?.['incorrect'] ? 'has-errors' : ''"
        [fieldData]="getFileFieldData(field.key)" [isEditable]="isFieldEditable(field.key)"
        [tooltip]="getFieldAdditionalInformation(field.key)">
      </rosie-file-field>
    </div>

    <rosie-validation-error [show]="form?.get(field.key)?.invalid && form?.get(field.key)?.touched"
      [translationAttributes]="{value: getFieldTranslation(field.value?.['fieldName'] || field.key)}"
      [translationKey]="getFieldValidationErrorTranslation(field.key)">
    </rosie-validation-error>
    <div *ngIf="getHelpTextPerField(field.value?.['fieldName'] || field.key)" class="text-secondary mt-1">
      {{ getHelpTextPerField(field.value?.['fieldName'] || field.key) }}
    </div>
  </div>
</ng-template>