<div class="modal-title text-left">{{ getAppStatusTranslation('HEADING', true) }}</div>
<div class="modal-subtitle with-border"></div>
<div class="modal-content">
  <div [ngClass]="{ 'mb-4': showPostInstallFields }" class="modal-description dark">
    {{
    showPostInstallFields ? getAppStatusTranslation('DESCRIPTION_WITH_SETTINGS', true) :
    getAppStatusTranslation('DESCRIPTION', true)
    }}
  </div>
  <div *ngIf="showPostInstallFields" class="settings-form">
    <div class="app-section-heading-wrapper mb-4">
      <div class="app-section-heading">{{ getAppStatusTranslation('SETTINGS_HEADING', true) }}</div>
    </div>
    <ng-container
      [ngTemplateOutletContext]="{ form: app?.setupData?.form, formFields: app?.setupData?.formFields, formOptions: formOptions }"
      [ngTemplateOutlet]="form">
    </ng-container>
  </div>
  <rosie-alert *ngIf="app?.isPending" [message]="getAppStatusTranslation('WARNING')" [type]="'warning'" class="mt-5"
    [customClass]="'mb-0'">
  </rosie-alert>
</div>
<div class="modal-action-line with-border">
  <rosie-button (click)="modal.closeAll()" *ngIf="!app?.hasPostInstallFields" [translationKey]="'APP.STATUS.OK'">
  </rosie-button>
  <ng-container *ngIf="app?.hasPostInstallFields">
    <rosie-button (click)="modal.closeAll()" [customClass]="'secondary narrow'"
      [translationKey]="getAppStatusTranslation('SETTINGS_SKIP', true)">
    </rosie-button>
    <rosie-button (click)="updateModalActionConfig.actionFunc()"
      [translationKey]="updateModalActionConfig.translationKey" [customClass]="'narrow slim'">
    </rosie-button>
  </ng-container>
</div>