import { Component, OnInit, ViewChild } from '@angular/core';
import { User, UserService } from '../core/user/user.service';
import { objectPropertiesToArray } from '@rallycommerce/common/utils';
import { Breakpoint, CaseType, convertCamelCaseToSnakeCase, fromMediaQuery } from '../core/helpers/utils';
import { environment } from '../../environments/environment';
import { AgentService } from '../core/agent/agent.service';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { SubscriptionService } from '../core/subscription/subscription.service';
import { GuidedSetupService } from '../guided-setup/guided-setup.service';
import { DataStorage } from '../core/data/data-storage';

@Component({
  selector: 'rosie-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss']
})
export class NavSidebarComponent implements OnInit {
  @ViewChild('subscriptionModal') subscriptionModal: NgxSmartModalComponent;

  isMobileVersion: boolean = false;
  isExpanded: boolean = false;
  navSidebarItems: NavSidebar[];
  private hoverState: { [key: string]: boolean } = {};

  constructor(private user: UserService, private agent: AgentService, private subscription: SubscriptionService, private modal: NgxSmartModalService, private setup: GuidedSetupService) {
  }

  ngOnInit() {
    this.navSidebarItems = this.generateNavSidebarItems();
    fromMediaQuery(Breakpoint.UpToMedium).subscribe(matches => {
      this.isMobileVersion = matches;
      const hasLoginAction = DataStorage.persistent().get('login')
      if (this.isMobileVersion && hasLoginAction) {
        this.isExpanded = true;
        DataStorage.persistent().remove('login');
      }
    });
    this.subscription.open.subscribe(() => this.subscriptionModal.open());
    this.subscription.created.subscribe(() => this.modal.close('subscriptionModal'));
    this.subscription.close.subscribe(() => this.modal.close('subscriptionModal'));

  }

  get showCreditCardAlert(): boolean {
    const isSetupCompletedLegacy = this.setup.isInitialSetupCompleted && this.agent.configuration && !this.agent.configuration?.hasOwnProperty('talkToRosieCompleted');
    const isSetupCompleted = this.setup.isInitialSetupCompleted && this.agent.configuration && this.agent.configuration?.hasOwnProperty('talkToRosieCompleted') && this.setup.isTalkToRosieCompleted;
    const hasCreditCardAdded = (isSetupCompletedLegacy || isSetupCompleted) && !this.subscription.hasCreditCardAdded;
    const isPastDue = this.subscription.isPastDue;
    return hasCreditCardAdded || isPastDue;
  }


  get userInformation(): User {
    return this.user.userData;
  }

  get isProduction(): boolean {
    return environment.name === 'production' || environment.name === 'sandbox';
  }

  get isAgentPhoneNumberVisible(): boolean {
    return this.agent.isInitialSetupCompleted && this.isAccountLimited() || !this.isAccountLimited();
  }

  get businessTitle(): string {
    return this.user.activeBusiness?.title;
  }

  get hasCreditCardOnFile(): boolean {
    return this.subscription.hasCreditCardAdded;
  }

  isAccountLimited() {
    return this.user.isAccountLimited
  }

  onMouseEnter(key: NavSidebarItem): void {
    this.hoverState[key] = true;
  }

  onMouseLeave(key: NavSidebarItem): void {
    this.hoverState[key] = false;
  }

  isHovered(key: NavSidebarItem): boolean {
    return !!this.hoverState[key];
  }

  handleSelection() {
    if (this.isMobileVersion) {
      this.isExpanded = false;
    }
  }

  toggleMenu() {
    this.isExpanded = !this.isExpanded;
  }

  getTranslationKey(key: string): string {
    return `SIDEBAR.${key.replace(/[^a-zA-Z0-9 ]/g, '')?.toUpperCase()}`;
  }

  getKebabCaseKey(key: string): string {
    return convertCamelCaseToSnakeCase(key, CaseType.LowerCase).replace(/_/g, '-');
  }

  getIcon(key: NavSidebarItem): string {
    return icons[key];
  }

  handleSubscriptionCancel() {
    this.subscription.reset.next(true);
  }

  handleSubscriptionOpen() {
    this.subscription.initialize.next(true);
  }

  private generateNavSidebarItems(): NavSidebar[] {
    return objectPropertiesToArray(NavSidebarItem).map((key: NavSidebarItem) => {
      return { key };
    });
  }
}

interface NavSidebar {
  key: NavSidebarItem;
  additionalIcon?: string;
  tooltipTranslationKey?: string;
}

enum NavSidebarItem {
  Setup = 'setup',
  Calls = 'calls',
  Settings = 'settings',
  Integrations = 'integrations',
  Account = 'account'
}

const icons = {
  [NavSidebarItem.Setup]: 'heroDocumentCheck',
  [NavSidebarItem.Calls]: 'heroInboxArrowDown',
  [NavSidebarItem.Settings]: 'heroCog6Tooth',
  [NavSidebarItem.Integrations]: 'heroSquaresPlus',
  [NavSidebarItem.Account]: 'heroUserCircle'
}