<div class="agent-process">
    <ngb-progressbar class="bar agent" type="success" [value]="progressValue" />
    <div *ngFor="let step of agentSteps; let last = last; let i = index;" class="d-flex align-items-center step"
        [ngClass]="{'complete': isAgentStepComplete(step), 'last': last}">
        <div class="d-flex align-items-center justify-content-center circle-wrapper">
            <circle-progress [percent]="currentStep !== step ? 0 : stepProgressValue" [maxPercent]="100" [radius]="13"
                [innerStrokeWidth]="4" [outerStrokeWidth]="4" *ngIf="!isAgentStepComplete(step)"></circle-progress>
            <span class="process-circle rounded-circle d-flex justify-content-center align-items-center"
                *ngIf="isAgentStepComplete(step)">
                <fa-icon class="custom-icon" [icon]="faCheck" size="xs"></fa-icon>
            </span>
        </div>
        <span class="step-text">{{getAgentStepTranslationKey(step) | translate}}</span>
    </div>
    <rosie-alert *ngIf="errorMessage" [innerHtml]="errorMessage" [type]="'success'"></rosie-alert>
</div>