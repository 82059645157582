import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SessionGuard } from './core/guards/session.guard';
import { SubscriptionResolver } from './core/subscription/subscription.resolver';
import { AgentResolver } from './core/agent/agent.resolver';
import { UserResolver } from './core/user/user.resolver';
import { RedirectGuard } from './onboarding/redirect.guard';

const routes: Routes = [
  {
    path: '', canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        resolve: {
          user: UserResolver,
          subscription: SubscriptionResolver,
          agent: AgentResolver,
        },
        component: DashboardComponent,
        children: [
          { path: '', redirectTo: 'calls', pathMatch: 'full' },
          { path: 'setup', loadChildren: () => import('./guided-setup/guided-setup.module').then(m => m.GuidedSetupModule) },
          { path: 'calls', loadChildren: () => import('./calls/calls.module').then(m => m.CallsModule) },
          { path: 'integrations', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
          { path: 'settings', loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule) },
          { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
        ]
      }
    ]
  },
  {
    path: 'register/agent',
    canActivate: [RedirectGuard],
    component: DashboardComponent,

  },
  {
    path: 'register/preview',
    canActivate: [RedirectGuard],
    component: DashboardComponent,

  },
  {
    path: 'register/user',
    canActivate: [RedirectGuard],
    component: DashboardComponent,
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [SessionGuard] },
  { path: 'register', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule) },
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  {
    path: 'password-reset', loadChildren: () => import('./password-update/password-update.module').then(m => m.PasswordUpdateModule),
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
