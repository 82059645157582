import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {Currency} from '@rallycommerce/common/utils';

/**
 *
 * A pipe to transform input number to a currency string, i.e. formatted according to locale US rules that determine group sizing and separator (decimal-point character, thousand separator).
 * Additionaly, adds currency symbol to a currency string based on `currencyConfig.symbolPosition` (`before` or `after`).
 *
 */
@Pipe({
  name: 'rosieCurrency',
  pure: false
})
@Injectable({
  providedIn: 'root'
})
export class RosieCurrencyPipe implements PipeTransform {
  private currencyConfig: Currency = {
    code: 'USD', symbol: '$', symbolPosition: 'before', decimalDigits: 2, decimalSeparator: '.', thousandsSeparator: ','
  };

  constructor() {
  }

  transform(value: number | string | null, showCurrencyCode = false, excludeDecimalDigits = false, suffix = ''): string {
    let formattedAmountWithCurrency = '';
    if (value === null) {
      formattedAmountWithCurrency = this.currencyConfig?.symbolPosition === 'before' ? this.currencyConfig?.symbol + '--' : '--' + this.currencyConfig?.symbol;
    } else {
      if (typeof value !== 'number') {
        value = Number.parseFloat(value);
      }
      if (!Number.isNaN(value)) {
        const [integer, decimal] = value.toFixed(this.currencyConfig?.decimalDigits).split('.');
        let formattedInteger = this.reverseString(integer).match(new RegExp('.{1,3}', 'g')).join(this.currencyConfig?.thousandsSeparator);
        formattedInteger = this.reverseString(formattedInteger);
        const formattedAmount = excludeDecimalDigits ? (formattedInteger + suffix) : (formattedInteger + this.currencyConfig?.decimalSeparator + decimal);
        formattedAmountWithCurrency = this.currencyConfig?.symbolPosition === 'before' ?
          this.currencyConfig?.symbol + formattedAmount :
          formattedAmount + this.currencyConfig?.symbol;
      }
    }
    return showCurrencyCode ? formattedAmountWithCurrency + ' ' + this.currencyConfig?.code : formattedAmountWithCurrency;
  }

  private reverseString(initialString: string): string {
    return initialString.split('').reverse().join('');
  }

}
