import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class QueryParamsService {
  queryParams: URLSearchParams = new URLSearchParams(window.location.search);

  constructor() {
  }

  /**
   * Extracts data from query params
   *
   * @param keys names of the query params to extract
   * @param remove if true removes the query params from the store and the url
   */
  get(keys: string[], remove = false): Record<string, any> | null {
    const params: Record<string, any> = {};
    this.queryParams.forEach((par: string, key: string) => {
      const [mainKey, subKey] = key.split('.');
      if (keys.includes(mainKey)) {
        if (subKey) {
          if (!params[mainKey]) {
            params[mainKey] = {};
          }
          params[mainKey][subKey] = par;
        } else {
          params[mainKey] = par;
        }
      }
    });
    if (remove) {
      this.remove(keys);
    }
    return Object.keys(params).length > 0 ? params : null;
  }

  /**
   * Returns value of a single query param
   *
   * @param keys name of the query param to return
   * @param remove if true removes the query param from the store and the url
   */
  getByKey(key: string, remove = false): any {
    return this.get([key], remove)?.[key];
  }

  /**
   * Remove query params from the store
   *
   * @param params names of the query params to remove
   * @param removeFromUrl if false keep the query param in the url
   */
  remove(params: string[], removeFromUrl = true): void {
    params?.forEach(param => this.queryParams.delete(param));
    const currentQueryParams = new URLSearchParams(window.location.search);
    if (removeFromUrl && params?.some(param => currentQueryParams.has(param))) {
      params.forEach(param => currentQueryParams.delete(param));
      const newQueryParams = currentQueryParams ? `?${currentQueryParams.toString()}` : '';
      window.history.replaceState(null, '', window.location.origin + window.location.pathname + newQueryParams + window.location.hash);
    }
  }
}
