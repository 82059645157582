import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppsRoutingModule } from './apps-routing.module';
import { AppsComponent } from './apps.component';
import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './app/app.component';
import { DateAgoPipe } from '../shared/pipes/date-ago.pipe';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AppSettingsComponent } from './app/app-modals/app-settings/app-settings.component';
import { AppUpdateComponent } from './app/app-modals/app-update/app-update.component';
import { AppInstallComponent } from './app/app-modals/app-install/app-install.component';
import { AppStatusComponent } from './app/app-modals/app-status/app-status.component';
import { AppUninstallComponent } from './app/app-modals/app-uninstall/app-uninstall.component';
import { FileFieldComponent } from './app/file-field/file-field.component';
import { heroArrowUturnLeft } from '@ng-icons/heroicons/outline';
import { NgIconsModule } from '@ng-icons/core';


@NgModule({
  declarations: [
    AppsComponent,
    AppComponent,
    AppSettingsComponent,
    AppUpdateComponent,
    AppInstallComponent,
    AppStatusComponent,
    AppUninstallComponent,
    FileFieldComponent
  ],
  imports: [
    CommonModule,
    AppsRoutingModule,
    SharedModule,
    NgbModule,
    NgbTooltipModule,
    NgIconsModule.withIcons({
      heroArrowUturnLeft
    }),
  ],
  exports: [],
  providers: [DateAgoPipe]
})
export class AppsModule {
}
