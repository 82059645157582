import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CoreModule } from './core/core.module';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgProgressModule } from 'ngx-progressbar';
import { AccountModule } from './account/account.module';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { browserTracingIntegration, init, TraceService } from '@sentry/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FinalizeComponent } from './finalize/finalize.component';
import { CustomErrorHandler } from './core/error-handler';
import { Router } from '@angular/router';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { NgIconsModule, provideNgIconsConfig } from '@ng-icons/core';
import { heroArrowRightStartOnRectangle, heroCheck, heroCog6Tooth, heroDocumentCheck, heroInboxArrowDown, heroSquaresPlus, heroUserCircle, heroUser, heroPencilSquare, heroBars3, heroXMark, heroArrowUturnLeft, heroGift, heroCreditCard, heroArrowUpRight } from '@ng-icons/heroicons/outline';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar.component';
import { UsageStatusComponent } from './nav-sidebar/usage-status/usage-status.component';
import { ReferFriendComponent } from './nav-sidebar/refer-friend/refer-friend.component';
import { heroChatBubbleOvalLeftSolid, heroExclamationTriangleSolid } from '@ng-icons/heroicons/solid';
import { CreditCardAlertComponent } from './nav-sidebar/credit-card-alert/credit-card-alert.component';

if (environment.name !== 'local') {
  init({
    dsn: 'https://04f6fc85a282e1d0fbb584c9b774cea8@o504920.ingest.us.sentry.io/4507338457153536',
    environment: environment.name,
    integrations: [browserTracingIntegration()],
    tracePropagationTargets: ['localhost', /(\.heyrosie\.)+([a-z A-Z 0-9])+(\/api)+/],
    tracesSampleRate: 1,
    release: environment.sentryRelease,
  });
  CustomErrorHandler.isSentryEnabled = true;
}

export function translateLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AdminComponent,
    NotFoundComponent,
    DashboardComponent,
    FinalizeComponent,
    NavSidebarComponent,
    UsageStatusComponent,
    ReferFriendComponent,
    CreditCardAlertComponent
  ],
  bootstrap: [AdminComponent], imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AdminRoutingModule,
    CoreModule,
    NgProgressModule.withConfig({ color: '#9648bd', thick: true, spinner: false }),
    NgProgressRouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translateLoaderFactory),
        deps: [HttpClient]
      }
    }),
    NgxSmartModalModule.forRoot(),
    NgbModule,
    NgbTooltipModule,
    AccountModule,
    SharedModule,
    NgIconsModule.withIcons({
      heroUser, heroDocumentCheck, heroInboxArrowDown, heroCog6Tooth, heroSquaresPlus, heroUserCircle, heroArrowRightStartOnRectangle, heroCheck, heroPencilSquare, heroBars3, heroXMark,
      heroArrowUturnLeft, heroGift, heroChatBubbleOvalLeftSolid, heroCreditCard, heroArrowUpRight, heroExclamationTriangleSolid
    }),
  ],
  providers: [
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    DatePipe,
    provideNgIconsConfig({
      size: '20px',
      color: '#9487A0',
      strokeWidth: 2
    }),
    { provide: APP_BASE_HREF, useValue: '/admin' }
  ]
})
export class AdminModule {
}
