import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {AppsService} from '../../../apps.service';
import {App, AppModal} from '../../app';

@Component({
  selector: 'rosie-app-uninstall',
  templateUrl: './app-uninstall.component.html',
})
export class AppUninstallComponent {

  @Input() app: App;

  @Output() uninstall = new EventEmitter<void>();

  constructor(private apps: AppsService, private modal: NgxSmartModalService) {
  }

  getTranslationWithAppName(translationKey: string, showPrefix = false): string {
    return this.apps.buildTranslation(translationKey, {app: this.app, showPrefix});
  }

  getAppSpecificTranslation(translationKey: string, showPrefix = false): string {
    return this.apps.buildTranslation(translationKey, {app: this.app, useAppKey: true, showPrefix});
  }

  close(): void {
    this.modal.getModal(AppModal.Uninstall).close();
  }

}
