<div class="d-flex justify-content-between align-items-center">
  <div class="field-label">
    <span>{{ label }}</span>
    <span *ngIf="helpText" class="d-block description">{{ helpText }}</span>
  </div>
  <div class="d-flex align-items">
    <span *ngIf="hasRestrictedBehaviour" class="mr-1 d-flex align-items-center">
      <ng-container *ngTemplateOutlet="dots"></ng-container>
    </span>
    <ng-container *ngIf="hasIconValue else fieldValue">
      <img [src]="'assets/images/'+icon" />
    </ng-container>
    <ng-template #fieldValue>
      <span *ngIf="(isRevealEnabled || !hasRevealBehaviour); else dots" class="field-value">{{ getValue() }}</span>
      <span (click)="toggleReveal()" *ngIf="hasRevealBehaviour" class="ml-3 pointer">{{ actionText }}</span>
    </ng-template>
  </div>
</div>
<ng-template #dots>
  <div class="d-flex align-items-center">
    <div *ngFor="let dot of mockedDotsLength" class="tiny-dot">
    </div>
  </div>
</ng-template>