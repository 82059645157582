import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataStorage } from '../data/data-storage';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../helpers/rest-utils';
import { map, tap } from 'rxjs/operators';
import { getPrefixedKey } from '../helpers/utils';
import { Business, BusinessData } from '../business/business';
import { Feature, Subscription, SubscriptionPlanName } from '../subscription/subscription';
import { buildBusiness } from '../business/business-mapper';
import { merge } from '@rallycommerce/common/utils';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../auth/authentication-service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  persistentStorage: DataStorage = DataStorage.persistent();

  constructor(private http: HttpClient, private auth: AuthenticationService) {
  }

  get userData(): User {
    return this.persistentStorage.get(getPrefixedKey('user')) || {};
  }

  get activeBusiness(): Business {
    return this.userData.businesses?.[0];
  }

  get name(): string {
    return this.userData.name;
  }

  get email(): string {
    return this.userData.email;
  }

  get id(): string {
    return this.userData.id;
  }

  get subscription(): Subscription {
    return this.userData.subscription;
  }

  get agent(): any {
    return this.userData.agent;
  }

  get hasCreditCardOnFile(): boolean {
    return this.userData.subscription?.isCreditCardAdded;
  }

  get isAccountLimited(): boolean {
    const userCreatedDate = new Date(this.userData?.createdAt);
    const releaseDate = new Date('2024-11-06T10:00:00+01:00');
    const featureReadyForTestingDate = new Date('2024-10-24T00:00:00Z');
    return (userCreatedDate >= featureReadyForTestingDate && environment.name !== 'production') || (userCreatedDate >= releaseDate && environment.name === 'production');
  }

  get hasSpamEnabled(): boolean {
    return true;
  }

  get hasAppointmentsEnabled(): boolean {
    return this.userData.subscription?.features?.includes(Feature.Appointments);
  }

  get hasTransferCallsEnabled(): boolean {
    return this.userData.subscription?.features?.includes(Feature.TransferCalls);
  }

  get hasTrainingFilesEnabled(): boolean {
    return this.userData.subscription?.features?.includes(Feature.TrainingFiles);
  }

  get hasTrainingFilesVisible(): boolean {
    return this.hasTrainingFilesEnabled || this.subscription?.planName === SubscriptionPlanName.Growth;
  }

  refreshUser(user?: User) {
    if (user) {
      user.businesses = user.businesses?.map(buildBusiness);
      const refreshedUser = merge(this.userData, user);
      refreshedUser.isActive = true;
      this.persistentStorage.set(getPrefixedKey('user'), refreshedUser);
    }
  }

  getUser(): Observable<User> {
    return this.http.get(`${getApiUrl()}user`)
      .pipe(map((res: any) => res.data), tap((user: User) => this.refreshUser(user)));
  }

  createBusiness(data: BusinessData): Observable<Business> {
    const urls = data.websiteUrls.map((url) => {
      return url.includes('https://') ? url : `https://${url}`;
    });
    const structuredData = { title: data.title, urls, additionalProperties: { industry: data.industry } };
    return this.http.post(`${getApiUrl()}businesses`, structuredData)
      .pipe(map((res: any) => res?.data), tap((business: Business) => {
        const businesses = buildBusiness(business);
        const user = this.userData;
        user.businesses = [businesses];
        this.refreshUser(user);
      }));
  }

  logout() {
    this.auth.revoke();
  }
}

export interface User {
  name: string;
  email: string;
  isActive: boolean;
  id?: string;
  businesses: Business[];
  agent: any;
  subscription?: Subscription;
  createdAt: string;
  campaign?: {
    rrf: string;
    rcmp: string;
  }
}
