import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Breakpoint, fromMediaQuery } from './core/helpers/utils';

@Component({
  selector: 'rosie-root',
  templateUrl: './admin.component.html',
  styleUrls: []
})
export class AdminComponent implements OnInit {
  isMobileVersion: boolean = false;
  title = 'admin';

  constructor(translate: TranslateService, private router: Router) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    fromMediaQuery(Breakpoint.UpToMedium).subscribe(matches => this.isMobileVersion = matches);
  }

  get isSandboxEnvironment(): boolean {
    return environment.name === 'sandbox';
  }

  get isLoginPage(): boolean {
    return this.router.url === '/login';
  }

}

export enum RouteUrl {
  Integrations = 'integrations',
  UserProfile = 'user-profile',
  Calls = 'calls',
  Settings = 'settings',
}
