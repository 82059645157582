import { Business } from '../business/business';
import { PhoneNumber } from '../phone/phone';

export interface AgentConfigOptions {
  toneOptions: string[];
  voiceOptions: string[];
  languages: { value: string, label: string }[];
}

export interface AgentData {
  title: string;
  configuration: {
    voice: string,
    tone?: string;
  };
}

export interface Agent {
  id?: string;
  businessId?: number;
  title?: string;
  configuration?: Configuration;
  phoneNumbers?: PhoneNumber[];
  business?: Business;
  externalId?: string;
  isAgentGenerated?: boolean;
  trialSecondsRemaining?: number;
  bookingMessage?: Booking;
  blockedPhoneNumbers?: PhoneNumber[];
  sms: boolean;
  isTrainingInProgress?: boolean;
  greeting?: {
    default?: string;
    legalDisclaimer?: string;
  }
}

export interface Configuration {
  tone?: string;
  hasAppointmentsEnabled?: boolean;
  hasTransferCallsEnabled?: boolean;
  hasCustomQuestionsForNotesEnabled?: boolean;
  hasCustomSummaryEnabled?: boolean;
  customSummary?: string;
  hasCustomGreetingEnabled?: boolean;
  customGreeting?: string;
  hasLegalDisclaimerEnabled?: boolean;
  legalDisclaimer?: string;
  appointmentLengthMinutes?: number;
  hasBlock_1800NumbersEnabled?: boolean;
  hasSmartSpamDetectionEnabled?: boolean;
  callFinishedNotificationChannel?: CallFinishedNotificationChannel[];
  initialSetupCompleted?: boolean;
  languages?: string[];
  talkToRosieCompleted?: boolean;
  numberForwardingEnabled?: boolean;
}

export interface TrainingFaq {
  question: string;
  answer: string;
  id?: string;
}

export interface CustomQuestion {
  question: string;
  id?: string;
}

export enum CustomQuestionType {
  Booking = 'booking',
  Note = 'note'
}
export interface TrainingFile {
  originalFileName: string;
  id: string;
}

export interface Booking {
  message: string;
  url: string;
  id?: string;
}

export enum CallFinishedNotificationChannel {
  Email = 'email',
  SMS = 'sms'
}