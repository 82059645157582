import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

export function getApiUrl(): string {
  return `//${environment.api.baseUrl}/api/`;
}

export function getHeaders(): HttpHeaders {
  return new HttpHeaders();
}

