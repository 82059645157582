import { UntypedFormGroup } from '@angular/forms';
import { FieldBehaviour as AppFieldBehaviour } from 'src/app/shared/display-field/display-field.component';
import { Convert, replaceWith } from '@rallycommerce/common/utils';
import { SelectOption } from 'src/app/shared/select-input/select-input.component';
import { environment } from 'src/environments/environment';
import { LinkConfig } from '../../core/helpers/utils';

export class DefaultApp implements App {
  readonly key: AppKey;
  readonly referenceId: string;
  readonly name: string;
  readonly id: string;
  type: AppType;
  status: AppStatus | string = '';
  logo: string;
  developer?: string = 'Rosie';
  lastUpdated?: string;
  details: AppDetails;
  setupData: AppSetup;
  summary: string;
  description: string;
  inProgress = false;
  isIntegrating = false;
  isPreferredPartner?: boolean = false;
  categories: AppCategory[];
  category: AppCategory = AppCategory.Default;
  statusMessage?: (LinkTextConfig | string)[] | string = '';
  error: boolean;
  errorMessage = '';
  requiredParams: string[];

  constructor(referenceId: string, name: string, id: string) {
    this.referenceId = referenceId;
    this.key = replaceWith(referenceId, Convert.CamelCase) as AppKey;
    this.name = name;
    this.id = id;
  }

  get isNotInstalled(): boolean {
    return this.status === null;
  }

  get isInstalled(): boolean {
    return this.status === AppStatus.Installed;
  }

  get hasAllowUninstall(): boolean {
    return !this.hasCredentialsFlow;
  }

  get isPending(): boolean {
    return this.status === AppStatus.Pending;
  }

  get hasExternalFlow(): boolean {
    return this.setupData.action === AppAction.OAuth;
  }

  get hasFormFlow(): boolean {
    return this.setupData.action === AppAction.Form;
  }

  get hasCredentialsFlow(): boolean {
    return this.setupData.action === AppAction.ApiKey;
  }

  get hasFormFields(): boolean {
    return this.setupData.formFields?.length > 0;
  }

  get hasSomeAlwaysEditableFormFields(): boolean {
    return this.setupData.formFields?.some((field: AppFormField) => field.editability === AppFieldStage.Always);
  }

  get hasPostInstallFields(): boolean {
    return this.setupData.formFields?.some((field: AppFormField) => field.editability === AppFieldStage.PostInstall);
  }

  get hasAnyEditableFieldsOnceInstalled() {
    return this.hasSomeAlwaysEditableFormFields || this.hasPostInstallFields;
  }

  get hasError(): boolean {
    return !!this.error;
  }

  get hasAtLeastOneFileAppFieldType(): boolean {
    return this.setupData.formFields?.some((field: AppFormField) => field.type === AppFieldType.File);
  }

  get hasExternalFlowWithFormFields(): boolean {
    return this.hasExternalFlow && this.hasFormFields;
  }

  buildApp(rawApp: RawApp) {
    this.logo = rawApp.logoUrl;
    this.lastUpdated = rawApp.updatedAt;
    this.status = rawApp.installStatus || null;
    this.summary = rawApp.summary;
    this.description = rawApp.description;
    this.isPreferredPartner = !!rawApp.isPreferred;
    this.details = {
      installUrl: rawApp.installUrl,
      callbackUrl: rawApp.callbackUrl,
      baseUrl: rawApp.baseUrl || null,
      helpUrl: rawApp.helpUrl,
      profile: null
    };

    this.setupData = {
      action: rawApp.action as AppAction
    };
    this.buildFormFields(rawApp.formFields);
    this.requiredParams = rawApp.requiredParams || [];
    this.categories = rawApp.categories?.map((cat: string) => cat.replace(/ /g, '_') as AppCategory) || [];
    this.type = this.getAppType();
  }

  buildFormFields(formFields: RawFormField[]) {
    const buildFormField = (field: any): AppFormField => {
      const formField: AppFormField = {
        name: field.name,
        type: field.type,
        isRequired: field.isRequired,
        position: field.position,
        value: this.getFieldValue(field),
        visibility: field.visibility || AppFieldStage.Always,
        editability: field.editability || AppFieldStage.Always,
        behaviour: field.behaviour || AppFieldBehaviour.Plain,
        options: field.options || [],
        validation: field.validation,
        actions: field.actions || [],
        additionalInformation: field.additionalInformation || '',
        displayType: field.displayType
      };
      if (field.fields?.length > 0) {
        formField.fields = field.fields;
      }
      return formField;
    };

    this.setupData.formFields = formFields?.map(field => {
      if (field.fields?.length > 0) {
        field.fields = field.fields.map(buildFormField);
      }
      return buildFormField(field);
    }) || [];
  }

  setStatus(apps: App[]) {
  }

  uninstall() {
    this.status = null;
  }

  getAppType(): AppType {
    return AppType.Other;
  }

  getFieldSelectOptions(fieldName: string): SelectOption[] {
    return this.setupData?.formFields?.find(field => field.name === fieldName).options;
  }

  isFieldReadOnly(fieldName: string): boolean {
    const field = this.setupData?.formFields?.find(field => field.name === fieldName);
    return field?.editability === AppFieldStage.Never;
  }

  isFieldEditable(fieldName: string): boolean {
    const field = this.setupData?.formFields?.find(field => field.name === fieldName);
    return field?.editability !== AppFieldStage.Never;
  }

  isFieldActionPostInstall(fieldName: string): boolean {
    const field = this.setupData?.formFields?.find(field => field.name === fieldName);
    return field?.editability === AppFieldStage.PostInstall;
  }

  isFieldEditableOnceInstalled(fieldName: string): boolean {
    const field = this.setupData?.formFields?.find(field => field.name === fieldName);
    return field?.editability === AppFieldStage.Always || field?.editability === AppFieldStage.PostInstall;
  }

  getFieldValue(field: any): string | boolean | Record<string, string>[] {
    let fieldValue: string | boolean | Record<string, string>[] = field.value ?? '';
    switch (field.type) {
      case AppFieldType.Boolean:
        fieldValue = !!field.value;
        break;
      case AppFieldType.Select:
        fieldValue = field.displayType === AppFieldDisplayType.Options ? this.getOptionsDisplayTypeFieldValue(field.value, field.options) : field.value;
        break;
    }
    return fieldValue;
  }

  hasFieldAction(fieldName: string, action: AppFormFieldAction): boolean {
    const field = this.setupData?.formFields?.find(field => field.name === fieldName);
    return field?.actions?.includes(action);
  }

  isFieldFileType(fieldName: string): boolean {
    const field = this.setupData?.formFields?.find(field => field.name === fieldName);
    return field?.type === AppFieldType.File;
  }

  hasConditionalRequiredFields(): boolean {
    return this.setupData.formFields.some((field: AppFormField) => field.validation?.includes(AppFormFieldValidation.RequiredWith));
  }

  setConditionalRequiredFields() {
    this.setupData?.formFields?.forEach((field: AppFormField) => {
      if (field.validation?.includes(AppFormFieldValidation.RequiredWith)) {
        const connectedFieldName = field.validation.split(':')[1];
        const hasConnectedFieldValue = !!this.setupData.form.controls[connectedFieldName].value;
        const fieldFormControl = this.setupData.form.controls[field.name];
        if (!fieldFormControl?.value) {
          if (hasConnectedFieldValue) {
            fieldFormControl?.setErrors({ 'incorrect': true });
          } else {
            delete fieldFormControl?.errors?.['incorrect'];
            fieldFormControl?.updateValueAndValidity();
          }
        }
      }
    });
  }

  getOptionsDisplayTypeFieldValue(labelValue: string | number = null, options: SelectOption[]): string | number {
    return labelValue ? options?.find((option: SelectOption) => option.label === labelValue || option.value === labelValue)?.value : options?.[0]?.value;
  }

  isAlwaysHidden(): boolean {
    return false;
  }

  isHiddenInProduction(): boolean {
    const isProduction = environment.name === 'production' || environment.name === 'sandbox';
    return false;
  }
}

export interface App {
  readonly referenceId: string;
  readonly key: AppKey;
  readonly name: string;
  readonly id: string;
  status: AppStatus | string;
  logo: string;
  developer?: string;
  lastUpdated?: string;
  details: AppDetails;
  setupData: AppSetup;
  summary: string;
  description: string;
  inProgress: boolean;
  isIntegrating: boolean;
  isPreferredPartner?: boolean;
  category: AppCategory;
  categories: AppCategory[];
  statusMessage?: (LinkTextConfig | string)[] | string;
  type: AppType;
  error: boolean;
  hasError: boolean;
  isInstalled: boolean;
  isNotInstalled: boolean;
  isPending: boolean;
  hasExternalFlow: boolean;
  hasFormFields: boolean;
  hasSomeAlwaysEditableFormFields: boolean;
  hasPostInstallFields: boolean;
  hasFormFlow: boolean;
  hasCredentialsFlow: boolean;
  errorMessage: string;
  hasAllowUninstall: boolean;
  hasAtLeastOneFileAppFieldType: boolean;
  hasExternalFlowWithFormFields: boolean;
  hasAnyEditableFieldsOnceInstalled: boolean;
  requiredParams: string[];

  buildApp(rawApp: RawApp);

  buildFormFields(formFields: RawFormField[]);

  setStatus(apps: App[]);

  uninstall();

  getAppType(): AppType;

  getFieldSelectOptions(fieldName: string): SelectOption[];

  isFieldReadOnly(fieldName: string): boolean;

  getFieldValue(field: any): string | boolean | Record<string, string>[];

  hasFieldAction(fieldName: string, action: AppFormFieldAction): boolean;

  isFieldEditable(fieldName: string): boolean;

  isFieldEditableOnceInstalled(fieldName: string): boolean;

  isFieldActionPostInstall(fieldName: string): boolean;

  isFieldFileType(fieldName: string): boolean;

  hasConditionalRequiredFields(): boolean;

  setConditionalRequiredFields(): void;

  getOptionsDisplayTypeFieldValue(labelValue: string | number | null, options: SelectOption[]): string | number;

  isAlwaysHidden(): boolean;

  isHiddenInProduction(): boolean;
}

export interface RawApp {
  referenceId: string;
  name: string;
  id: string;
  logoUrl: string;
  updatedAt: string;
  installStatus: AppStatus;
  summary: string;
  description: string;
  isPreferred: boolean;
  installUrl: string;
  callbackUrl: string;
  baseUrl: string;
  helpUrl: string;
  action: AppAction;
  categories: AppCategory[];
  formFields: RawFormField[];
  requiredParams: string[];
}

export interface RawFormField {
  name: string;
  type: AppFieldType;
  isRequired: boolean;
  position: number;
  value?: string;
  options?: { label: string, value: string }[];
  fields?: any[];
  visibility?: AppFieldStage;
  editability?: AppFieldStage;
  behaviour?: AppFieldBehaviour;
  validation?: AppFormFieldValidation | string;
}

export enum AppType {
  Other = 'other'
}

export enum AppCategory {
  Default = ''
}

export enum AppKey {
  GoogleCalendar = 'googleCalendar',
  AppointmentConfirmation = 'appointmentConfirmation',
  Zapier = 'zapier'
}

export enum AppStatus {
  Pending = 'pending',
  Installed = 'installed'
}

export enum AppAction {
  Form = 'form',
  OAuth = 'oauth',
  ApiKey = 'api_key'
}

export interface AppDetails {
  installUrl: string;
  callbackUrl: string;
  baseUrl: string;
  helpUrl: string;
  profile: AppProfile;
}

export interface AppSetup {
  formFields?: AppFormField[];
  action?: AppAction;
  url?: string;
  form?: UntypedFormGroup;
  formOptions?: Record<string, Record<string, any>>;
  customScript?: any;
}

export interface AppFormField extends AppField {
  name: string;
  type: AppFieldType;
  isRequired?: boolean;
  position?: number;
  value?: string | boolean | Record<string, string>[];
  fields?: AppFormField[];
  visibility?: AppFieldStage;
  editability?: AppFieldStage;
  behaviour?: AppFieldBehaviour;
  options?: AppFormFieldOption[];
  validation?: AppFormFieldValidation | string;
  actions?: AppFormFieldAction[];
  additionalInformation?: string;
  displayType?: AppFieldDisplayType;
}

export enum AppFormFieldAction {
  Add = 'add',
  Remove = 'remove',
  Copy = 'copy'
}

export enum AppFieldStage {
  Always = 'always',
  PreInstall = 'pre_install',
  PostInstall = 'post_install',
  Never = 'never'
}

export enum AppFormFieldValidation {
  Required = 'required',
  RequiredBoolean = 'required|boolean',
  Sometimes = 'sometimes',
  SometimesBoolean = 'sometimes|boolean',
  RequiredWith = 'required_with'
}

export interface AppProfile {
  fields: AppField[];
}

export interface AppField {
  name: string;
  value?: string | boolean | number | Record<string, string>[];
  behaviour?: AppFieldBehaviour;
  editability?: AppFieldStage;
  helpText?: string;
}

export enum AppFieldType {
  Text = 'text',
  Boolean = 'boolean',
  Array = 'array',
  Select = 'select',
  File = 'file'
}

export interface AppWarning {
  key: AppKey,
  message: string
}

export interface AppWarning {
  key: AppKey,
  message: string
}

export interface LinkTextConfig extends LinkConfig {
  customClass?: string;
}

export interface AppTranslationConfig {
  app: App;
  showPrefix?: boolean;
  useStatus?: boolean;
  useAppKey?: boolean;
}

export interface AppFormFieldOption {
  label: string;
  value: string;
}

export enum FieldFileState {
  None = 'none',
  Uploaded = 'uploaded'
}

export enum AppModal {
  Status = 'statusModal',
  Uninstall = 'uninstallModal',
  Install = 'installModal',
  Settings = 'settingsModal',
  Update = 'updateModal',
}

export enum AppFieldDisplayType {
  Default = 'default',
  Options = 'options'
}
