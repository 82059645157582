import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LinkConfig } from '../../core/helpers/utils';

@Component({
  selector: 'rosie-alert',
  template: `
    <div class="alert" [ngClass]="type + ' ' + customClass" [dataHook]="'alert-' + type">
      <div class="{{iconClass}} alert-icon" [style.background-image]="customIcon ? getCustomIconUrl() : null"></div>
      <div>
        <span *ngIf="message">{{ message | translate }}</span>
        <span *ngIf="link?.url">&nbsp;<a class="link" (click)="navigateTo(link.url)">{{ link.title | translate }}</a></span>
      </div>
      <span *ngIf="innerHtml" [innerHTML]="innerHtml"></span>
    </div>
  `,
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() message = '';
  @Input() innerHtml: string;
  @Input() type: AlertType = AlertType.Error;
  @Input() customClass = '';
  @Input() customIcon?: string = '';
  @Input() link?: LinkConfig;

  constructor(private router: Router) {
  }

  get iconClass(): string {
    return `${this.type}-alert-icon`;
  }

  ngOnInit(): void {
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  getCustomIconUrl(): string {
    return `url('assets/images/${this.customIcon}')`;
  }
}

export enum AlertType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

export interface AlertConfig {
  message: string;
  type: AlertType;
  customClass?: string;
  customIcon?: string;
  link?: LinkConfig;
  innerHtml?: string;
}