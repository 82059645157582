import {Directive, ElementRef, HostBinding, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[shimmer]'
})
export class ShimmerDirective implements OnChanges {
  @HostBinding('class') cssClass = '';
  @Input('shimmer') loading = true;
  @Input() shimmerStyle: string;
  private shimmerChild: HTMLDivElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleChanges();
  }

  private handleChanges(): void {
    if (this.loading && !this.shimmerChild) {
      this.shimmerChild = this.renderer.createElement('div');
      this.shimmerChild.classList.add('skl-child');
      if (this.shimmerStyle) {
        this.shimmerChild.setAttribute('style', this.shimmerStyle);
      }
      this.renderer.appendChild(this.elementRef.nativeElement, this.shimmerChild);
      this.cssClass += ' skl-parent';
    } else if (!this.loading && this.shimmerChild) {
      this.shimmerChild.classList.add('remove');
      setTimeout(() => {
        if (this.shimmerChild) {
          this.renderer.removeChild(this.elementRef.nativeElement, this.shimmerChild);
        }
        this.shimmerChild = null;
        this.cssClass = this.cssClass.replace(' skl-parent', '');
      }, 360);
    }
  }

}
