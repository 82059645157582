import { Component } from '@angular/core';
import { AnalyticsEvent, InternalAnalyticsService } from '../../core/internal-analytics.service';
import { SubscriptionService } from '../../core/subscription/subscription.service';

@Component({
    selector: 'rosie-credit-card-alert',
    templateUrl: './credit-card-alert.component.html',
    styleUrls: ['./credit-card-alert.component.scss']
})
export class CreditCardAlertComponent {
    isMobileVersion: boolean = false;

    constructor(private subscription: SubscriptionService, private analytics: InternalAnalyticsService) {
    }

    get icon() {
        return !this.subscription.hasCreditCardAdded ? 'heroCreditCard' : 'heroExclamationTriangleSolid';
    }

    get heading() {
        return !this.subscription.hasCreditCardAdded ? 'CREDIT_CARD_ALERT.ADD_CREDIT_CARD' : 'CREDIT_CARD_ALERT.PAYMENT_FAILED';
    }

    get description() {
        return !this.subscription.hasCreditCardAdded ? 'CREDIT_CARD_ALERT.ADD_CREDIT_CARD_DESCRIPTION' : 'CREDIT_CARD_ALERT.PAYMENT_FAILED_DESCRIPTION';
    }

    handleSubscription() {
        if (!this.subscription.hasCreditCardAdded) {
            this.analytics.trackEvent(AnalyticsEvent.AddCreditCartAlertInitiated);
            this.subscription.open.next(true);
        } else {
            this.redirectToBillingPortal();
        }
    }

    private redirectToBillingPortal(): void {
        window.open(this.subscription?.billingUrl);
    }
}